import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm } from '@wafo/forms';
import moment from 'moment';
import DatetimePicker from '#components/forms/customForms/datetimePicker/datetimePicker';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormCustoms = ({ locale, translations, onSubmitSuccess, locationId, customs, equipments }) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  async function onFormSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();

        const newAduana = {
          ...(values.placement_date && { placement_date: moment(values.placement_date).toISOString() }),
          ...(values.placement_end_date && { placement_end_date: moment(values.placement_end_date).toISOString() }),
          ...(values.modulation_start_date && { modulation_start_date: moment(values.modulation_start_date).toISOString() }),
          ...(values.modulation_end_date && { modulation_end_date: moment(values.modulation_end_date).toISOString() }),
          ...(values.liberation_date && { liberation_date: moment(values.liberation_date).toISOString() }),
        };

        if (Object.keys(newAduana).length > 0) {
          if (equipments.length === 0) {
            if (customs.id_custom) {
              await api.put(`/equipments/locations/customs/${customs.id_custom}`, newAduana);
            } else {
              await api.post(`/equipments/locations/${locationId}/customs`, newAduana);
            }
          } else {
            // edit
            await Promise.all(
              equipments
                .filter(e => e.equipment_location.equipment_custom)
                .map(c => api.put(`/equipments/locations/customs/${c.equipment_location.equipment_custom.id_custom}`, newAduana)),
            );
            // For new aduanas
            await Promise.all(
              equipments
                .filter(e => !e.equipment_location.equipment_custom)
                .map(e => api.post(`/equipments/locations/${e.equipment_location.id_el}/customs`, newAduana)),
            );
          }

          alerts.success({
            title: translations['alerts-success-title'],
            message: translations['alerts-success-msg'],
            timer: 3000,
          });

          onSubmitSuccess();
        } else {
          alerts.set({
            type: 'warning',
            title: translations['customs-f-a-empty-title'],
            message: translations['customs-f-a-empty-message'],
            timer: 3000,
          });
        }

        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <WafoForm formId="form-customs" onSubmit={onFormSubmit} ignoreEmpty locale={locale} values={customs}>
          <DatetimePicker
            name="placement_date"
            customClass={equipments.length ? 'col-12' : 'col-12 col-md-6'}
            label={translations['customs-f-c-label-placementstart']}
            handleChange
          />

          <DatetimePicker
            name="placement_end_date"
            customClass={equipments.length ? 'col-12' : 'col-12 col-md-6'}
            label={translations['customs-f-c-label-placementend']}
            handleChange
          />

          <DatetimePicker
            name="modulation_start_date"
            customClass={equipments.length ? 'col-12' : 'col-12 col-md-6'}
            label={translations['customs-f-c-label-modulationstart']}
            handleChange
          />

          <DatetimePicker
            name="modulation_end_date"
            customClass={equipments.length ? 'col-12' : 'col-12 col-md-6'}
            label={translations['customs-f-c-label-modulationend']}
            handleChange
          />

          <DatetimePicker
            name="liberation_date"
            customClass={equipments.length ? 'col-12' : 'col-12 col-md-6'}
            label={translations['customs-f-c-label-release']}
            handleChange
          />
        </WafoForm>
      </div>
      {equipments.length === 0 && (
        <div className="col-12 text-center">
          <button type="submit" form="form-customs" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      )}
    </div>
  );
};

FormCustoms.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
  locationId: PropTypes.string,
  customs: PropTypes.any,
  equipments: PropTypes.array,
};

FormCustoms.defaultProps = {
  onSubmitSuccess: f => f,
  equipments: [],
};

export default withTranslation(FormCustoms, 'customs');
