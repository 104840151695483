import React from 'react';
import { useWebsocket } from '@wafo/react-websocket';
import { getToken } from '#utils/authService';

const logo = require('#assets/images/logo_notification.png');

const WSNotifications = () => {
  React.useEffect(() => {
    async function setupNotifications() {
      // TODO: Remove check for denied to spam the user for permission.
      if (Notification.permission !== "denied" && Notification.permission !== "granted") {
        await Notification.requestPermission();
      }
    }
    setupNotifications();
  }, []);

  const { socketStatus, socket } = useWebsocket({
    url: process.env.REACT_APP_WSS,
    reconnect: false,
    onMessage: ({ data }, ws) => {
      const dataObj = JSON.parse(data);
      switch (dataObj.action) {
        case 'ping':
          ws.send('{"action":"pong"}');
          break;
        case 'notification': {
          const { payload: { notifications: notification } } = dataObj;
          new Notification(notification.title, {
            body: notification.message,
            icon: logo,
            badge: logo,
          });
          break;
        }
        default: break;
      }
    },
  });

  React.useEffect(() => {
    // console.log('Socket status: ', socketStatus);
    if (socketStatus === 1) {
      socket.send(JSON.stringify({
        action: "authenticate",
        payload: getToken(),
      }));
    }
  }, [socketStatus, socket]);

  return <React.Fragment></React.Fragment>;
};

export default WSNotifications;
