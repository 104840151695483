import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import { WafoForm, WafoFormInput, WafoFormAutocomplete } from '@wafo/forms';
import withTranslation from '#components/HOCs/withTranslation';
import useApi from '#hooks/api/useApi';
import LocationListItem from '#components/UI/locationListItem/locationListItem';
import RouteOrderList from '#components/UI/routes/routeOrderList/routeOrderList';
import { FormattedMessage } from 'react-intl';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormRoute = ({ locale, translations, onSubmitSuccess }) => {
  const [route, setRoute] = React.useState({});
  const [preroute, setPreroute] = React.useState([]);

  const { id } = useParams();
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  React.useEffect(() => {
    async function getRoute() {
      try {
        loading.set();
        const resp = await api.get(`/routes/${id}`);
        setRoute(resp);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }

    if (id) {
      getRoute();
    }
  }, [api, id, loading]);

  React.useEffect(() => {
    if (route.route_locations) {
      const order = route.route_locations
        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
        .map(location => {
          const obj = {
            ...location,
            ...location.location,
          };
          delete obj.location;
          return obj;
        });
      setPreroute(order);
    }
  }, [route]);

  async function handleSubmit(form, values) {
    if (!form.valid) {
      return;
    }
    if (preroute.length < 2) {
      alerts.set({
        type: 'warning',
        title: translations['routes-f-a-noroute-title'],
        message: translations['routes-f-a-noroute-msg'],
      });
      return;
    }
    try {
      loading.set();
      const toSend = preroute.map((l, i) => {
        let seconds = 0;
        if (l.order !== 1) {
          const [hours, minutes] = l.estimate_time_arrival.split(':');
          seconds = hours * 3600 + minutes * 60;
        }
        return {
          ...l,
          order: i + 1,
          estimate_time_arrival: seconds,
        };
      });
      if (route.id_route) {
        // editing

        // Eliminando locations de db.
        const dbLocations = preroute.filter(l => l.id_rl);
        const toEliminate = route.route_locations.filter(l => !dbLocations.find(x => x.id_rl === l.id_rl));
        await Promise.all(toEliminate.map(l => api.delete(`/routes/locations/${l.id_rl}`)));

        await Promise.all(
          toSend
            .filter(x => x.id_rl)
            .map(l =>
              api.put(`/routes/locations/${l.id_rl}`, {
                order: l.order,
                ...(l.order !== 1 && { estimate_time_arrival: l.estimate_time_arrival }),
              }),
            ),
        );
        await Promise.all(
          toSend
            .filter(x => !x.id_rl)
            .map(l =>
              api.post('/routes/locations', {
                id_route: route.id_route,
                id_location: l.id_location,
                order: l.order,
                ...(l.order !== 1 && { estimate_time_arrival: l.estimate_time_arrival }),
              }),
            ),
        );

        await api.put(`/routes/${route.id_route}`, { name: values.name });
      } else {
        // new
        const res = await api.post('/routes', {
          name: values.name,
        });
        // Posible porque no valen ubicaciones repetidas.
        await Promise.all(
          toSend.map(l =>
            api.post('/routes/locations', {
              id_route: res.id_route,
              id_location: l.id_location,
              order: l.order,
              ...(l.order !== 1 && { estimate_time_arrival: l.estimate_time_arrival }),
            }),
          ),
        );
      }
      alerts.success({
        title: translations['alerts-success-title'],
        message: translations['alerts-success-msg'],
        timer: 3000,
      });
      loading.stop();
      onSubmitSuccess();
    } catch (error) {
      loading.stop();
      console.error(error);
    }
  }

  /** **************************************** */
  /** Locations */
  /** **************************************** */
  const [locations, setLocations] = React.useState([]);

  React.useEffect(() => {
    async function getLocations() {
      try {
        loading.set();
        const resp = await api.get('/locations', {
          params: {
            limit: 999,
          },
        });
        setLocations(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getLocations();
  }, [api, loading]);

  const locationsAvailable = React.useMemo(() => {
    return locations.filter(x => preroute.findIndex(y => y.id_location === x.id_location) === -1);
  }, [locations, preroute]);

  const onLocationSelect = location => {
    setPreroute(prev => [...prev, { ...location, uuid: uuidv4(), estimate_time_arrival: '' }]);
  };

  const onLocationRemove = index => {
    setPreroute(prev => [...prev.slice(0, index), ...prev.slice(index + 1)]);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6">
          <WafoForm formId="form-routes" locale={locale} onSubmit={handleSubmit} values={route}>
            <WafoFormInput
              type="text"
              name="name"
              label={translations['routes-f-label-name']}
              placeholder={translations['routes-f-placeholder-name']}
              customClass="col-12"
              validations={{ required: true }}
            />

            <WafoFormAutocomplete
              name="preroute"
              customClass="col-12"
              label={translations['routes-f-label-preroute']}
              placeholder={translations['routes-f-placeholder-preroute']}
              items={locationsAvailable}
              filterItems={(items, query) =>
                items.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
              }
              renderInput={item => item.name}
              renderItem={item => <LocationListItem location={item} />}
              extraProps={{
                autoComplete: 'off',
              }}
              onSelectCallback={onLocationSelect}
              // handleChange
            >
              <p className="form-help">
                <FormattedMessage id="routes-f-help-preroute" />
              </p>
            </WafoFormAutocomplete>
          </WafoForm>
        </div>
        <div className="col-12 col-md-6">
          <label className="form-label">
            <FormattedMessage id="routes-f-label-preroutelist" />
          </label>
          <RouteOrderList
            translations={translations}
            route={preroute}
            onOrderChanged={setPreroute}
            onLocationRemove={onLocationRemove}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
          <button type="submit" form="form-routes" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </div>
    </div>
  );
};

FormRoute.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
};

export default withTranslation(FormRoute, 'locations');
