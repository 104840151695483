import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import withTranslation from '#components/HOCs/withTranslation';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import InfoColumn from '#components/UI/general/infoColumn/infoColumn';
import LocationType from '#components/UI/extra/locationType';
import useApi from '#hooks/api/useApi';
import RouteProgress from '#components/UI/routes/routeProgress/routeProgress';
import TableEquipments from './tableEquipments';
import useLoading from '#context/hooks/useLoading';
import styles from './trainDetails.module.css';
// TODO: Corrección a materiales con nueva forma.

const TrainDetails = ({ translations, trainId }) => {
  const api = useApi();

  const [train, setTrain] = React.useState({
    route: null,
    equipments: [],
  });

  const [locationFilter, serLocationFilter] = React.useState(null);
  const onLocationFilterClick = l => {
    serLocationFilter(prev => {
      if (prev && prev.location.id_location === l.location.id_location) {
        return null;
      }
      return l;
    });
  };

  const history = useHistory();
  const { id } = useParams();
  const loading = useLoading();

  const getData = React.useCallback(async () => {
    try {
      loading.set();
      const resp = await api.get(`/packages/${id || trainId}`);
      setTrain(resp);
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  }, [api, id, trainId, loading]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  const onEquipmentSelect = (id, select) => {
    setTrain(prev => {
      const index = prev.equipments.findIndex(x => x.equipment_id === id);
      const dummy = [...prev.equipments];
      dummy[index].select = select ? false : true;
      return {
        ...prev,
        equipments: dummy,
      };
    });
  };

  const onEquipmentSelectAll = () => {
    const totalSelected = train.equipments.filter(x => x.select).length;
    let dummy = [...train.equipments];
    if (totalSelected < train.equipments.length) {
      dummy = dummy.map(eq => ({
        ...eq,
        select: true,
      }));
    } else if (totalSelected === train.equipments.length) {
      dummy = dummy.map(eq => ({
        ...eq,
        select: false,
      }));
    }
    setTrain(prev => ({
      ...prev,
      equipments: dummy,
    }));
  };

  const filteredEquipments = React.useMemo(() => {
    if (locationFilter) {
      return train.equipments.filter(x => x.location && x.location.id_location === locationFilter.location.id_location);
    }
    return train.equipments;
  }, [train.equipments, locationFilter]);

  return (
    <div>
      <CardHeader
        title={translations['trains-details-title']}
        showBack
        onBack={() => {
          history.goBack();
        }}
      />
      <div className="card card-panel">
        <div className="card-body">
          <h5 className="card-subtitle">
            <FormattedMessage id="trains-details-subt-1" />
          </h5>

          <div className={styles['information']}>
            <div className="row">
              <InfoColumn
                title={translations['trains-details-info-trainid']}
                info={
                  <>
                    <span>{train.external_id ? train.external_id : train.id_package}</span>
                    {train.train_id && (
                      <span style={{ fontSize: '0.85em', marginLeft: '.5rem' }}>[{train.train_id}]</span>
                    )}
                  </>
                }
              />
              <InfoColumn title={translations['trains-details-info-waybill']} info={train.waybill} />
              <InfoColumn
                title={translations['trains-details-info-material']}
                info={
                  <>
                    {train.equipments.length > 0 && (
                      <>
                        <span>
                          #{train.equipments[0].material.id_material} {train.equipments[0].material.name}
                        </span>
                        <br />
                        <span style={{ fontSize: '0.8em' }}>
                          {translations['materials-f-types'][train.equipments[0].material.material_type]}
                        </span>
                      </>
                    )}
                  </>
                }
              />
              <InfoColumn
                title={translations['trains-details-info-route']}
                info={train.route ? train.route.name : 'NA'}
              />
              <InfoColumn
                title={translations['trains-details-info-current']}
                info={
                  <>
                    {train.current_location && (
                      <>
                        <span>{train.current_location.name}</span>
                        <br />
                        <span style={{ fontSize: '0.8em' }}>
                          <LocationType type={train.current_location.type} />
                        </span>
                      </>
                    )}
                    {!train.current_location && 'NA'}
                  </>
                }
              />
              <InfoColumn
                title={translations['trains-details-info-date']}
                info={
                  <>
                    <span title="Updated">{moment(train.updateAt).format('DD MMMM YYYY HH:mm')}</span>
                    <br />
                    <span title="Created" style={{ fontSize: '0.8em' }}>
                      {moment(train.createdAt).format('DD MMMM YYYY HH:mm')}
                    </span>
                  </>
                }
              />
            </div>
          </div>

          {/** ************************************************ */}
          <div className="divider" />
          {/** ************************************************ */}

          <h5 className="card-subtitle">
            <FormattedMessage id="trains-details-subt-2" />
          </h5>

          {!train.route && (
            <div className={styles['no-route']}>
              <FormattedMessage id="trains-details-noroute-title">
                {txt => <p className={styles['no-route-title']}>{txt}</p>}
              </FormattedMessage>
              <FormattedMessage id="trains-details-noroute-desc">
                {txt => <p className={styles['no-route-desc']}>{txt}</p>}
              </FormattedMessage>
            </div>
          )}
          {train.route && (
            <RouteProgress
              route={train.route}
              current={train.current_location}
              selected={locationFilter}
              onLocationClick={onLocationFilterClick}
            />
          )}

          {/** ************************************************ */}
          <div className="divider" />
          {/** ************************************************ */}

          <h5 className="card-subtitle">
            <FormattedMessage id="trains-details-subt-3" />
          </h5>

          <div className={styles['equipments']}>
            <div className="row">
              <div className="col-12">
                <TableEquipments
                  train={train}
                  equipments={filteredEquipments}
                  onSelect={onEquipmentSelect}
                  onSelectAll={onEquipmentSelectAll}
                  updateData={getData}
                  locationFilter={locationFilter}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TrainDetails.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  trainId: PropTypes.number,
};

export default withTranslation(TrainDetails, ['trains', 'locations', 'materials']);
