import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from '#components/HOCs/withTranslation';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { faToggleOff, faToggleOn, faEdit, faList } from '@fortawesome/free-solid-svg-icons';
import { DataTable } from '@wafo/table';
import { makeModal } from '#components/modals/makeModal';
import ConfirmModal from '#components/modals/confirmModal/confirmModal';
import useApi from '#hooks/api/useApi';
import useQuery from '#hooks/router/useQuery';
import useLoading from '#context/hooks/useLoading';

const TableWarehouses = ({ translations, locale }) => {
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const tableRef = React.useRef();

  const api = useApi();
  const loading = useLoading();

  const query = useQuery();
  const history = useHistory();
  const match = useRouteMatch();

  const toggleDisabled = React.useCallback(
    async (id, disabled) => {
      async function callDisable() {
        try {
          loading.set();
          await api.put(`/locations/${id}`, { disabled: !disabled });
          loading.stop();
          tableRef.current.forceUpdate();
        } catch (error) {
          loading.stop();
        }
      }
      makeModal(ConfirmModal, {
        message: translations['warehouses-t-confirm-m'],
        translations,
        onAccept: () => {
          callDisable();
        },
      });
    },
    [api, loading, translations],
  );

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await api.get('/locations', {
          params: {
            limit: size,
            page,
            type: '4,5',
            ...(search && { search }),
          },
        });
        setTotalRows(resp.count);
        setRows(
          resp.rows.map(row => ({
            id: row.id_location,
            status: row.disabled,
            name: row.name,
            stores: row.location_stores.length,
            options: { id: row.id_location, disabled: row.disabled },
          })),
        );
        history.replace({
          pathname: match.url,
          search: `?page=${page}`,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [api, loading, history, match.url],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: function col(val) {
        return <span>#{val}</span>;
      },
      status: function col(val) {
        return (
          <span className={`badge table-badge ${!val ? 'badge-success' : 'badge-warning'}`}>
            <FormattedMessage id={!val ? 'warehouses-t-enabled' : 'warehouses-t-disabled'} />
          </span>
        );
      },
      options: function col(val) {
        return (
          <React.Fragment>
            <button
              type="button"
              className={`btn btn-table ${val.disabled ? 'btn-danger' : 'btn-success'}`}
              title={val.disabled ? 'Enable' : 'Disable'}
              onClick={() => {
                toggleDisabled(val.id, val.disabled);
              }}
            >
              <FontAwesomeIcon icon={val.disabled ? faToggleOff : faToggleOn} />
            </button>
            <Link to={`/panel/warehouses/new/${val.id}`} className="btn btn-table btn-primary with-icon">
              <FontAwesomeIcon icon={faEdit} />
              <FormattedMessage id="warehouses-t-b-edit" />
            </Link>
            <Link to={`/panel/warehouses/details/${val.id}`} className="btn btn-table btn-secondary with-icon">
              <FontAwesomeIcon icon={faList} />
              <FormattedMessage id="warehouses-t-b-details" />
            </Link>
          </React.Fragment>
        );
      },
    }),
    [toggleDisabled],
  );

  return (
    <div>
      <DataTable
        ref={tableRef}
        locale={locale}
        columns={translations['warehouses-t-columns']}
        rows={rows}
        totalRows={totalRows}
        tableClass="table table-striped table-sm"
        onPagination={getData}
        noRowsMessage={translations['warehouses-t-norows']}
        initialPage={parseInt(query.get('page'), 10)}
        columnsConfig={columnsConfig}
      />
    </div>
  );
};

TableWarehouses.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  alerts: PropTypes.shape({
    success: PropTypes.func,
  }),
};

export default withTranslation(TableWarehouses, 'warehouses');
