import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const UserType = ({ type }) => {
  switch (type) {
    case 1:
      return <FormattedMessage id="users-type-1" />;
    case 2:
      return <FormattedMessage id="users-type-2" />;
    default:
      return <span>NA</span>;
  }
};

UserType.propTypes = {
  type: PropTypes.number,
};

export default UserType;