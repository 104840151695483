import React from 'react';

const useScrollTop = (pathname, id = 'panel-right') => {
  React.useEffect(() => {
    const elem = document.getElementById(id);
    if (elem) {
      elem.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [pathname, id]);

  return null;
};

export default useScrollTop;
