import React from 'react';
import PropTypes from 'prop-types';

const PanelContainer = ({ children }) => (
  <div className="container">
    <div className="row">
      <div className="col">{children}</div>
    </div>
  </div>
);

PanelContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

PanelContainer.defaultProps = {
  children: null,
};

export default PanelContainer;
