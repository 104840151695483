import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '#components/modals/baseModal';
import withTranslation from '#components/HOCs/withTranslation';
import FormStore from '#components/forms/warehouses/formStore';

const NewstoreModal = ({ translations, updateData, location, store, onClose }) => {
  const handleSubmit = () => {
    onClose();
    updateData();
  };

  return (
    <BaseModal
      title={translations['warehouses-f-store-title']}
      modalStyle={{ width: '48em', maxHeight: '80vh', minHeight: '40vh' }}
      closeModal={onClose}
    >
      <FormStore location={location} onSubmitSuccess={handleSubmit} editStore={store} />
    </BaseModal>
  );
};

NewstoreModal.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  train: PropTypes.any,
  updateData: PropTypes.func,
  onClose: PropTypes.func,
  location: PropTypes.any,
  store: PropTypes.any,
};

NewstoreModal.defaultProps = {
  train: {},
  updateData: f => f,
  onClose: f => f,
};

export default withTranslation(NewstoreModal, 'warehouses');
