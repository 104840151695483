import React from 'react';
import { getToken } from '#utils/authService';

const useAuth = () => {
  const [decoded, setDecoded] = React.useState(null);

  React.useEffect(() => {
    const token = getToken(true);
    setDecoded(token);
  }, []);

  return decoded;
};

export default useAuth;