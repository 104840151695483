import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput, WafoFormSelect } from '@wafo/forms';
import { useParams } from 'react-router-dom';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormUser = ({ locale, translations, onSubmitSuccess }) => {
  const [user, setUser] = React.useState({
    id_user: 0,
  });

  const { id } = useParams();
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  React.useEffect(() => {
    async function getUser() {
      try {
        loading.set();
        const resp = await api.get(`/users/${id}`);
        setUser(resp);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    if (id) {
      getUser();
    }
  }, [api, id, loading]);

  async function handleSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();
        if (user.id_user) {
          await api.put(`/users/${user.id_user}`, {
            name: values.name,
            last_name: values.last_name,
            email: values.email,
            type: values.type,
            ...(values.password && { password: values.password }),
          });
        } else {
          await api.post('/users', values);
        }
        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        onSubmitSuccess();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }

  return (
    <div>
      <WafoForm formId="form-user" locale={locale} onSubmit={handleSubmit} values={user}>
        <WafoFormInput
          type="text"
          name="name"
          label={translations['users-f-label-name']}
          placeholder={translations['users-f-placeholder-name']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormInput
          type="text"
          name="last_name"
          label={translations['users-f-label-lastname']}
          placeholder={translations['users-f-placeholder-lastname']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormInput
          type="email"
          name="email"
          label={translations['users-f-label-email']}
          placeholder={translations['users-f-placeholder-email']}
          customClass="col-12 col-md-4"
          validations={{
            required: true,
            email: true,
          }}
        />

        <WafoFormInput
          type="password"
          name="password"
          label={translations['users-f-label-password']}
          placeholder={translations['users-f-placeholder-password']}
          customClass="col-12 col-md-4"
          validations={{ required: !user.id_user }}
        />

        <WafoFormSelect
          name="type"
          label={translations['users-f-label-type']}
          placeholder={translations['users-f-placeholder-type']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
          options={[
            { value: '1', display: translations['users-type-1'] },
            { value: '2', display: translations['users-type-2'] },
          ]}
        />
      </WafoForm>

      <div className="row">
        <div className="col-12 text-center">
          <button type="submit" form="form-user" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </div>
    </div>
  );
};

FormUser.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
};

export default withTranslation(FormUser, 'users');
