import React from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import BreadcrumbRouterNav from '#components/UI/general/breadcrumb/breadcrumb';
import useScrollTop from '#hooks/useScrollTop';
import SearchByEquipment from './searchByEquipment';
import SelectWaybill from './selectWaybill';
import WaybillConfirmation from './waybillConfirmation/waybillConfirmation';
import { transformUnitsUP } from '#utils/utils';
import convert from 'convert-units';
import useTranslation from '#hooks/redux/useTranslation';

const NewTrain = () => {
  const [equipmentId, setEquipmentId] = React.useState('');
  const [waybills, setWaybills] = React.useState([]);
  const [waybill, setWaybill] = React.useState({
    shipmentInformation: {
      equipmentId: {},
      originDetail: {},
      destinationDetail: {},
    },
    billingInformation: {},
    weightInformation: {
      netWeight: {},
    },
    additionalReferenceIdentification: {
      shipmentExtendedReferenceIdentificationInformations: [{ value: 'NA' }],
    },
    priceDocuments: {
      rateAuthority: {},
    },
    customerInformation: {
      mainPartyList: [],
      otherPartyList: [],
    },
    routeInformation: {
      junctionsAndCarriers: [],
    },
    ladingDescription: {
      packages: [],
    },
    shipmentSpecialConditions: {
      conditions: [],
    },
    multiCarInformation: {
      multiCarDetail: [],
    },
  });

  const { translations } = useTranslation();

  const history = useHistory();

  useScrollTop(history.location.pathname);

  const handleSearch = data => {
    setEquipmentId(data.equipment);
    setWaybills(data.waybills);
    history.push('/panel/trains/new/waybill');
  };

  const handleWaybill = waybill => {
    setWaybill(waybill);
    history.push('/panel/trains/new/confirmation');
  };

  const handleChangeBL = (bl, id) => {
    setWaybill(prev => {
      const dummy = [...prev.multiCarInformation.multiCarDetail];
      const index = dummy.findIndex(x => x.equipmentId.number === id);
      dummy[index] = {
        ...dummy[index],
        bl,
      };
      return {
        ...prev,
        multiCarInformation: {
          ...prev.multiCarInformation,
          multiCarDetail: dummy,
        },
      };
    });
  };

  const handleChangeIgnore = (ignore, id) => {
    setWaybill(prev => {
      const dummy = [...prev.multiCarInformation.multiCarDetail];
      const index = dummy.findIndex(x => x.equipmentId.number === id);
      dummy[index] = {
        ...dummy[index],
        ignore,
      };
      return {
        ...prev,
        multiCarInformation: {
          ...prev.multiCarInformation,
          multiCarDetail: dummy,
        },
      };
    });
  };

  const handleMaterialChange = material => {
    try {
      // update weightInformation
      const {
        weightInformation: { netWeight },
      } = waybill;
      const from = transformUnitsUP(netWeight.unit);
      const weight = convert(netWeight.value)
        .from(from)
        .to(material.unit_type)
        .toFixed(3);

      // Updating equipments
      const equipments = [...waybill.multiCarInformation.multiCarDetail].map(e => {
        const weight = convert(e.grossWeight.value)
          .from(from)
          .to(material.unit_type)
          .toFixed(3);
        return {
          ...e,
          materialWeight: {
            value: weight,
            unit: material.unit_type,
          },
        };
      });

      // Updating state
      setWaybill(prev => ({
        ...prev,
        weightInformation: {
          ...prev.weightInformation,
          materialWeight: {
            value: weight,
            unit: material.unit_type,
          },
        },
        multiCarInformation: {
          ...prev.multiCarInformation,
          multiCarDetail: equipments,
        },
      }));
    } catch (error) {
      //
    }
  };

  return (
    <div>
      <CardHeader
        title={translations['trains-new-title']}
        showBack
        onBack={() => {
          history.goBack();
        }}
      />
      <div className="card card-panel">
        <div className="card-body">
          <BreadcrumbRouterNav
            breadcrumbs={[
              { display: translations['trains-new-breadcrumb-1'], path: '/panel/trains/new' },
              { display: translations['trains-new-breadcrumb-2'], path: '/panel/trains/new/waybill' },
              { display: translations['trains-new-breadcrumb-3'], path: '/panel/trains/new/confirmation' },
            ]}
            current={history.location.pathname}
          />

          <Switch>
            <Route exact path="/panel/trains/new" render={() => <SearchByEquipment handleSearch={handleSearch} />} />
            <Route
              exact
              path="/panel/trains/new/waybill"
              render={() => <SelectWaybill waybills={waybills} equipment={equipmentId} handleWaybill={handleWaybill} />}
            />
            <Route
              exact
              path="/panel/trains/new/confirmation"
              render={() => (
                <WaybillConfirmation
                  waybill={waybill}
                  onChangeBL={handleChangeBL}
                  onChangeIgnore={handleChangeIgnore}
                  onMaterialChange={handleMaterialChange}
                />
              )}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default NewTrain;
