import React from 'react';
import { useRouteMatch, Switch, Redirect, Route } from 'react-router-dom';
import PanelContainer from '../panelContainer';
import GenerateReports from './generateReports';

const Reports = () => {
  const match = useRouteMatch();

  return (
    <PanelContainer>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/generate`} />
        <Route exact path={`${match.url}/generate`} component={GenerateReports} />
      </Switch>
    </PanelContainer>
  );
};

export default Reports;
