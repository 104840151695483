import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '#components/modals/baseModal';
import withTranslation from '#components/HOCs/withTranslation';
import FormModalTrain from '#components/forms/trains/formModalTrain';

const EditTrain = ({ translations, train, onClose }) => {
  const handleSubmit = () => {
    onClose();
  };

  return (
    <BaseModal title={translations['trains-f-trainedit-title']} modalStyle={{ width: '32em' }} closeModal={onClose}>
      <FormModalTrain train={train} onSubmitSuccess={handleSubmit} />
    </BaseModal>
  );
};

EditTrain.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  train: PropTypes.any,
  onClose: PropTypes.func,
};

EditTrain.defaultProps = {
  train: {},
  onClose: f => f,
};

export default withTranslation(EditTrain, 'trains');
