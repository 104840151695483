import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@wafo/table';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import withTranslation from '#components/HOCs/withTranslation';
import useApi from '#hooks/api/useApi';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff, faToggleOn, faEdit } from '@fortawesome/free-solid-svg-icons';
import { makeModal } from '#components/modals/makeModal';
import ConfirmModal from '#components/modals/confirmModal/confirmModal';
import useLoading from '#context/hooks/useLoading';
import useQuery from '#hooks/router/useQuery';

const TableRoutes = ({ locale, translations }) => {
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const tableRef = React.useRef();

  const api = useApi();
  const loading = useLoading();

  const history = useHistory();
  const query = useQuery();
  const match = useRouteMatch();

  const toggleDisabled = React.useCallback(
    async (id, disabled) => {
      async function callDisable() {
        try {
          loading.set();
          await api.put(`/routes/${id}`, { disabled: !disabled });
          loading.stop();
          tableRef.current.forceUpdate();
        } catch (error) {
          loading.stop();
        }
      }
      makeModal(ConfirmModal, {
        message: translations['routes-t-confirm-m'],
        translations,
        onAccept: () => {
          callDisable();
        },
      });
    },
    [api, loading, translations],
  );

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await api.get('/routes', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
          },
        });
        setTotalRows(resp.count);
        setRows(
          resp.rows.map(row => ({
            id: row.id_route,
            status: row.disabled,
            name: row.name,
            locations: row.total_locations,
            options: { id: row.id_route, disabled: row.disabled },
          })),
        );
        history.replace({
          pathname: match.url,
          search: `?page=${page}`,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [api, loading, history, match.url],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: function col(val) {
        return <span>#{val}</span>;
      },
      status: function col(val) {
        return (
          <span className={`badge table-badge ${!val ? 'badge-success' : 'badge-warning'}`}>
            <FormattedMessage id={!val ? 'routes-t-enabled' : 'routes-t-disabled'} />
          </span>
        );
      },
      options: function col(val) {
        return (
          <React.Fragment>
            <button
              type="button"
              className={`btn btn-table ${val.disabled ? 'btn-danger' : 'btn-success'}`}
              title={val.disabled ? 'Enable' : 'Disable'}
              onClick={() => {
                toggleDisabled(val.id, val.disabled);
              }}
            >
              <FontAwesomeIcon icon={val.disabled ? faToggleOff : faToggleOn} />
            </button>
            <Link to={`/panel/routes/new/${val.id}`} className="btn btn-table btn-primary with-icon">
              <FontAwesomeIcon icon={faEdit} />
              {translations['routes-t-b-edit']}
            </Link>
          </React.Fragment>
        );
      },
    }),
    [toggleDisabled, translations],
  );

  return (
    <DataTable
      ref={tableRef}
      locale={locale}
      columns={translations['routes-t-columns']}
      rows={rows}
      totalRows={totalRows}
      tableClass="table table-striped table-sm"
      onPagination={getData}
      noRowsMessage={translations['routes-t-norows']}
      initialPage={parseInt(query.get('page'), 10)}
      columnsConfig={columnsConfig}
    />
  );
};

TableRoutes.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  alerts: PropTypes.shape({
    success: PropTypes.func,
  }),
};

export default withTranslation(TableRoutes, 'locations');
