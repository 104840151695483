import React from 'react';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';
import useTranslation from '#hooks/redux/useTranslation';
import { WafoForm, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import DatetimePicker from '../customForms/datetimePicker/datetimePicker';
import { materialQuantity } from '#utils/utils';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

const FormShipIncident = ({ shipId, stores: storesProp, onSubmitSuccess, incident }) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();
  const { locale, translations } = useTranslation();

  const stores = React.useMemo(() => {
    return storesProp.map(x => {
      let {
        material,
        material: { unit_type },
        limit_quantity,
        charged_material,
        stowage_plan: { stowage_plan },
      } = x;

      limit_quantity = materialQuantity(material, limit_quantity);
      charged_material = materialQuantity(material, charged_material);
      stowage_plan = materialQuantity(material, stowage_plan);

      return {
        value: x.id_ship_storage,
        display: `${limit_quantity} | ${charged_material}/${stowage_plan} ${unit_type}`,
      };
    });
  }, [storesProp]);

  const handleSubmit = async (form, values) => {
    if (form.valid) {
      loading.set();
      try {
        const toSend = {
          type: values.type,
          date_incident: moment(values.date_incident).toISOString(),
          id_ship: shipId,
          ...(values.comment && { comment: values.comment }),
          ...(values.id_ship_storage && { id_ship_storage: values.id_ship_storage }),
          ...(values.date_released && { date_released: moment(values.date_released).toISOString() }),
        };

        if (incident) {
          await api.put(`/ships/incidents/${incident.id_ship_incident}`, toSend);
        } else {
          await api.post('/ships/incidents', toSend);
        }

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });

        onSubmitSuccess();
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
  };

  let preparedIncident = null;
  if (incident) {
    preparedIncident = {
      ...incident,
      date_incident: moment(incident.date_incident).format('YYYY-MM-DD:HH:mm'),
      date_released: moment(incident.date_released).format('YYYY-MM-DD:HH:mm'),
    };
  }

  return (
    <div>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo adipisci quos natus doloribus. Doloremque harum
        neque quae! Dolores et a sit, reprehenderit similique non est iure cupiditate, fuga hic sed.
      </p>
      <WafoForm formId="form-shipincidents" locale={locale} onSubmit={handleSubmit} values={preparedIncident}>
        <WafoFormSelect
          name="type"
          label={translations['s-incidents-label-type']}
          defaultValue={translations['s-incidents-placeholder-type']}
          customClass="col-12 col-md-6"
          options={translations['s-incidents-types'].map((x, i) => ({
            value: i + 1,
            display: x,
          }))}
          validations={{ required: true }}
        />

        <WafoFormSelect
          name="id_ship_storage"
          label={translations['s-incidents-label-store']}
          defaultValue={translations['s-incidents-placeholder-store']}
          customClass="col-12 col-md-6"
          options={stores}
          validations={{ required: false }}
        />

        <DatetimePicker
          name="date_incident"
          customClass="col-12 col-md-6"
          label={translations['s-incidents-label-datestart']}
          validations={{ required: true }}
          handleChange
        />

        <DatetimePicker
          name="date_released"
          customClass="col-12 col-md-6"
          label={translations['s-incidents-label-datefinish']}
          validations={{ required: false }}
          handleChange
        />

        <WafoFormTextArea
          name="comment"
          customClass="col-12"
          label={translations['s-incidents-label-comment']}
          placeholder={translations['s-incidents-placeholder-comment']}
        />

        <div className="col-12 text-center">
          <div className="divider" />
          <button type="submit" form="form-shipincidents" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </WafoForm>
    </div>
  );
};

export default FormShipIncident;
