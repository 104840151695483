import React from 'react';
import { useIntl } from 'react-intl';

const useTranslation = () => {
  const intl = useIntl();

  const translation = React.useMemo(
    () => ({
      translations: intl.messages,
      locale: intl.locale,
      defaultLocale: intl.defaultLocale,
    }),
    [intl],
  );

  return translation;
};

export default useTranslation;
