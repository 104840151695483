import React from 'react';
import PanelContainer from '../panelContainer';
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import NewTrain from './new/newTrain';
import LoadExcel from './new/loadExcel';
import TableTrains from './tableTrains';
import TrainDetails from './details/trainDetails';

const Trains = () => {
  const match = useRouteMatch();

  return (
    <PanelContainer>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/list`} />
        <Route path={`${match.url}/new`} component={NewTrain} />
        <Route path={`${match.url}/excel`} component={LoadExcel} />
        <Route exact path={`${match.url}/list`} component={TableTrains} />
        <Route path={`${match.url}/details/:id`} component={TrainDetails} />
      </Switch>
    </PanelContainer>
  );
};

export default Trains;
