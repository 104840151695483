import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BaseModal from '#components/modals/baseModal';
import withTranslation from '#components/HOCs/withTranslation';
import FormIncident from '#components/forms/trains/formIncident';
import IncidentType from '#components/UI/extra/incidentType';
import LocationType from '#components/UI/extra/locationType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheck, faTrash, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';
import styles from './incidentModal.module.css';

const IncidentModal = ({ translations, equipment: baseEquipment, onClose }) => {
  const [equipment, setEquipment] = React.useState({
    equipment_incidents: [],
  });

  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  const getData = React.useCallback(async () => {
    try {
      loading.set();
      const resp = await api.get(`/equipments/${baseEquipment.id_equipment}`);
      setEquipment(resp);
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  }, [api, baseEquipment, loading]);

  React.useEffect(() => {
    getData();
  }, [getData]);

  const incidents = React.useMemo(() => {
    return equipment.equipment_incidents
      .sort((a, b) => (a.id_ei < b.id_ei ? 1 : b.id_ei < a.id_ei ? -1 : 0))
      .map(i => ({
        id: i.id_ei,
        type: i.type,
        location: i.equipment_location.location,
        date: {
          incident: i.date_incident,
          released: i.date_released,
        },
      }));
  }, [equipment]);

  /** ***************************** */
  /** Incidents */
  /** ***************************** */

  const [edit, setEdit] = React.useState(false);

  const onEdit = id => {
    const incident = equipment.equipment_incidents.find(i => i.id_ei === id);
    setEdit(incident);
  };

  const onReleaseIncident = async id => {
    const incident = equipment.equipment_incidents.find(i => i.id_ei === id);
    try {
      loading.set();
      await api.put(`/equipments/incidents/${incident.id_ei}`, {
        date_released: moment().toISOString(),
      });

      alerts.success({
        title: translations['alerts-success-title'],
        message: translations['alerts-success-msg'],
        timer: 3000,
      });

      getData();
      loading.stop();
      setEdit(false);
    } catch (error) {
      loading.stop();
    }
  };

  const onDeleteIncident = async id => {
    try {
      loading.set();
      await api.delete(`/equipments/incidents/${id}`);
      getData();
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  return (
    <BaseModal
      title={`${translations['equipments-m-incidents-i-title']} [${baseEquipment.equipment_id}]`}
      modalStyle={{ width: '48em', maxHeight: '80vh', minHeight: '40vh' }}
      tabs={translations['equipments-m-incidents-i-tabs']}
      closeModal={onClose}
    >
      <div>
        <FormIncident
          key={equipment.equipment_incidents.length}
          equipmentId={baseEquipment.id_equipment}
          equipmentLocation={baseEquipment.equipment_location.id_el}
          onSubmitSuccess={getData}
        />
      </div>
      <div>
        {edit && (
          <>
            <div className={styles['go-back']}>
              <button
                type="button"
                className={`btn btn-sm btn-link ${styles['btn-back']}`}
                onClick={() => setEdit(false)}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
              <h6>
                <strong>{translations['incidents-f-b-edit']}:</strong>{' '}
                <IncidentType translations={translations} type={edit.type} />
              </h6>
            </div>
            <FormIncident
              key={equipment.equipment_incidents.length}
              equipmentId={baseEquipment.id_equipment}
              equipmentLocation={baseEquipment.equipment_location.id_el}
              incident={edit}
              onSubmitSuccess={() => {
                setEdit(false);
                getData();
              }}
            />
          </>
        )}
        {!edit && (
          <div className="row">
            <div className="col-12">
              <div className={styles['table-wrapper']}>
                <table className="table table-sm table-striped">
                  <thead>
                    <tr>
                      {translations['incidents-m-single-columns'].map((column, index) => (
                        <th key={index}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {incidents.map(row => (
                      <React.Fragment key={row.id}>
                        <tr>
                          <td>{row.id}</td>
                          <td>
                            <IncidentType translations={translations} type={row.type} />
                          </td>
                          <td style={{ lineHeight: 1 }}>
                            <span>{row.location.name}</span>
                            <br />
                            <span style={{ fontSize: '0.9em' }}>
                              <LocationType type={row.location.type} />
                            </span>
                          </td>
                          <td style={{ lineHeight: 1, whiteSpace: 'nowrap' }}>
                            <span title="Incident Date">
                              {row.date.incident
                                ? moment(row.date.incident).format('DD/MM/YYYY HH:mm')
                                : 'No date available'}
                            </span>
                            <br />
                            <span title="Released Date" style={{ fontSize: '0.9em' }}>
                              {row.date.released
                                ? moment(row.date.released).format('DD/MM/YYYY HH:mm')
                                : 'No date available'}
                            </span>
                          </td>
                          <td>
                            <button type="button" className="btn btn-table btn-s" onClick={() => onEdit(row.id)}>
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button
                              disabled={row.date.released}
                              type="button"
                              className="btn btn-table btn-success"
                              onClick={() => onReleaseIncident(row.id)}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button
                              type="button"
                              className="btn btn-table btn-danger"
                              onClick={() => onDeleteIncident(row.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                    {!incidents.length && (
                      <tr>
                        <td colSpan="5" className="text-center">
                          {translations['incidents-modal-single-emptytable']}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </BaseModal>
  );
};

IncidentModal.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  equipment: PropTypes.any,
};

IncidentModal.defaultProps = {
  updateData: f => f,
};

export default withTranslation(IncidentModal, ['equipments', 'incidents', 'locations']);
