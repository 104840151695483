import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '#components/modals/baseModal';
import withTranslation from '#components/HOCs/withTranslation';
import FormWarehouseTransfer from '#components/forms/warehouses/formTransfer';

const WarehouseTransferModal = ({ translations, updateData, warehouse, onClose }) => {
  const handleSubmit = () => {
    onClose();
    updateData();
  };

  return (
    <BaseModal
      title={translations['warehouses-transfer-f-title']}
      modalStyle={{ width: '40em', minHeight: '60vh' }}
      closeModal={onClose}
    >
      <FormWarehouseTransfer onSubmitSuccess={handleSubmit} warehouse={warehouse} />
    </BaseModal>
  );
};

WarehouseTransferModal.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  updateData: PropTypes.func,
};

WarehouseTransferModal.defaultProps = {
  updateData: f => f,
};

export default withTranslation(WarehouseTransferModal, 'warehouses');
