import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from '#components/HOCs/withTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faEdit } from '@fortawesome/free-solid-svg-icons';
import InfoColumn from '#components/UI/general/infoColumn/infoColumn';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import TableStore from './tableStore';
import TableWarehouseLogs from './tableWarehouseLogs';
import styles from './storeDetails.module.css';

const StoreDetails = ({ translations, store, openEdit, updateData }) => {
  const [tab, setTab] = React.useState('material_stores');

  const units = React.useMemo(() => {
    const units = store.store_materials.reduce((accumulator, { material }) => {
      const type = `${translations['materials-f-types'][material.material_type - 1]} [${material.unit_type}]`;
      if (!accumulator.includes(type)) {
        accumulator.push(type);
      }
      return accumulator;
    }, []);
    if (!units.length) {
      return [translations['warehouses-d-s-info-nounits']];
    }
    return units;
  }, [store, translations]);

  const lastUpdate = React.useMemo(() => {
    let lastUpdate = store.updatedAt;
    store.user_stores.forEach(us => {
      if (new Date(us.updatedAt) > new Date(lastUpdate)) {
        lastUpdate = us.updatedAt;
      }
    });
    store.store_materials.forEach(sm => {
      if (new Date(sm.updatedAt) > new Date(lastUpdate)) {
        lastUpdate = sm.updatedAt;
      }
    });
    return lastUpdate;
  }, [store]);

  const clients = React.useMemo(() => {
    return store.user_stores.reduce((accumulator, user) => {
      if (!accumulator.includes(user.id_user)) {
        accumulator.push(user.id_user);
      }
      return accumulator;
    }, []);
  }, [store]);

  const storeMaterials = React.useMemo(() => {
    const storeMaterials = store.store_materials.map(sm => ({
      ...sm,
      total: 0,
      user_stores: [],
      limit_quantity: sm.limit_quantity * sm.material.quantity,
    }));

    store.user_stores.forEach(us => {
      const index = storeMaterials.findIndex(sm => sm.material.id_material === us.material.id_material);
      if (index === -1) {
        storeMaterials.push({
          id_sm: null,
          limit_quantity: 0,
          total: us.available_quantity * us.material.quantity,
          id_material: us.id_material,
          material: us.material,
          disabled: false,
          user_stores: [us],
        });
      } else {
        storeMaterials[index].total += us.available_quantity * us.material.quantity;
        storeMaterials[index].user_stores.push(us);
      }
    });

    return storeMaterials;
  }, [store]);

  return (
    <div className="card">
      <div className="card-body">
        <div className={styles['head']}>
          <h5 className="card-subtitle">
            {store.name}
            {store.default_store && <FontAwesomeIcon icon={faCertificate} className={styles['certified']} />}
          </h5>
          <button type="button" className="btn btn-sm btn-link with-icon" onClick={openEdit}>
            <FontAwesomeIcon icon={faEdit} />
            <FormattedMessage id="warehouses-t-b-edit" />
          </button>
        </div>

        <div className={styles['information']}>
          <div className="row">
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['warehouses-d-s-info-materials']}
              info={`${store.store_materials.length} ${translations['warehouses-d-s-info-materials-desc']}`}
            />
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['warehouses-d-s-info-units']}
              info={units.join(', ')}
            />
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['warehouses-d-s-info-clients']}
              info={`${clients.length} ${translations['warehouses-d-s-info-clients-desc']}`}
            />
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['warehouses-d-s-info-update']}
              info={moment(lastUpdate).format('DD MMMM YYYY HH:mm')}
            />
          </div>
        </div>

        <ul className={`nav nav-tabs ${styles['tabs']}`}>
          <li className="nav-item">
            <button
              type="button"
              className={`btn btn-link nav-link ${tab === 'material_stores' && 'active'}`}
              onClick={() => setTab('material_stores')}
            >
              <FormattedMessage id="warehouses-d-s-tab-materials" />
            </button>
          </li>
          <li className="nav-item">
            <button
              type="button"
              className={`btn btn-link nav-link ${tab === 'logs' && 'active'}`}
              onClick={() => setTab('logs')}
            >
              <FormattedMessage id="warehouses-d-s-tab-logs" />
            </button>
          </li>
        </ul>

        {tab === 'material_stores' && <TableStore stores={storeMaterials} />}
        {tab === 'logs' && <TableWarehouseLogs store={store} />}
      </div>
    </div>
  );
};

StoreDetails.propTypes = {
  store: PropTypes.any,
  updateData: PropTypes.func,
  locale: PropTypes.string,
  translations: PropTypes.any,
  openEdit: PropTypes.func,
};

export default withTranslation(StoreDetails, ['warehouses', 'materials']);
