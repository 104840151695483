import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { removeModal } from '../makeModal';
import styles from './confirmModal.module.css';

const ConfirmModal = ({ closeModal = removeModal, title, message, onCancel = removeModal, onAccept, translations }) => {
  const handleAccept = () => {
    onAccept();
    closeModal();
  };

  return (
    <div className={styles['modal']}>
      <div className={`card animated fadeInUp faster ${styles['content']}`}>
        <div className="card-body">
          <FontAwesomeIcon icon={faExclamation} className={styles.icon} />
          <div className={styles.title}>{title || translations['confirm-title']}</div>
          <div className={styles.message}>{message || translations['confirm-message']}</div>
          <div className={styles.buttons}>
            <button type="button" className="btn btn-danger" onClick={() => onCancel()}>
              {translations['confirm-deny']}
            </button>
            <button type="button" className="btn btn-s" onClick={handleAccept}>
              {translations['confirm-accept']}
            </button>
          </div>
        </div>
      </div>
      <div className={styles['backdrop']} onClick={() => closeModal()} />
    </div>
  );
};

ConfirmModal.propTypes = {
  closeModal: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  translations: PropTypes.any,
};

ConfirmModal.defaultProps = {
  translations: {
    'confirm-deny': 'Cancel',
    'confirm-accept': 'Accept',
    'confirm-title': 'Warning',
    'confirm-message': 'Are you sure?',
  },
};

export default ConfirmModal;
