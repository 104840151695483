import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withTranslation from '#components/HOCs/withTranslation';
import styles from './equipmentLogs.module.css';

const EquipmentLogs = ({ translations, logs }) => {
  return (
    <div id="equipment-logs">
      <p>{translations['equipments-m-details-logs-desc']}</p>
      <div className={styles['table-wrapper']}>
        <table className="table table-sm table-bordered table-striped">
          <thead>
            <tr>
              {translations['equipments-m-details-logs-columns'].map((column, index) => (
                <th key={index}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {logs.map(l => (
              <tr key={l.id_log}>
                <td>{translations[l.type]}</td>
                <td className={styles['td-date']}>{moment(l.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                <td className={styles['td-user']}>#{l.user.id_user}</td>
                <td className={styles['td-info']}>
                  {(function() {
                    switch (l.type) {
                      case 'Equipment/UpdateEquipment':
                        return (
                          <ul className={styles['list']}>
                            {Object.keys(l.json).map((key, i) => (
                              <li key={i}>
                                <strong>{key}</strong>
                                <br />
                                <span className={styles['list-change']}>Before: {l.json[key].before}</span>
                                <br />
                                <span className={styles['list-change']}>After: {l.json[key].after}</span>
                              </li>
                            ))}
                          </ul>
                        );
                      case 'Equipment/NewLocation':
                        return (
                          <p>
                            {l.json.name} ({l.json.location_external})
                          </p>
                        );
                      case 'Equipment/UpdateEquipmentPedimento':
                        return null;
                      case 'Equipment/NewManualLocation':
                        return (
                          <p>
                            {l.json.name} ({l.json.location_external})
                          </p>
                        );
                      case 'EquipmentLocation/UpdateInfo':
                        return null;
                      case 'Incident/NewIncident':
                        return (
                          <p>
                            {translations['Incident/NewIncident-desc']} {l.id_reference}
                          </p>
                        );
                      case 'Incident/UpdateIncident':
                        return (
                          <p>
                            {translations['Incident/UpdateIncident-desc']} {l.id_reference}
                          </p>
                        );
                      case 'Incident/DeleteIncident':
                        return (
                          <p>
                            {translations['Incident/DeleteIncident-desc']} {l.id_reference}
                          </p>
                        );
                      case 'Incident/NewIncidentDocument':
                        return (
                          <p>
                            {translations['Incident/NewIncidentDocument-desc']} {l.id_reference}
                          </p>
                        );
                      default:
                        return null;
                    }
                  })()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

EquipmentLogs.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  logs: PropTypes.array,
};

export default withTranslation(EquipmentLogs, 'equipments');
