const global = require('./global.json');
const auth = require('./auth.json');
const errors = require('./errors.json');
const menus = require('./menus.json');
const trains = require('./trains.json');
const locations = require('./locations.json');
const equipments = require('./equipments.json');
const materials = require('./materials.json');
const notifications = require('./notifications.json');
const users = require('./users.json');
const incidents = require('./incidents.json');
const customs = require('./customs.json');
const warehouses = require('./warehouses.json');
const drivers = require('./drivers.json');
const vehicles = require('./vehicles.json');
const ships = require('./ships.json');
const shipsIncidents = require('./shipsIncidents.json');
const belts = require('./belts.json');
const reports = require('./reports.json');
const profile = require('./profile.json');

// TODO: Incluir todos los errores faltantes.

module.exports = {
  global,
  auth,
  errors,
  menus,
  trains,
  locations,
  equipments,
  materials,
  notifications,
  users,
  incidents,
  customs,
  warehouses,
  drivers,
  vehicles,
  ships,
  shipsIncidents,
  belts,
  reports,
  profile,
};
