import React from 'react';
import useApi from '#hooks/api/useApi';
import useLoading from '#context/hooks/useLoading';
import useQuery from '#hooks/router/useQuery';
import { useHistory, useRouteMatch, Link } from 'react-router-dom';
import { DataTable } from '@wafo/table';
import useTranslation from '#hooks/redux/useTranslation';
import { FormattedMessage } from 'react-intl';
import { makeModal } from '#components/modals/makeModal';
import ConfirmModal from '#components/modals/confirmModal/confirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff, faToggleOn, faEdit } from '@fortawesome/free-solid-svg-icons';
import { WafoFormSelect } from '@wafo/forms';

const TableBelts = () => {
  const api = useApi();
  const loading = useLoading();

  const { translations, locale } = useTranslation();

  const query = useQuery();
  const history = useHistory();
  const match = useRouteMatch();

  /** ******************************************************* */
  /** Warehouses */
  /** ******************************************************* */

  const [warehouses, setWarehouses] = React.useState([]);
  const [filters, setFilters] = React.useState({
    id_location: '',
  });

  React.useEffect(() => {
    async function getWarehouses() {
      loading.set();
      try {
        const resp = await api.get('/locations', {
          params: {
            limit: 999,
            page: 1,
            type: '4,5',
          },
        });
        setWarehouses(resp.rows);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
    getWarehouses();
  }, [api, loading]);

  /** ******************************************************* */
  /** Table */
  /** ******************************************************* */

  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const tableRef = React.useRef();

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await api.get('/belts', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
            ...(filters.id_location && { id_location: filters.id_location }),
          },
        });
        setTotalRows(resp.count);
        setRows(
          resp.rows.map(row => ({
            id: row.id_belt,
            status: row.disabled,
            name: row.name,
            warehouse: row.location,
            options: row,
          })),
        );
        history.replace({
          pathname: match.url,
          search: `?page=${page}`,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [api, loading, history, match.url, filters],
  );

  const toggleDisabled = React.useCallback(
    async (id, disabled) => {
      async function callDisable() {
        try {
          loading.set();
          await api.put(`/belts/${id}`, { disabled: !disabled });
          loading.stop();
          tableRef.current.forceUpdate();
        } catch (error) {
          loading.stop();
        }
      }
      makeModal(ConfirmModal, {
        message: translations['belts-t-confirm-m'],
        translations,
        onAccept: () => {
          callDisable();
        },
      });
    },
    [api, loading, translations],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>#{val}</span>,
      status: val => (
        <span className={`badge table-badge ${!val ? 'badge-success' : 'badge-warning'}`}>
          <FormattedMessage id={!val ? 'global-t-enabled' : 'global-t-disabled'} />
        </span>
      ),
      warehouse: val => (
        <span>
          #{val.id_location} - {val.name}
        </span>
      ),
      options: {
        render: val => (
          <React.Fragment>
            <button
              type="button"
              className={`btn btn-table ${val.disabled ? 'btn-danger' : 'btn-success'}`}
              title={val.disabled ? 'Enable' : 'Disable'}
              onClick={() => {
                toggleDisabled(val.id_belt, val.disabled);
              }}
            >
              <FontAwesomeIcon icon={val.disabled ? faToggleOff : faToggleOn} />
            </button>
            <Link to={`/panel/warehouses/belts/new/${val.id_belt}`} className="btn btn-table btn-primary with-icon">
              <FontAwesomeIcon icon={faEdit} />
              {translations['global-t-b-edit']}
            </Link>
          </React.Fragment>
        ),
        style: { whiteSpace: 'no-wrap' },
      },
    }),
    [toggleDisabled, translations],
  );

  return (
    <div>
      <DataTable
        locale={locale}
        columns={translations['belts-t-columns']}
        rows={rows}
        totalRows={totalRows}
        tableClass="table table-striped table-sm"
        onPagination={getData}
        noRowsMessage={translations['belts-t-norows']}
        initialPage={parseInt(query.get('page'), 10)}
        columnsConfig={columnsConfig}
        controls={
          <div className="row">
            <WafoFormSelect
              name="warehouse"
              defaultValue={translations['belts-t-filters-warehouse']}
              customClass="col-12 col-md-6"
              options={warehouses.map(w => ({
                value: w.id_location,
                display: `#${w.id_location} - ${w.name}`,
              }))}
              value={filters.id_location}
              onChangeCallback={({ target: { value } }) => setFilters(prev => ({ ...prev, id_location: value }))}
            />
          </div>
        }
      />
    </div>
  );
};

export default TableBelts;
