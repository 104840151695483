import React from 'react';
import BaseModal from '#components/modals/baseModal';
import useTranslation from '#hooks/redux/useTranslation';
import FormShipStores from '../../forms/ships/formShipStores';

const ShipStoresModal = ({ ship, updateData, onClose }) => {
  const { translations } = useTranslation();

  const handleClose = () => {
    updateData();
    onClose();
  };

  return (
    <BaseModal
      title={translations['ships-f-store-title']}
      modalStyle={{ width: '48em', maxHeight: '80vh', minHeight: '40vh' }}
      closeModal={onClose}
    >
      <div className="row">
        <div className="col-12">
          <FormShipStores ship={ship.id_ship} stores={ship.shipment_storages} onSubmitSuccess={handleClose} />
        </div>
      </div>
    </BaseModal>
  );
};

export default ShipStoresModal;
