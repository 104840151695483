import React from 'react';
import useTranslation from '#hooks/redux/useTranslation';
import { WafoForm, WafoFormSelect } from '@wafo/forms';
import useApi from '#hooks/api/useApi';
import useLoading from '#context/hooks/useLoading';
import useAlerts from '#context/hooks/useAlerts';
import DatetimePicker from '../customForms/datetimePicker/datetimePicker';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { materialQuantity } from '#utils/utils';
import styles from './ships.module.css';

const FormShipCharge = ({ charges, stores: storesProp, locationId, onCancel, onSubmitSuccess }) => {
  const { translations, locale } = useTranslation();
  const api = useApi();
  const loading = useLoading();
  const alerts = useAlerts();

  const [belts, setBelts] = React.useState([]);

  React.useEffect(() => {
    async function getBelts() {
      loading.set();
      try {
        const resp = await api.get('/belts', {
          params: {
            limit: 999,
            id_location: locationId,
          },
        });
        setBelts(resp.rows);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }

    getBelts();
  }, [api, locationId, loading]);

  const handleSubmit = async (form, values) => {
    if (form.valid) {
      loading.set();
      try {
        await Promise.all(
          charges.map(charge => {
            const charge_material = charge.charge_materials[0];

            return api.post('/ships/charges', {
              id_ship_storage: values.id_ship_storage,
              id_belt: values.id_belt,
              ...(values.placement_date_start && {
                placement_date_start: moment(values.placement_date_start).toISOString(),
              }),
              ...(values.placement_date_end && {
                placement_date_end: moment(values.placement_date_end).toISOString(),
              }),
              ...(values.charge_date_start && {
                charge_date_start: moment(values.charge_date_start).toISOString(),
              }),
              ...(values.charge_date_end && {
                charge_date_end: moment(values.charge_date_end).toISOString(),
              }),
              ...(values.departure_date && {
                departure_date: moment(values.departure_date).toISOString(),
              }),
              id_charge_material: charge_material.id_charge_material,
              quantity: charge_material.current_quantity,
            });
          }),
        );

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });

        onSubmitSuccess();
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
  };

  const stores = React.useMemo(() => {
    return storesProp.map(x => {
      let {
        material,
        material: { unit_type },
        limit_quantity,
        charged_material,
        stowage_plan: { stowage_plan },
      } = x;

      limit_quantity = materialQuantity(material, limit_quantity);
      charged_material = materialQuantity(material, charged_material);
      stowage_plan = materialQuantity(material, stowage_plan);

      return {
        value: x.id_ship_storage,
        display: `${limit_quantity} | ${charged_material}/${stowage_plan} ${unit_type}`,
      };
    });
  }, [storesProp]);

  const loadTotal = React.useMemo(() => {
    const load = charges.reduce((accumulator, charge) => {
      const { current_quantity, material } = charge.charge_materials[0];
      accumulator += materialQuantity(material, current_quantity);
      return accumulator;
    }, 0);
    return {
      load,
      material: charges.length ? charges[0].charge_materials[0].material : null,
    };
  }, [charges]);

  return (
    <div>
      <WafoForm formId="form-transportcharge" locale={locale} onSubmit={handleSubmit} values={{}}>
        <div className="col-12 col-md-4 wafo-wrapper">
          <div className="wafo-input form-group">
            <label>
              <FormattedMessage id="ships-f-load-label-charge" />
            </label>
            <ul className={styles['list']}>
              <li>Loads: {charges.length}</li>
              <li>
                Total: {materialQuantity(loadTotal.material, loadTotal.load)} {loadTotal.material.unit_type}
              </li>
            </ul>
          </div>
        </div>

        <WafoFormSelect
          name="id_ship_storage"
          label={translations['ships-f-load-label-store']}
          defaultValue={translations['ships-f-load-placeholder-store']}
          customClass="col-12 col-md-4"
          options={stores}
          validations={{ required: true }}
        />

        <WafoFormSelect
          name="id_belt"
          label={translations['ships-f-load-label-belt']}
          defaultValue={translations['ships-f-load-placeholder-belt']}
          customClass="col-12 col-md-4"
          options={belts.map(x => ({
            value: x.id_belt,
            display: `#${x.id_belt} - ${x.name}`,
          }))}
          validations={{ required: true }}
        />

        <div className="col-12">
          <p className="form-p-divider">
            <FormattedMessage id="ships-f-transport-divider-1" />
          </p>
        </div>

        <DatetimePicker
          name="placement_date_start"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-placement-start']}
          validations={{ required: false }}
          handleChange
        />

        <DatetimePicker
          name="placement_date_end"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-placement-end']}
          validations={{ required: false }}
          handleChange
        />

        <DatetimePicker
          name="charge_date_start"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-charge-start']}
          validations={{ required: false }}
          handleChange
        />

        <DatetimePicker
          name="charge_date_end"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-charge-end']}
          validations={{ required: false }}
          handleChange
        />

        <DatetimePicker
          name="departure_date"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-departure']}
          validations={{ required: false }}
          handleChange
        />

        <div className="col-12 text-right">
          <div className="divider" />
          <button type="button" className="btn btn-link" onClick={onCancel}>
            <FormattedMessage id="confirm-deny" />
          </button>
          <button type="submit" form="form-transportcharge" className="btn btn-s">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </WafoForm>
    </div>
  );
};

export default FormShipCharge;
