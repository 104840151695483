import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { WafoForm, WafoFormInput, WafoFormAutocomplete } from '@wafo/forms';
import withTranslation from '#components/HOCs/withTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import useApi from '#hooks/api/useApi';
import MaterialType from '#components/UI/extra/materialType';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormStore = ({ locale, translations, location, onSubmitSuccess, editStore }) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  const [store, setStore] = React.useState({
    store_materials: [],
    ...editStore,
    id_location: location,
  });

  // TODO: Cargar lista completa de locations para cuando se use fuera del modal.
  const [locations] = React.useState([location]);
  // TODO: Filtrar materiales ya seleccionados para evitar repetidos.
  const [baseMaterials, setBaseMaterials] = React.useState([]);

  React.useEffect(() => {
    async function getMaterials() {
      try {
        loading.set();
        const resp = await api.get('/materials', {
          params: {
            limit: 999999,
          },
        });
        setBaseMaterials(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getMaterials();
  }, [api, loading]);

  const addMaterialStore = () => {
    setStore(prev => ({
      ...prev,
      store_materials: [
        ...prev.store_materials,
        {
          uuid: uuidv4(),
          id_material: '',
          limit_quantity: '',
        },
      ],
    }));
  };

  const removeMaterialStore = id => {
    setStore(prev => {
      const index = prev.store_materials.findIndex(x => x.id_sm === id || x.uuid === id);
      return {
        ...prev,
        store_materials: [...prev.store_materials.slice(0, index), ...prev.store_materials.slice(index + 1)],
      };
    });
  };

  async function handleSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();

        // Store
        const resp = editStore
          ? await api.put(`/stores/${editStore.id_store}`, {
              name: values.name,
            })
          : await api.post('/stores', {
              id_location: values.id_location.id_location,
              name: values.name,
            });

        // Add/edit store_materials
        const store_materials = Object.keys(values).reduce((accumulator, key) => {
          if (key !== 'name' && key !== 'id_location') {
            const keys = key.split('/');
            accumulator[keys[0]] = {
              ...accumulator[keys[0]],
              [keys[1]]: values[key],
            };
          }
          return accumulator;
        }, {});

        await Promise.all(
          Object.keys(store_materials).map(key =>
            api.post('/stores/materials', {
              id_store: resp.id_store,
              id_material: store_materials[key].id_material.id_material,
              limit_quantity: store_materials[key].limit_quantity,
            }),
          ),
        );

        // Delete stores_materials
        if (editStore) {
          const toDelete = editStore.store_materials.filter(x => store.store_materials.findIndex(y => x.id_sm === y.id_sm) === -1);
          await Promise.all(
            toDelete.map(d =>
              api.delete('/stores/materials', {
                params: {
                  id_material: d.id_material,
                  id_store: d.id_store,
                },
              }),
            ),
          );
        }

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });

        onSubmitSuccess();
        loading.stop();
      } catch (error) {
        loading.stop();
        console.error(error);
      }
    }
  }

  const initialValues = React.useMemo(() => {
    const initial = {
      id_location: location,
    };
    if (editStore) {
      initial.name = editStore.name;
      editStore.store_materials.forEach(sm => {
        initial[`${sm.id_sm}/id_material`] = sm.material;
        initial[`${sm.id_sm}/limit_quantity`] = sm.limit_quantity;
      });
    }
    return initial;
  }, [editStore, location]);

  return (
    <div>
      <WafoForm formId="form-store" locale={locale} onSubmit={handleSubmit} values={initialValues}>
        <WafoFormInput
          type="text"
          name="name"
          label={translations['warehouses-f-store-label-name']}
          placeholder={translations['warehouses-f-store-placeholder-name']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormAutocomplete
          name="id_location"
          customClass="col-12 col-md-4"
          label={translations['warehouses-f-store-label-location']}
          placeholder={translations['warehouses-f-store-placeholder-location']}
          items={locations}
          filterItems={(items, query) => items.filter(i => i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)}
          renderInput={item => item.name}
          renderItem={item => (
            <p style={{ margin: '0', padding: '0.5rem', borderBottom: '1px solid #f5f5f5' }}>
              <span>{item.name}</span>
            </p>
          )}
          extraProps={{
            autoComplete: 'off',
            disabled: location,
          }}
          validations={{ required: true }}
          handleChange
        />

        <div className="col-12">
          <div className="form-divider-with-btn">
            <span>
              <strong>
                <FormattedMessage id="warehouses-f-store-sub-1" />
              </strong>
            </span>
            <button type="button" className="btn btn-sm btn-link with-icon" onClick={addMaterialStore}>
              <FontAwesomeIcon icon={faPlus} />
              <FormattedMessage id="warehouses-f-store-b-add" />
            </button>
          </div>
        </div>

        {store.store_materials.length === 0 && (
          <div className="col-12">
            <p className="form-well-msg">
              <FormattedMessage id="warehouses-f-store-empty" />
            </p>
          </div>
        )}

        {store.store_materials.length > 0 &&
          store.store_materials.map(m => (
            <div key={m.id_sm || m.uuid} className="col-12">
              <div className="row">
                <WafoFormAutocomplete
                  name={`${m.id_sm || m.uuid}/id_material`}
                  customClass="col-6"
                  label={translations['warehouses-f-store-label-material']}
                  placeholder={translations['warehouses-f-store-placeholder-material']}
                  items={baseMaterials}
                  filterItems={(items, query) => items.filter(i => i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)}
                  renderInput={item => `[${item.unit_type} - ${translations['materials-f-types'][item.material_type - 1]}] ${item.name}`}
                  renderItem={item => (
                    <p style={{ margin: '0', padding: '0.5rem', lineHeight: 1, borderBottom: '1px solid #f5f5f5' }}>
                      <span>{item.name}</span>
                      <br />
                      <span style={{ fontSize: '0.85em', color: '#777777' }}>
                        {item.unit_type}
                        {' - '}
                        <MaterialType translations={translations} type={item.material_type} />
                      </span>
                    </p>
                  )}
                  extraProps={{
                    autoComplete: 'off',
                  }}
                  validations={{ required: true }}
                  handleChange
                />

                <WafoFormInput
                  type="number"
                  name={`${m.id_sm || m.uuid}/limit_quantity`}
                  label={translations['warehouses-f-store-label-limit']}
                  placeholder={translations['warehouses-f-store-placeholder-limit']}
                  customClass="col-4"
                  validations={{ required: true }}
                />

                <div className="col-2 form-lineBtn-nolabel">
                  <button type="button" className="btn btn-link" onClick={() => removeMaterialStore(m.id_sm || m.uuid)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              </div>
            </div>
          ))}
      </WafoForm>

      <div className="row">
        <div className="col-12 text-center">
          <button type="submit" form="form-store" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </div>
    </div>
  );
};

FormStore.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
  location: PropTypes.any,
  editStore: PropTypes.any,
};

export default withTranslation(FormStore, ['warehouses', 'materials']);
