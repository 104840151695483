import React from 'react';
import PropTypes from 'prop-types';
import { useSegroveContext } from '#context/segrove-context';
import styles from './loadingBar.module.css';

const LoadingBar = ({ show, color, backgroundColor, wrapperBackgroundColor, height }) => {
  const {
    state: { loading },
  } = useSegroveContext();

  if (!show) {
    return null;
  }
  return (
    <div className={styles['loading-bar']} style={{ backgroundColor: wrapperBackgroundColor, height }}>
      {loading.length > 0 && (
        <div className={styles['bar']} style={{ backgroundColor, height }}>
          <span className={styles['innerBar']} style={{ backgroundColor: color, height }} />
        </div>
      )}
    </div>
  );
};

LoadingBar.propTypes = {
  show: PropTypes.bool,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  wrapperBackgroundColor: PropTypes.string,
  height: PropTypes.string,
};

LoadingBar.defaultProps = {
  show: false,
  color: '#333',
  backgroundColor: '#ddd',
  wrapperBackgroundColor: 'transparent',
  height: '.5rem',
};

export default LoadingBar;
