import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const LocationType = ({ type }) => {
  switch (type) {
    case 1:
      return <FormattedMessage id="locations-type-1" />;
    case 2:
      return <FormattedMessage id="locations-type-2" />;
    case 3:
      return <FormattedMessage id="locations-type-3" />;
    case 4:
      return <FormattedMessage id="locations-type-4" />;
    default:
      return <span>NA</span>;
  }
};

LocationType.propTypes = {
  type: PropTypes.number,
};

export default LocationType;
