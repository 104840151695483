import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '#components/modals/baseModal';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import FormCustoms from '#components/forms/trains/formCustoms';
import moment from 'moment';

const CustomsModal = ({ translations, equipments, onClose }) => {
  const rows = React.useMemo(() => {
    return equipments.map(e => ({
      id: e.equipment_id,
      invoice: e.customs_invoice,
      date: e.customs_date,
    }));
  }, [equipments]);

  return (
    <BaseModal
      title={translations['equipments-m-customs-i-title']}
      modalStyle={{ width: '48em', maxHeight: '80vh', minHeight: '40vh' }}
      closeModal={onClose}
    >
      <div className="row">
        <div className="col-12">
          <p>
            <FormattedMessage id="equipments-m-customs-m-desc" />
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <h6>
            <strong>
              <FormattedMessage id="equipments-m-customs-m-sub" />
            </strong>
          </h6>
          <div className="modal-table-wrapper">
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  {translations['equipments-m-customs-m-columns'].map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map(row => {
                  return (
                    <tr key={row.id}>
                      <td>{row.id}</td>
                      <td>{row.invoice}</td>
                      <td>{row.date ? moment(row.date).format('DD MMMM YYYY HH:mm') : 'NA'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <p className="modal-warning-text">
            <FormattedMessage id="equipments-m-customs-m-warning" />
          </p>
        </div>
        <div className="col-12 col-md-6">
          <FormCustoms equipments={equipments} onSubmitSuccess={onClose} />
        </div>
        <div className="col-12 text-center">
          <button type="submit" form="form-customs" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

CustomsModal.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  equipments: PropTypes.array,
};

CustomsModal.defaultProps = {
  equipmnets: [],
};

export default withTranslation(CustomsModal, ['equipments']);
