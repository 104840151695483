import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import useClickOutside from '#hooks/useClickOutside';
import styles from './buttonDropdown.module.css';

const ButtonDropdown = ({ text, children, btnStyle, side = 'right' }) => {
  const [dropdown, setDropdown] = React.useState(false);
  const toggleDropdown = () => setDropdown(prevState => !prevState);
  const clickRef = useClickOutside(dropdown, toggleDropdown, f => f);

  return (
    <div ref={clickRef} className={styles['dropdown']}>
      <button
        type="button"
        className={`btn btn-sm btn-s ${dropdown && styles['btn-down']}`}
        onClick={toggleDropdown}
        style={btnStyle}
      >
        {text}
        <FontAwesomeIcon icon={dropdown ? faCaretUp : faCaretDown} style={{ marginLeft: '.5rem' }} />
      </button>
      <div
        className={`dropdown-menu ${dropdown && 'show'} ${styles['dropdown-menu']} ${side === 'left' &&
          styles['left']}`}
      >
        {children}
      </div>
    </div>
  );
};

ButtonDropdown.propTypes = {
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default ButtonDropdown;
