import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const SegroveContex = React.createContext();

function reducer(state, action) {
  switch (action.type) {
    /** ************************** */
    /** Sidebar */
    /** ************************** */
    case 'SIDEBAR_TOGGLE':
      return {
        ...state,
        sidebar: !state.sidebar,
      };
    /** ************************** */
    /** Loading */
    /** ************************** */
    case 'LOADING_ADD':
      return {
        ...state,
        loading: [...state.loading, true],
      };
    case 'LOADING_REMOVE':
      if (state.loading.length > 0) {
        const dummy = [...state.loading];
        dummy.pop();
        return {
          ...state,
          loading: dummy,
        };
      }
      return state;
    /** ************************** */
    /** Alerts */
    /** ************************** */
    case 'ALERT_ADD':
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            ...alertDefault,
            ...action.payload,
            uuid: uuidv4(),
          },
        ],
      };
    case 'ALERT_REMOVE': {
      const index = state.alerts.findIndex(x => x.uuid === action.payload);
      return {
        ...state,
        alerts: [...state.alerts.slice(0, index), ...state.alerts.slice(index + 1)],
      };
    }
    /** ************************** */
    /** Locale */
    /** ************************** */
    case 'LOCALE_CHANGE':
      return {
        ...state,
        locale: action.payload,
      };
    /** ************************** */
    /** Default */
    /** ************************** */
    default:
      throw new Error();
  }
}

export const alertDefault = {
  uuid: 'abc123',
  type: 'dark',
  title: 'Alerta',
  message: 'Mensaje de alerta',
  timer: 0,
};

const initialState = {
  sidebar: false,
  loading: [],
  alerts: [],
  locale: 'es',
};

function SegroveProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  // Implementar local storage ?
  return <SegroveContex.Provider value={{ state, dispatch }} {...props} />;
}

const useSegroveContext = () => React.useContext(SegroveContex);

export { SegroveProvider, useSegroveContext };
