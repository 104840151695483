import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '#components/modals/baseModal';
import withTranslation from '#components/HOCs/withTranslation';
import { WafoForm } from '@wafo/forms';
import { FormattedMessage } from 'react-intl';
import LocationType from '#components/UI/extra/locationType';
import DatetimePicker from '#components/forms/customForms/datetimePicker/datetimePicker';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';
import styles from './locationModal.module.css';

function getFormValues(equipments, route, newLocation) {
  let order = 0;
  equipments.forEach(e => {
    if (e.id_location) {
      const location = route.route_locations.find(x => x.id_location === e.id_location);
      if (location.order > order) {
        order = location.order;
      }
    }
  });

  let formValues = {
    values: {},
    disabled: {
      next_date_arrival: newLocation.id_rl === 0,
      next_date_departed: newLocation.id_rl === 0,
    },
  };

  const sameLocation = equipments.filter(x => x.id_location === equipments[0].id_location);
  if (equipments[0].equipment_location && sameLocation.length === equipments.length) {
    const sameArrival = equipments.filter(
      x => x.equipment_location.date_arrival === equipments[0].equipment_location.date_arrival,
    );
    const sameDeparture = equipments.filter(
      x => x.equipment_location.date_departed === equipments[0].equipment_location.date_departed,
    );

    formValues.values = {
      ...(sameArrival.length === equipments.length && {
        // current_date_arrival: equipments[0].equipment_location.date_arrival,
        current_date_arrival: new Date(equipments[0].equipment_location.date_arrival),
      }),
      ...((sameDeparture.length === equipments.length && equipments[0].equipment_location.date_departed) && {
        // current_date_departed: equipments[0].equipment_location.date_departed,
        current_date_departed: new Date(equipments[0].equipment_location.date_departed),
      }),
    };
  } else {
    formValues.disabled.current_date_arrival = true;
    formValues.disabled.current_date_departed = true;
  }

  return {
    formValues,
    order,
  };
}

const LocationModal = ({ locale, translations, equipments, route, onClose }) => {
  const [newLocation, setNewLocation] = React.useState({ id_rl: 0 });

  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  const rows = React.useMemo(() => {
    return equipments.map(e => ({
      id: e.equipment_id,
      currentLocation: { ...e.equipment_location, location: e.location },
    }));
  }, [equipments]);

  const { formValues, order } = React.useMemo(() => {
    return getFormValues(equipments, route, newLocation);
  }, [equipments, route, newLocation]);

  async function handleSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();
        // Destination
        if (newLocation.id_rl) {
          await Promise.all(
            equipments
              .filter(x => !x.id_location || (x.location && x.id_location !== newLocation.id_location))
              .map(l =>
                api.post(`/equipments/${l.id_equipment}/locations`, {
                  id_location: newLocation.id_location,
                  ...(values.next_date_arrival && { date_arrival: values.next_date_arrival }),
                  ...(values.next_date_departed && { date_departed: values.next_date_departed }),
                }),
              ),
          );
        }

        // Update current location
        await Promise.all(
          equipments
            .filter(x => x.id_location)
            .map(l =>
              api.put(`/equipments/locations/${l.equipment_location.id_el}`, {
                ...(values.current_date_arrival && { date_arrival: values.current_date_arrival }),
                ...(values.current_date_departed && { date_departed: values.current_date_departed }),
              }),
            ),
        );

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['equipments-a-updatebl-success'],
          timer: 3000,
        });

        onClose();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }

  return (
    <BaseModal
      title={translations['equipments-m-location-title']}
      modalStyle={{ width: '48em', maxHeight: '80vh' }}
      closeModal={onClose}
    >
      <div className="row">
        <div className="col-12">
          <p>
            <FormattedMessage id="equipments-m-location-desc" />
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <h6 className={styles['subtitle']}>
            <strong>
              <FormattedMessage id="equipments-m-location-subtitle-1" />
            </strong>
          </h6>
          <div className={styles['table-wrapper']}>
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  {translations['equipments-m-locations-columns'].map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map(row => (
                  <tr key={row.id}>
                    <td>{row.id}</td>
                    <td>{row.currentLocation.location ? row.currentLocation.location.name : 'NA'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <h6 className={styles['subtitle']}>
            <strong>{route.name}</strong>
          </h6>
          <ul className={styles['route-list']}>
            {route.route_locations
              .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
              .map((l, i) => {
                // if (l.order < order) { return null; }
                let style = {};
                if (newLocation.id_rl === l.id_rl) {
                  style = { backgroundColor: '#2F80ED', color: '#fff' };
                } else if (l.order <= order) {
                  style = { backgroundColor: '#f7f7f7', color: '#777' };
                }
                return (
                  <li
                    key={l.id_rl}
                    className={styles['route-item']}
                    onClick={() => (l.order <= order ? null : setNewLocation(l))}
                  >
                    <div className={styles['route-position']} style={style}>
                      <span>{i + 1}</span>
                    </div>
                    <div className={styles['route-card']} style={style}>
                      <span>{l.location.name}</span>
                      <span style={{ fontSize: '0.85em' }}>
                        Type: <LocationType type={l.location.type} />
                      </span>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>

      <div className="row" style={{ marginTop: '2rem' }}>
        <div className="col-12">
          <WafoForm formId="form-locations" onSubmit={handleSubmit} locale={locale} values={formValues.values}>
            <DatetimePicker
              name="current_date_arrival"
              customClass="col-6"
              label={translations['equipments-m-locations-f-label-currenta']}
              validations={{ required: !formValues.disabled.current_date_arrival }}
              disabled={formValues.disabled.current_date_arrival}
              handleChange
            />

            <DatetimePicker
              name="current_date_departed"
              customClass="col-6"
              label={translations['equipments-m-locations-f-label-currentd']}
              validations={{ required: !formValues.disabled.current_date_departed }}
              disabled={formValues.disabled.current_date_departed}
              handleChange
            />

            <DatetimePicker
              name="next_date_arrival"
              customClass="col-6"
              label={translations['equipments-m-locations-f-nexta']}
              validations={{ required: !formValues.disabled.next_date_arrival }}
              disabled={formValues.disabled.next_date_arrival}
              handleChange
            />

            <DatetimePicker
              name="next_date_departed"
              customClass="col-6"
              label={translations['equipments-m-locations-f-nextd']}
              // validations={{ required: true }}
              disabled={formValues.disabled.next_date_departed}
              handleChange
            />
          </WafoForm>

          <div className="row">
            <div className="col text-center">
              <button type="submit" form="form-locations" className="btn btn-s btn-submit">
                {translations['form-submit']}
              </button>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
};

LocationModal.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onClose: PropTypes.func,
  equipments: PropTypes.array,
  route: PropTypes.any,
};

LocationModal.defaultProps = {
  onClose: f => f,
  equipments: [],
  route: {},
};

export default withTranslation(LocationModal, ['equipments', 'locations']);
