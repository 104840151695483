import React from 'react';
import { IntlProvider } from 'react-intl';
import translations from '#translations/';
import useLocale from '#context/hooks/useLocale';

// TODO:
// Una forma de seleccionar unicamente que translations quiero ?

const TranslationProvider = ({ children }) => {
  const locale = useLocale();

  const messages = React.useMemo(() => {
    const allMessages = Object.values(translations).reduce(
      (accumulator, translation) => {
        return {
          en: {
            ...accumulator.en,
            ...translation.en,
          },
          es: {
            ...accumulator.es,
            ...translation.es,
          },
        };
      },
      { en: {}, es: {} },
    );
    return allMessages[locale.state];
  }, [locale]);

  return (
    <IntlProvider locale={locale.state} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default TranslationProvider;
