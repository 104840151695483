import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { logout, getToken } from '#utils/authService';
import useApi from '#hooks/api/useApi';
import styles from './navbar.module.css';
import useLocale from '#context/hooks/useLocale';

function Navbar({ onToggleClick }) {
  const [notiCount, setNotiCount] = React.useState(0);
  const history = useHistory();

  const api = useApi();

  React.useEffect(() => {
    async function getCount() {
      try {
        const resp = await api.get('/notifications/count');
        setNotiCount(resp.count);
      } catch (error) {
        //
      }
    }
    getCount();
    const interval = setInterval(getCount, 300000);

    return () => clearInterval(interval);
  }, [api]);

  const username = React.useMemo(() => {
    const decoded = getToken(true);
    return `${decoded.name} ${decoded.last_name}`;
  }, []);

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  const locale = useLocale();

  return (
    <nav className={`navbar navbar-expand-lg navbar-light bg-light ${styles.navbar}`}>
      <button type="button" className={`btn btn-link ${styles.toggle}`} onClick={onToggleClick}>
        <FontAwesomeIcon icon={faBars} />
      </button>

      <div className="collapse navbar-collapse">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/panel/profile">
              <FontAwesomeIcon icon={faUserCircle} style={{ marginRight: '.5rem' }} />
              <FormattedMessage id="menus-navbar-welcome" />, {username}
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/panel/notifications">
              <FontAwesomeIcon icon={faBell} style={{ marginRight: '.5rem' }} />
              <FormattedMessage id="menus-navbar-notifications" />
              <span className="badge badge-light" style={{ marginLeft: '.5rem' }}>
                {notiCount}
              </span>
            </Link>
          </li>
          <li className="nav-item">
            <button className={`nav-link btn btn-link ${styles['btn-idioma']}`} onClick={handleLogout}>
              <FormattedMessage id="menus-navbar-logout" />
            </button>
          </li>
          <li className="nav-item">
            <button className={`nav-link btn btn-link ${styles['btn-idioma']}`} onClick={locale.toggle}>
              <FormattedMessage id="menus-navbar-language" />
            </button>
          </li>
        </ul>
      </div>
    </nav>
  );
}

Navbar.propTypes = {
  onToggleClick: PropTypes.func,
};

export default Navbar;
