import React from 'react';
import { Switch, Route, Redirect, Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage, faKey, faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { getToken } from '#utils/authService';
import FormLogin from '#components/forms/auth/formLogin';
import FormPassword from '#components/forms/auth/formPassword';
import FormRecover from '#components/forms/auth/formRecover';
import AlertList from '#components/UI/alerts/alertList';
import useLocale from '#context/hooks/useLocale';
import styles from './auth.module.css';

const logo = require('#assets/images/logo_notext.png');

const Auth = () => {
  const history = useHistory();

  React.useEffect(() => {
    const token = getToken();
    if (token) {
      history.push('/panel');
    }
  }, [history]);

  const handleRedirects = toRedirect => {
    history.push(toRedirect);
  };

  const locale = useLocale();

  return (
    <div className={styles.auth}>
      <AlertList />
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-sm-12 col-md-5">
            <div className="card">
              <div className="card-body">
                <img src={logo} alt="Logo" className={styles.logo} />
                <h1 className="font-bold font-segrove text-center">SEGROVE</h1>
                <p className="text-center">
                  <strong>
                    <Switch>
                      <Route exact path="/auth/login" render={() => <FormattedMessage id="auth-greet-login" />} />
                      <Route exact path="/auth/recover" render={() => <FormattedMessage id="auth-greet-recover" />} />
                      <Route
                        exact
                        path="/auth/awaitemail"
                        render={() => <FormattedMessage id="auth-greet-awaitemail" />}
                      />
                      <Route path="/auth/forgot" render={() => <FormattedMessage id="auth-greet-forgot" />} />
                    </Switch>
                  </strong>
                </p>

                <Switch>
                  <Route exact path="/auth" render={() => <Redirect to="/auth/login" />} />
                  <Route exact path="/auth/login" render={() => <FormLogin onAfterSubmit={handleRedirects} />} />
                  <Route
                    exact
                    path="/auth/forgot/:token"
                    render={() => <FormPassword onAfterSubmit={handleRedirects} />}
                  />
                  <Route exact path="/auth/recover" render={() => <FormRecover onAfterSubmit={handleRedirects} />} />
                  <Route
                    exact
                    path="/auth/awaitemail"
                    render={() => (
                      <div>
                        <h5>
                          <FormattedMessage id="auth-m-recover-title" />
                        </h5>
                        <p>
                          <FormattedMessage id="auth-m-recover-msg" />
                        </p>
                      </div>
                    )}
                  />
                </Switch>

                <ul className={styles['form-options']}>
                  <Switch>
                    <Route
                      exact
                      path="/auth/login"
                      render={() => (
                        <li>
                          <Link to="/auth/recover">
                            <FontAwesomeIcon icon={faKey} style={{ marginRight: '.25rem' }} />
                            <FormattedMessage id="auth-b-recoverpassword" />
                          </Link>
                        </li>
                      )}
                    />
                    <Route
                      exact
                      path="*"
                      render={() => (
                        <li>
                          <Link to="/auth/login">
                            <FontAwesomeIcon icon={faSignInAlt} style={{ marginRight: '.25rem' }} />
                            <FormattedMessage id="auth-b-login" />
                          </Link>
                        </li>
                      )}
                    />
                  </Switch>
                  <li>
                    <button
                      type="button"
                      className={`btn btn-link ${styles['change-language']}`}
                      onClick={locale.toggle}
                    >
                      <FontAwesomeIcon icon={faLanguage} style={{ marginRight: '.25rem' }} />
                      <FormattedMessage id="auth-b-changelanguage" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
