import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import LocationType from '#components/UI/extra/locationType';
import styles from './locationListItem.module.css';

const LocationListItem = ({ location }) => {
  return (
    <p className={styles['item']}>
      <span>
        <strong>
          #{location.id_location} {location.name}
        </strong>
      </span>
      <br />
      <span style={{ fontSize: '0.9em' }}>
        <FormattedMessage id="routes-f-type" />
        <LocationType type={location.type} />
      </span>
    </p>
  );
};

LocationListItem.propTypes = {
  location: PropTypes.any,
};

export default withTranslation(LocationListItem, 'locations');
