import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import { DataTable } from '@wafo/table';
import useApi from '#hooks/api/useApi';
import useLoading from '#context/hooks/useLoading';
import moment from 'moment';

const TableWarehouseLogs = ({ locale, translations, store }) => {
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const tableRef = React.useRef();

  const api = useApi();
  const loading = useLoading();

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await api.get(`/stores/logs/store/${store.id_store}`, {
          params: {
            limit: size,
            page,
            ...(search && { search }),
          },
        });

        setTotalRows(resp.count);
        setRows(
          resp.rows.map(row => ({
            movement: {
              type: row.type,
              author: row.user_author,
              id: row.id_ustore_log,
            },
            date: row.createdAt,
            material: {
              material: row.user_store.material,
              user_store: row.user_store,
              type: row.type,
              json: row.json,
            },
            info: {
              type: row.type,
              json: row.json,
              user_store: row.user_store,
            },
          })),
        );
        // history.replace(`/panel/warehouses/${page}`);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [api, loading, store],
  );

  const columnsConfig = React.useMemo(
    () => ({
      movement: function col(val) {
        return (
          <React.Fragment>
            <span>
              #{val.id} - {translations[val.type]}
            </span>
            <br />
            <span style={{ fontSize: '0.85em' }}>
              <strong>
                <FormattedMessage id="warehouses-logs-t-author" />:
              </strong>{' '}
              {val.author.name} {val.author.last_name}
            </span>
          </React.Fragment>
        );
      },
      date: {
        render: function col(val) {
          return <span>{moment(val).format('DD MMMM YYYY HH:mm')}</span>;
        },
        style: {
          fontSize: '0.85em',
        },
      },
      material: function col(val) {
        // TODO: Add client
        return (
          <React.Fragment>
            <span>
              <strong>{val.material.name}</strong> |{' '}
              <span style={{ fontSize: '0.85em' }}>
                {translations['materials-f-types'][val.material.material_type - 1]} [{val.material.unit_type}]
              </span>
            </span>
            {val.type === 'Movements/DownloadMovement' && (
              <React.Fragment>
                <br />
                <span style={{ fontSize: '0.85em' }}>
                  <strong>Unloaded:</strong> {(val.json.available_quantity * val.material.quantity).toFixed(3)}
                </span>
              </React.Fragment>
            )}
            {(val.type === 'Transfers/StoreTranferSend' ||
              val.type === 'Transfers/StoreTranferReceived' ||
              val.type === 'Transfers/UserTranferSend' ||
              val.type === 'Transfers/UserTranferReceived') && (
              <React.Fragment>
                <br />
                <span style={{ fontSize: '0.85em' }}>
                  <strong>Transferred:</strong> {(val.json.transfer_weight * val.material.quantity).toFixed(3)}
                </span>
              </React.Fragment>
            )}
            {(val.type === 'Movements/DownloadMovement' ||
              val.type === 'Transfers/StoreTranferSend' ||
              val.type === 'Transfers/StoreTranferReceived' ||
              val.type === 'Transfers/UserTranferSend' ||
              val.type === 'Transfers/UserTranferReceived') && (
              <React.Fragment>
                <br />
                <span style={{ fontSize: '0.85em' }}>
                  <strong>New total:</strong> {(val.user_store.available_quantity * val.material.quantity).toFixed(3)}
                </span>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      },
      info: {
        render: function col(val) {
          return (
            <React.Fragment>
              <span>
                <strong>
                  <FormattedMessage id="warehouses-logs-t-desc" />:
                </strong>{' '}
                <FormattedMessage id={`${val.type}/description`} />
              </span>
              <br />
              <span>
                <strong><FormattedMessage id="warehouses-logs-t-client" />:</strong> #{val.user_store.id_user} - {val.user_store.user.name} {val.user_store.user.last_name}
              </span>
              {val.json.reason && (
                <React.Fragment>
                  <br />
                  <span>
                    <strong>
                      <FormattedMessage id="warehouses-logs-t-reason" />:
                    </strong>{' '}
                    {val.json.reason}
                  </span>
                </React.Fragment>
              )}
            </React.Fragment>
          );
        },
        style: {
          fontSize: '0.85em',
        },
      },
    }),
    [translations],
  );

  return (
    <div>
      <DataTable
        ref={tableRef}
        locale={locale}
        columns={translations['warehouses-logs-t-columns']}
        rows={rows}
        totalRows={totalRows}
        tableClass="table table-striped table-bordered table-sm"
        tableWrapperClass="table-noheader"
        onPagination={getData}
        noRowsMessage={translations['warehouses-logs-t-norows']}
        columnsConfig={columnsConfig}
      />
    </div>
  );
};

TableWarehouseLogs.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  store: PropTypes.any,
};

export default withTranslation(TableWarehouseLogs, ['warehouses', 'materials']);
