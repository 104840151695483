import React from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShip, faList, faEdit } from '@fortawesome/free-solid-svg-icons';
import { DataTable } from '@wafo/table';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import useApi from '#hooks/api/useApi';
import useQuery from '#hooks/router/useQuery';
import useTranslation from '#hooks/redux/useTranslation';
import useLoading from '#context/hooks/useLoading';
import moment from 'moment';

const TableShips = () => {
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const { translations, locale } = useTranslation();
  const loading = useLoading();

  const api = useApi();

  const query = useQuery();
  const history = useHistory();
  const match = useRouteMatch();

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await api.get('/ships', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
          },
        });
        setRows(
          resp.rows.map(row => ({
            id: row.ship_id,
            name: row.name,
            harbor: row.id_location,
            stores: row.ship_storages.length,
            arrival: {
              arrival: row.arrival_date,
              eta: row.eta_date,
            },
            options: row,
          })),
        );
        setTotalRows(resp.count);
        // history.replace(`${page}`);
        history.replace({
          pathname: match.url,
          search: `?page=${page}`,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [api, history, loading, match.url],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: val => <span>{val}</span>,
      harbor: val => <span>{val}</span>,
      arrival: {
        render: val => (
          <React.Fragment>
            <span title="Arrival date">
              {val.arrival ? moment(val.arrival).format('DD MMMM YYYY HH:mm') : <FormattedMessage id="global-na" />}
            </span>
            <br />
            <span title="Arrival ETA" style={{ fontSize: '0.85em' }}>
              {moment(val.eta).format('DD MMMM YYYY HH:mm')}
            </span>
          </React.Fragment>
        ),
        style: { lineHeight: 1 },
      },
      options: val => (
        <React.Fragment>
          <Link
            to={{ pathname: `/panel/ships/new/${val.id_ship}`, state: { ship: val } }}
            className="btn btn-table btn-primary with-icon"
          >
            <FontAwesomeIcon icon={faEdit} />
            <FormattedMessage id="ships-t-b-edit" />
          </Link>
          <Link
            className="btn btn-table btn-secondary"
            to={{ pathname: `/panel/ships/details/${val.id_ship}`, state: { ship: val } }}
          >
            <FontAwesomeIcon icon={faList} style={{ marginRight: '0.5rem' }} />
            <FormattedMessage id="ships-t-b-details" />
          </Link>
        </React.Fragment>
      ),
    }),
    [],
  );

  return (
    <div>
      <CardHeader title={translations['ships-t-title']}>
        <Link to={`/panel/ships/new`} className="btn btn-primary with-icon">
          <FontAwesomeIcon icon={faShip} />
          <FormattedMessage id="ships-t-b-new" />
        </Link>
      </CardHeader>
      <div className="card card-panel">
        <div className="card-body">
          <DataTable
            locale={locale}
            columns={translations['ships-t-columns']}
            rows={rows}
            totalRows={totalRows}
            tableClass="table table-striped table-sm"
            onPagination={getData}
            noRowsMessage={translations['ships-t-norows']}
            initialPage={parseInt(query.get('page'), 10)}
            columnsConfig={columnsConfig}
          />
        </div>
      </div>
    </div>
  );
};

export default TableShips;
