import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from '#components/HOCs/withTranslation';
import { LocalTable } from '@wafo/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const TableNewEquipments = ({ locale, translations, equipments, onChangeBL, onChangeIgnore }) => {
  const rows = React.useMemo(() => {
    return equipments.map(e => ({
      id: `${e.equipmentId.initial} ${e.equipmentId.number}`,
      material: {
        grossWeight: {
          value: e.grossWeight.value,
          unit: e.grossWeight.unit,
        },
        materialWeight: e.materialWeight,
      },
      auth: e.moveAuth,
      bl: { bl: e.bl, id: e.equipmentId.number },
      options: {
        equipment: e.equipmentId.number,
        ignore: e.ignore,
      },
    }));
  }, [equipments]);

  const columnsConfig = React.useMemo(
    () => ({
      material: {
        render: function col(val) {
          return (
            <React.Fragment>
              {val.materialWeight && (
                <span>
                  {val.materialWeight.value} {val.materialWeight.unit}
                </span>
              )}
              {!val.materialWeight && (
                <span>
                  {val.grossWeight.value} {val.grossWeight.unit}
                </span>
              )}
              <br />
              <span style={{ fontSize: '0.85em' }}>
                {val.grossWeight.value} {val.grossWeight.unit}
              </span>
            </React.Fragment>
          );
        },
        style: { lineHeight: 1 },
      },
      bl: {
        render: function col(val) {
          return (
            <input
              type="number"
              className="form-control"
              placeholder={translations['equipments-new-t-f-placeholder-bl']}
              value={val.bl}
              onChange={({ target: { value } }) => onChangeBL(value, val.id)}
            />
          );
        },
        style: { width: '25%', paddingRight: '1rem' },
      },
      options: function col(val) {
        return (
          <React.Fragment>
            <button
              type="button"
              className={`btn btn-table ${!val.ignore ? 'btn-success' : 'btn-danger'}`}
              onClick={() => onChangeIgnore(!val.ignore, val.equipment)}
            >
              <FontAwesomeIcon icon={!val.ignore ? faCheck : faTimes} />
            </button>
          </React.Fragment>
        );
      },
    }),
    [onChangeBL, onChangeIgnore, translations],
  );

  return (
    <div>
      <LocalTable
        locale={locale}
        columns={translations['equipments-new-t-columns']}
        rows={rows}
        tableWrapperClass="table-noreload"
        tableClass="table table-striped table-sm"
        columnsConfig={columnsConfig}
        noRowsMessage={translations['equipments-t-norows']}
        keepPage
      />
    </div>
  );
};

TableNewEquipments.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  equipments: PropTypes.array,
  onChangeBL: PropTypes.func,
  onChangeIgnore: PropTypes.func,
};

export default withTranslation(TableNewEquipments, 'equipments');
