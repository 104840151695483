import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput, WafoFormAutocomplete } from '@wafo/forms';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import FilePicker from '#components/forms/customForms/filePicker/filePicker';
import useApi from '#hooks/api/useApi';
import MaterialType from '#components/UI/extra/materialType';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormTrainExcel = ({ locale, translations, onSubmitSuccess }) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  /** **************************************** */
  /** Extra */
  /** **************************************** */
  const [routes, setRoutes] = React.useState([]);
  const [materials, setMaterials] = React.useState([]);

  React.useEffect(() => {
    async function getRoutes() {
      try {
        loading.set();
        const resp = await api.get('/routes', {
          params: {
            limit: 999,
          },
        });
        setRoutes(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    const getMaterials = async () => {
      try {
        loading.set();
        const resp = await api.get('/materials', {
          params: {
            limit: 999,
            disabled: false,
          },
        });
        setMaterials(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    };
    getMaterials();
    getRoutes();
  }, [api, loading]);

  /** **************************************** */
  /** Submit */
  /** **************************************** */

  async function handleSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();

        const formModel = new FormData();
        formModel.append('type', 1);
        formModel.append('waybill', values.waybill);
        formModel.append('id_route', values.id_route.id_route);
        formModel.append('document', values.document);
        formModel.append('external_id', values.external_id);
        formModel.append('bol_number', values.bol_number);
        formModel.append('train_id', values.train_id);
        formModel.append('id_material', values.material.id_material);

        await api.post(
          `/equipments/waybill/excel`,
          formModel,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        onSubmitSuccess();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    } else {
      alerts.set({
        type: 'warning',
        title: translations['form-warning-title'],
        message: translations['form-warning-message'],
      });
    }
  }

  return (
    <div>
      <WafoForm formId="form-excel" locale={locale} onSubmit={handleSubmit}>
        <WafoFormInput
          type="number"
          name="waybill"
          customClass="col-12 col-md-4"
          label={translations['trains-excel-f-label-waybill']}
          placeholder={translations['trains-excel-f-placeholder-waybill']}
          validations={{ required: true }}
        />

        <WafoFormAutocomplete
          name="id_route"
          customClass="col-12 col-md-4"
          label={translations['trains-excel-f-label-route']}
          placeholder={translations['trains-excel-f-placeholder-route']}
          items={routes}
          filterItems={(items, query) =>
            items.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
          }
          renderInput={item => item.name}
          renderItem={item => (
            <p style={{ margin: '0', padding: '0.75rem 0.5rem', borderBottom: '1px solid #f5f5f5' }}>
              <span>{item.name}</span>
            </p>
          )}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          handleChange
        />

        <FilePicker
          name="document"
          label={translations['trains-excel-f-label-document']}
          placeholder={translations['trains-excel-f-placeholder-document']}
          customClass="col-12 col-md-4"
          fileTypes=".xml, .xlsx"
          validations={{ required: true }}
          handleChange
        />

        <WafoFormInput
          type="text"
          name="train_id"
          customClass="col-12 col-md-4"
          label={translations['trains-excel-f-label-train']}
          placeholder={translations['trains-excel-f-placeholder-train']}
          validations={{ required: true }}
        />

        <WafoFormInput
          type="number"
          name="external_id"
          customClass="col-12 col-md-4"
          label={translations['trains-excel-f-label-external']}
          placeholder={translations['trains-excel-f-placeholder-external']}
          validations={{ required: true }}
        />

        <WafoFormInput
          type="text"
          name="bol_number"
          customClass="col-12 col-md-4"
          label={translations['trains-excel-f-label-bol']}
          placeholder={translations['trains-excel-f-placeholder-bol']}
          validations={{ required: true }}
        />

        <WafoFormAutocomplete
          name="material"
          customClass="col-12 col-md-4"
          label={translations['trains-excel-f-label-material']}
          placeholder={translations['trains-excel-f-placeholder-material']}
          items={materials}
          filterItems={(items, query) => items.filter(i => i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)}
          renderInput={item =>
            `[${item.unit_type} - ${translations['materials-f-types'][item.material_type - 1]}] ${item.name}`
          }
          renderItem={item => (
            <p style={{ margin: '0', padding: '0.5rem', lineHeight: 1, borderBottom: '1px solid #f5f5f5' }}>
              <span>{item.name}</span>
              <br />
              <span style={{ fontSize: '0.85em', color: '#777777' }}>
                {item.unit_type}
                {' - '}
                <MaterialType translations={translations} type={item.material_type} />
              </span>
            </p>
          )}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          handleChange
        />
      </WafoForm>

      <div className="row">
        <div className="col-12 text-center">
          <button type="submit" form="form-excel" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </div>
    </div>
  );
};

FormTrainExcel.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
};

export default withTranslation(FormTrainExcel, ['trains', 'materials']);
