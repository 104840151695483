import React from 'react';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import useTranslation from '#hooks/redux/useTranslation';
import { FormattedMessage } from 'react-intl';
import useLoading from '#context/hooks/useLoading';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';

const FormUpAuth = ({ onSubmitSuccess }) => {
  const { translations, locale } = useTranslation();
  const loading = useLoading();
  const api = useApi();
  const alerts = useAlerts();

  const handleSubmit = async (form, values) => {
    if (form.valid) {
      loading.set();
      try {
        await api.post('/upr/auth', {
          user: values.user,
          password: values.password,
        });

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        onSubmitSuccess();
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
  };

  return (
    <div>
      <p><FormattedMessage id="profile-f-up-desc" /></p>
      <WafoForm formId="form-up" locale={locale} onSubmit={handleSubmit} values={{}}>
        <WafoFormInput
          type="email"
          name="user"
          label={translations['profile-f-up-label-user']}
          placeholder={translations['profile-f-up-placeholder-user']}
          customClass="col-12 col-md-4"
          validations={{
            required: true,
            email: true,
          }}
        />

        <WafoFormInput
          type="password"
          name="password"
          label={translations['profile-f-up-label-pass']}
          placeholder={translations['profile-f-up-placeholder-pass']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <div className="col-12">
          <button type="submit" form="form-up" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </WafoForm>
    </div>
  );
};

export default FormUpAuth;
