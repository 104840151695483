import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withTranslation from '#components/HOCs/withTranslation';
import styles from './equipmentCustoms.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import InfoColumn from '#components/UI/general/infoColumn/infoColumn';
import { FormattedMessage } from 'react-intl';
import FormPedimento from '#components/forms/trains/formPedimento';
import FormCustoms from '#components/forms/trains/formCustoms';

const EquipmentCustoms = ({ translations, locations, equipment, updateEquipment }) => {
  const [editcustoms, setEditcustoms] = React.useState(false);
  const [editlocation, setEditlocation] = React.useState(false);

  const customLocations = React.useMemo(() => {
    return locations.filter(x => x.location.type === 2);
  }, [locations]);

  const customs = React.useMemo(() => {
    let customs = customLocations.find(x => x.id_el === editlocation);
    if (customs && customs.equipment_custom) {
      const equipment_custom = customs.equipment_custom;
      customs = {
        id_custom: equipment_custom.id_custom || '',
        placement_date: equipment_custom.placement_date ? equipment_custom.placement_date : '',
        placement_end_date: equipment_custom.placement_end_date ? equipment_custom.placement_end_date : '',
        modulation_start_date: equipment_custom.modulation_start_date ? equipment_custom.modulation_start_date : '',
        modulation_end_date: equipment_custom.modulation_end_date ? equipment_custom.modulation_end_date : '',
        liberation_date: equipment_custom.liberation_date ? equipment_custom.liberation_date : '',
        name: customs.location.name,
      };
    } else {
      customs = {
        ...(customs && { name: customs.location.name }),
      };
    }
    return customs;
  }, [customLocations, editlocation]);

  if (editcustoms) {
    return (
      <div>
        <button
          type="button"
          className={`btn btn-sm btn-link ${styles['btn-back']}`}
          onClick={() => setEditcustoms(false)}
        >
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '.25em' }} />
          {translations['equipments-m-details-f-b-cancel']}
        </button>
        <FormPedimento
          onSubmitSuccess={() => {
            setEditcustoms(false);
            updateEquipment();
          }}
          equipment={equipment}
        />
      </div>
    );
  }

  if (editlocation) {
    return (
      <div id="equipment-customs">
        <div className={styles['go-back']}>
          <button
            type="button"
            className={`btn btn-sm btn-link ${styles['btn-back']}`}
            onClick={() => setEditlocation(false)}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <h6>
            <strong>{translations['customs-f-c-b-edit']}:</strong> {customs.name}
          </h6>
        </div>
        <FormCustoms
          onSubmitSuccess={() => {
            setEditlocation(false);
            updateEquipment();
          }}
          locationId={editlocation}
          customs={customs}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <button
            type="button"
            className={`btn btn-sm btn-link ${styles['btn-edit']}`}
            onClick={() => setEditcustoms(true)}
          >
            <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.25rem' }} />
            <FormattedMessage id="equipments-m-details-f-b-edit" />
          </button>
        </div>
      </div>
      <div className="row">
        <InfoColumn
          title={translations['equipments-m-details-customs-invoice']}
          info={
            equipment.customs_invoice
              ? equipment.customs_invoice
              : translations['equipments-m-details-customs-!invoice']
          }
        />
        <InfoColumn
          title={translations['equipments-m-details-customs-date']}
          info={
            equipment.customs_date
              ? moment(equipment.customs_date).format('DD MMMM YYYY')
              : translations['equipments-m-details-customs-!date']
          }
        />
        <InfoColumn
          title={translations['equipments-m-details-customs-file']}
          info={equipment.customs_file ? equipment.customs_file : translations['equipments-m-details-customs-!file']}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <h6 style={{ marginBottom: '.5rem' }}>
            <strong>Aduanas</strong>
          </h6>
          <div className={styles['table-wrapper']}>
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  {translations['equipments-m-details-customs-columns'].map((column, index) => (
                    <th key={index}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {customLocations.map(l => {
                  const equipment_custom = l.equipment_custom || {};
                  const {
                    placement_date,
                    placement_end_date,
                    modulation_start_date,
                    modulation_end_date,
                    liberation_date,
                  } = equipment_custom;
                  // TODO: Falta cambiar placement_date por inicio y fin de placement
                  return (
                    <tr key={l.id_el}>
                      <td>{l.location.name}</td>
                      <td className={styles['td-date']}>
                        {equipment_custom ? (
                          <>
                            <span title="start">
                              {placement_date ? moment(placement_date).format('DD MMMM YYYY HH:mm') : ''}
                            </span>
                            <br />
                            <span title="finish">
                              {placement_end_date ? moment(placement_end_date).format('DD MMMM YYYY HH:mm') : ''}
                            </span>
                          </>
                        ) : (
                          ''
                        )}
                      </td>
                      <td className={styles['td-date']}>
                        {equipment_custom ? (
                          <React.Fragment>
                            <span title="start">
                              {modulation_start_date ? moment(modulation_start_date).format('DD MMMM YYYY HH:mm') : ''}
                            </span>
                            <br />
                            <span title="finish">
                              {modulation_end_date ? moment(modulation_end_date).format('DD MMMM YYYY HH:mm') : ''}
                            </span>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </td>
                      <td className={styles['td-date']}>
                        {equipment_custom && liberation_date
                          ? moment(liberation_date).format('DD MMMM YYYY HH:mm')
                          : ''}
                      </td>
                      <td className={styles['td-buttons']}>
                        <button type="button" className="btn btn-table btn-s" onClick={() => setEditlocation(l.id_el)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

EquipmentCustoms.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  updateData: PropTypes.func,
  locations: PropTypes.array,
  equipment: PropTypes.any,
  updateEquipment: PropTypes.func,
};

export default withTranslation(EquipmentCustoms, ['equipments', 'customs']);
