import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withTranslation from '#components/HOCs/withTranslation';
import StoreContainerH from '#components/UI/materialContainers/storeContainerH';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import styles from './storeDetails.module.css';

// Stores ya vienen los valores multiplicados por material.quantity
const TableStore = ({ translations, stores }) => {
  const [dropdowns, setDropdowns] = React.useState(() => {
    return stores.map(() => false);
  });

  const toggleDropdown = index => {
    setDropdowns(prev => {
      const dummy = [...prev];
      dummy[index] = !dummy[index];
      return dummy;
    });
  };

  return (
    <table className="table table-sm">
      <thead>
        <tr>
          {translations['warehouses-d-s-table-columns'].map((col, i) => (
            <th key={i}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {stores.map((sm, i) => (
          <React.Fragment key={i}>
            <tr className={styles['table-row']}>
              <td>#{sm.id_sm}</td>
              <td>
                <strong>{sm.material.name}</strong> |{' '}
                <span style={{ fontSize: '0.85em' }}>{`${translations['materials-f-types'][sm.material.material_type - 1]} [${
                  sm.material.unit_type
                }]`}</span>
              </td>
              <td>
                {sm.limit_quantity} {sm.material.unit_type}
              </td>
              <td>
                <StoreContainerH total={sm.limit_quantity} used={sm.total} unit={sm.material.unit_type} />
              </td>
              <td>
                <button disabled={sm.user_stores.length === 0} type="button" className="btn btn-link" onClick={() => toggleDropdown(i)}>
                  <FontAwesomeIcon icon={dropdowns[i] ? faCaretUp : faCaretDown} />
                </button>
              </td>
            </tr>
            {dropdowns[i] && (
              <tr>
                <td colSpan={5} className={styles['subtable']}>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        {translations['warehouses-d-s-subtable-columns'].map((col, i) => (
                          <th key={i}>{col}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {sm.user_stores.map(us => (
                        <tr key={us.id_user_store}>
                          <td>#{us.id_user_store}</td>
                          <td>
                            #{us.id_user} {us.user.name} {us.user.last_name}
                          </td>
                          <td>
                            {us.available_quantity} {us.material.unit_type}
                          </td>
                          <td>{((us.available_quantity * 100) / sm.total).toFixed(2)}%</td>
                          <td>{moment(us.updatedAt).format('DD MMMM YYYY HH:mm')}</td>
                          <td>TODO:</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

// TODO: Mostrar fecha de caducar de cada material.

TableStore.propTypes = {
  stores: PropTypes.array,
  translations: PropTypes.any,
};

export default withTranslation(TableStore, ['warehouses', 'materials']);
