import React from 'react';
import PanelContainer from '../panelContainer';
import { Switch, Redirect, useRouteMatch, Route } from 'react-router-dom';
import TableShips from './tableShips';
import NewShip from './newShip';
import ShipDetails from './details/shipDetails';

const Ships = () => {
  const match = useRouteMatch();

  return (
    <PanelContainer>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/list`} />
        <Route exact path={`${match.url}/details/:id`} component={ShipDetails} />
        <Route exact path={`${match.url}/new`} component={NewShip} />
        <Route exact path={`${match.url}/new/:id`} component={NewShip} />
        <Route exact path={`${match.url}/list`} component={TableShips} />
      </Switch>
    </PanelContainer>
  );
};

export default Ships;
