import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlaneArrival,
  faPlaneDeparture,
  faEdit,
  faFileExcel,
  faRedo,
  faFileUpload,
  faRoute,
  faListAlt,
  faExclamationTriangle,
  faTrash,
  faTruckLoading,
  faStamp,
} from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { FormattedMessage } from 'react-intl';
import FileSaver from 'file-saver';
import { LocalTable } from '@wafo/table';
import withTranslation from '#components/HOCs/withTranslation';
import ButtonDropdown from '#components/UI/general/buttonDropdown/buttonDropdown';
import { makeModal } from '#components/modals/makeModal';
import EditTrain from '#components/modals/trains/editTrain';
import useApi from '#hooks/api/useApi';
import LocationModal from '#components/modals/trains/locationModal/locationModal';
import EquipmentDetailsModal from '#components/modals/trains/equipmentDetailsModal/equipmentDetailsModal';
import IncidentModal from '#components/modals/trains/incidents/incidentModal';
import IncidentsModal from '#components/modals/trains/incidents/incidentsModal';
import ConfirmModal from '#components/modals/confirmModal/confirmModal';
import CustomsModal from '#components/modals/trains/customs/customsModal';
import CustomModal from '#components/modals/trains/customs/customModal';
import UnloadModal from '#components/modals/trains/unloadModal/unloadModal';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';
import moment from 'moment';
import styles from '../trains.module.css';

const TableEquipments = ({ locale, translations, updateData, train, equipments, onSelect, onSelectAll }) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  /** ******************************************* */
  /** Table */
  /** ******************************************* */

  const rows = React.useMemo(() => {
    return equipments.map(e => {
      let bgColor = '';
      if (e.equipment_location) {
        if (e.location.type === 2 && !e.equipment_location.equipment_custom) {
          bgColor = '#cce5ff';
        } else if (e.equipment_location.last_equipment_incident) {
          const incident = e.equipment_location.last_equipment_incident;
          if (!incident.date_released) {
            switch (incident.type) {
              case 5:
                bgColor = '#f8d7da';
                break;
              default:
                bgColor = '#fff3cd';
                break;
            }
          }
        }
      }

      return {
        rowData: {
          incident: e.total_incidents,
          select: { id: e.equipment_id, select: e.select },
          id: e.equipment_id,
          material: {
            quantity: e.quantity,
            id: e.material.id_material,
            name: e.material.name,
            unit: e.material.unit_type,
            type: e.material.material_type,
            mQuantity: e.material.quantity,
            unloaded: e.equipment_movement,
          },
          bl: e.bl_number,
          customs: {
            expires: e.customs_expires,
            date: e.customs_date,
            invoice: e.customs_invoice,
            // Used by the table to search in objects, must be a string
            wafoTableSearch: e.customs_invoice,
          },
          billing: e.bill_number, // Número de factura (Segrove secuencial).
          updated: e.updatedAt,
          current: { ...e.equipment_location, location: e.location },
        },
        rowStyle: {
          backgroundColor: bgColor,
        },
      };
    });
  }, [equipments]);

  const selectedEquipments = React.useMemo(() => {
    return equipments.filter(e => e.select);
  }, [equipments]);

  const columnsConfig = React.useMemo(
    () => ({
      incident: {
        render: function col(val) {
          if (val > 0) {
            return <div className={styles['incident-col']} />;
          }
          return null;
        },
        style: { position: 'relative', padding: 0 },
      },
      select: function col(val) {
        return (
          <button className="btn btn-table" onClick={() => onSelect(val.id, val.select)}>
            <FontAwesomeIcon icon={val.select ? faCheckSquare : faSquare} />
          </button>
        );
      },
      id: {
        style: { fontSize: '0.85em' },
      },
      material: {
        render: function col(val) {
          return (
            <div className={styles['material-td']}>
              {val.unloaded && <FontAwesomeIcon icon={faTruckLoading} />}
              <div>
                <span>
                  {(val.quantity * val.mQuantity).toFixed(3)} {val.unit}
                </span>
                <br />
                <span style={{ fontSize: '0.85em' }}>
                  #{val.id} {val.name} - {translations['materials-f-types'][val.type]}
                </span>
              </div>
            </div>
          );
        },
        style: { lineHeight: 1 },
      },
      bl: {
        style: { fontSize: '0.85em' },
      },
      customs: {
        render: function col(val) {
          // TODO: Revisar el tema de fecha de expiración de pedimentos.
          if (val.invoice) {
            return (
              <>
                <span>#{val.invoice}</span>
                {val.date && (
                  <React.Fragment>
                    <br />
                    <span style={{ fontSize: '0.8rem' }}>{moment(val.date).format('DD MMM YYYY')}</span>
                  </React.Fragment>
                )}
              </>
            );
          }
          return null;
        },
        style: { lineHeight: 1 },
      },
      billing: {
        render: function col(val) {
          if (!val) {
            return null;
          }
          return <span>{val}</span>;
        },
        style: { fontSize: '0.9rem' },
      },
      updated: {
        render: function col(val) {
          return (
            <>
              <span>{moment(val).format('HH:mm')}</span>
              <br />
              <span style={{ fontSize: '0.9em' }}>{moment(val).format('DD MMMM YYYY')}</span>
            </>
          );
        },
        style: {
          fontSize: '0.9rem',
          lineHeight: 1,
        },
      },
      current: {
        render: function col(val) {
          if (!val.location) {
            return <span>NA</span>;
          }
          return (
            <>
              <span>{val.location.name}</span>
              <br />
              <span style={{ fontSize: '.8rem' }} title="Arrival">
                <FontAwesomeIcon icon={faPlaneArrival} style={{ marginRight: '.25rem' }} />
                {moment(val.date_arrival).format('DD/MM/YYYY HH:mm')}
              </span>
              <br />
              {val.date_departed && (
                <span style={{ fontSize: '.8rem' }} title="Departed">
                  <FontAwesomeIcon icon={faPlaneDeparture} style={{ marginRight: '.25rem' }} />
                  {moment(val.date_departed).format('DD/MM/YYYY HH:mm')}
                </span>
              )}
            </>
          );
        },
        style: { fontSize: '0.9rem', lineHeight: 1 },
      },
    }),
    [onSelect, translations],
  );

  const columns = React.useMemo(() => {
    return translations['equipments-t-columns'].map((col, i) => {
      if (i === 1) {
        return (
          <button className="btn btn-table" onClick={onSelectAll}>
            <FontAwesomeIcon icon={selectedEquipments.length === equipments.length ? faCheckSquare : faSquare} />
          </button>
        );
      }
      return col;
    });
  }, [translations, selectedEquipments, equipments, onSelectAll]);

  /** ******************************************* */
  /** Buttons */
  /** ******************************************* */

  async function updateBL() {
    try {
      loading.set();
      await api.get(`/packages/${train.id_package}/billoflanding`);
      alerts.success({
        title: translations['alerts-success-title'],
        message: translations['equipments-a-updatebl-success'],
        timer: 3000,
      });
      updateData();
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  }

  function deleteEquipments() {
    async function callDelete() {
      try {
        loading.set();
        await Promise.all(selectedEquipments.map(e => api.delete(`/equipments/${e.id_equipment}`)));
        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        updateData();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    makeModal(ConfirmModal, {
      message: translations['equipments-t-confirm-m'],
      translations,
      onAccept: () => {
        callDelete();
      },
    });
  }

  async function getEquipmentReport() {
    loading.set();
    try {
      const filename = 'equipments_report';
      const resp = await api.get(`/equipments/waybill/${train.waybill}/excel`, {
        responseType: 'blob',
      });
      FileSaver.saveAs(resp.data, filename);
    } catch (error) {
      console.error(error);
    }
    loading.stop();
  }

  /** ******************************************* */
  /** Disableds */
  /** ******************************************* */

  const disableds = React.useMemo(() => {
    const sameLocation =
      selectedEquipments.filter(x => x.id_location === selectedEquipments[0].id_location).length !==
      selectedEquipments.length;
    const withLocation = selectedEquipments.filter(x => x.equipment_location);
    const isCustoms =
      selectedEquipments.length && selectedEquipments[0].location && selectedEquipments[0].location.type === 2;
    const isWarehouse =
      selectedEquipments.length && selectedEquipments[0].location && selectedEquipments[0].location.type === 4;
    const unloaded = selectedEquipments.filter(x => x.equipment_movement === null).length !== selectedEquipments.length;

    return {
      noEquipments: !selectedEquipments.length,
      sameLocation,
      noRoute: !train.route,
      onlyOne: selectedEquipments.length !== 1,
      withLocation: withLocation.length === selectedEquipments.length,
      isCustoms: !isCustoms,
      isWarehouse: !isWarehouse,
      unloaded,
    };
  }, [selectedEquipments, train]);

  /** ******************************************* */
  /** Modals */
  /** ******************************************* */

  const [modal, setModal] = React.useState(null);

  const closeModalAndUpdate = React.useCallback(() => {
    setModal(null);
    updateData();
  }, [updateData]);

  const renderModal = React.useMemo(() => {
    if (!modal) return null;
    switch (modal) {
      case 'customs':
        if (selectedEquipments.length > 1) {
          return <CustomsModal equipments={selectedEquipments} onClose={closeModalAndUpdate} />;
        } else if (selectedEquipments.length === 1) {
          return <CustomModal equipment={selectedEquipments[0]} onClose={closeModalAndUpdate} />;
        }
        break;
      case 'details':
        return <EquipmentDetailsModal equipments={selectedEquipments} onClose={closeModalAndUpdate} />;
      case 'edit':
        return <EditTrain train={train} onClose={closeModalAndUpdate} />;
      case 'incidents':
        if (selectedEquipments.length > 1) {
          return <IncidentsModal equipments={selectedEquipments} onClose={closeModalAndUpdate} />;
        } else if (selectedEquipments.length === 1) {
          return <IncidentModal equipment={selectedEquipments[0]} onClose={closeModalAndUpdate} />;
        }
        break;
      case 'locations':
        return <LocationModal equipments={selectedEquipments} route={train.route} onClose={closeModalAndUpdate} />;
      case 'unload':
        return (
          <UnloadModal equipments={selectedEquipments} idPackage={train.id_package} onClose={closeModalAndUpdate} />
        );
      default:
        return null;
    }
  }, [modal, train, selectedEquipments, closeModalAndUpdate]);

  /** ******************************************* */
  /** Render */
  /** ******************************************* */

  return (
    <div>
      <LocalTable
        locale={locale}
        columns={columns}
        rows={rows}
        tableWrapperClass="table-noreload"
        tableClass="table table-striped table-sm"
        columnsConfig={columnsConfig}
        keepPage
        noRowsMessage={translations['equipments-t-norows']}
        controls={
          <div className={styles['controls']}>
            <button
              type="button"
              className="btn btn-sm btn-s with-icon"
              title="Edit train"
              onClick={() => setModal('edit')}
            >
              <FontAwesomeIcon icon={faEdit} />
              <FormattedMessage id="equipments-b-edit" />
            </button>
            <button
              type="button"
              className="btn btn-sm btn-success with-icon"
              title="Generate report"
              onClick={() => getEquipmentReport()}
            >
              <FontAwesomeIcon icon={faFileExcel} />
              <FormattedMessage id="equipments-b-report" />
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary with-icon"
              title="Update BL numbers"
              onClick={updateBL}
            >
              <FontAwesomeIcon icon={faRedo} />
              BL
            </button>
            <button type="button" className="btn btn-sm btn-secondary with-icon" title="Update with file">
              <FontAwesomeIcon icon={faFileUpload} />
              <FormattedMessage id="equipments-b-update" />
            </button>
            <ButtonDropdown text={translations['equipments-b-equipment']}>
              <button
                type="button"
                className="btn btn-link with-icon"
                onClick={() => setModal('details')}
                disabled={disableds.onlyOne}
              >
                <FontAwesomeIcon icon={faListAlt} />
                <FormattedMessage id="equipments-b-details" />
              </button>
              <button
                type="button"
                className="btn btn-link with-icon"
                onClick={() => setModal('locations')}
                disabled={disableds.noEquipments || disableds.noRoute || disableds.sameLocation}
              >
                <FontAwesomeIcon icon={faRoute} />
                <FormattedMessage id="equipments-b-locations" />
              </button>
              <button
                type="button"
                className="btn btn-link with-icon"
                onClick={() => setModal('incidents')}
                disabled={
                  disableds.noRoute || disableds.noEquipments || disableds.sameLocation || !disableds.withLocation
                }
              >
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <FormattedMessage id="equipments-b-incidents" />
              </button>
              <button
                type="button"
                className="btn btn-link with-icon"
                onClick={() => setModal('customs')}
                disabled={disableds.noRoute || disableds.noEquipments || disableds.sameLocation || disableds.isCustoms}
              >
                <FontAwesomeIcon icon={faStamp} />
                <FormattedMessage id="equipments-b-customs" />
              </button>
              <button
                type="button"
                className="btn btn-link with-icon"
                onClick={() => setModal('unload')}
                disabled={
                  disableds.noRoute ||
                  disableds.noEquipments ||
                  disableds.sameLocation ||
                  disableds.isWarehouse ||
                  disableds.unloaded
                }
              >
                <FontAwesomeIcon icon={faTruckLoading} />
                <FormattedMessage id="equipments-b-warehouse" />
              </button>
              <button
                type="button"
                title="Delete equipments"
                disabled={disableds.noEquipments || disableds.withLocation}
                className="btn btn-link"
                onClick={deleteEquipments}
              >
                <FontAwesomeIcon icon={faTrash} style={{ marginRight: '0.5rem' }} />
                <FormattedMessage id="equipments-b-delete" />
              </button>
            </ButtonDropdown>
          </div>
        }
      />
      <div className="row">
        <div className={`col-12 ${styles['table-legends']}`}>
          <div className={styles['legend']}>
            <div className={styles['legend-color']} style={{ backgroundColor: '#ed969e' }} />
            <span className={styles['legend-text']}>
              <FormattedMessage id="equipments-t-legends-red" />
            </span>
          </div>
          <div className={styles['legend']}>
            <div className={styles['legend-color']} style={{ backgroundColor: '#ffe181' }} />
            <span className={styles['legend-text']}>
              <FormattedMessage id="equipments-t-legends-incident" />
            </span>
          </div>
          <div className={styles['legend']}>
            <div className={styles['legend-color']} style={{ backgroundColor: '#cce5ff' }} />
            <span className={styles['legend-text']}>
              <FormattedMessage id="equipments-t-legends-customs" />
            </span>
          </div>
        </div>
      </div>
      {renderModal}
    </div>
  );
};

TableEquipments.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  train: PropTypes.any,
  equipments: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  updateData: PropTypes.func,
};

TableEquipments.defaultProps = {
  train: {},
  equipments: [],
  onSelect: f => f,
  onSelectAll: f => f,
};

export default withTranslation(TableEquipments, ['equipments', 'materials']);
