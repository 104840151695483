import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrain,
  faShip,
  faUsers,
  faBell,
  faFile,
  faRoute,
  faWarehouse,
  faShapes,
} from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import withTranslation from '#components/HOCs/withTranslation';
import styles from './sidebar.module.css';

const logo = require('#assets/images/logo_notext.png');

const Sidebar = ({ toggled }) => (
  <nav data-testid="component-panel-sidebar" className={`${styles['sidebar']} ${toggled ? styles['collapsed'] : ''}`}>
    <div className="header">
      <img src={logo} alt="Logo" className="logo" />
      <h1 className="font-bold font-segrove text-center">SEGROVE</h1>
    </div>
    <ul className="links">
      <li>
        <NavLink to="/panel/trains" activeClassName="active">
          <FontAwesomeIcon icon={faTrain} />
          <span>
            <FormattedMessage id="menus-sidebar-trains" />
          </span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/panel/ships" activeClassName="active">
          <FontAwesomeIcon icon={faShip} />
          <span>
            <FormattedMessage id="menus-sidebar-ships" />
          </span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/panel/materials" activeClassName="active">
          <FontAwesomeIcon icon={faShapes} />
          <span>
            <FormattedMessage id="menus-sidebar-materials" />
          </span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/panel/warehouses"
          activeClassName="active"
          isActive={(match, location) =>
            !!(
              location.pathname.includes('/panel/warehouses') ||
              location.pathname.includes('/panel/vehicles') ||
              location.pathname.includes('/panel/drivers')
            )
          }
        >
          <FontAwesomeIcon icon={faWarehouse} />
          <span>
            <FormattedMessage id="menus-sidebar-storage" />
          </span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/panel/users" activeClassName="active">
          <FontAwesomeIcon icon={faUsers} />
          <span>
            <FormattedMessage id="menus-sidebar-users" />
          </span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/panel/routes" activeClassName="active">
          <FontAwesomeIcon icon={faRoute} />
          <span>
            <FormattedMessage id="menus-sidebar-locations" />
          </span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/panel/notifications" activeClassName="active">
          <FontAwesomeIcon icon={faBell} />
          <span>
            <FormattedMessage id="menus-sidebar-notifications" />
          </span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/panel/reports" activeClassName="active">
          <FontAwesomeIcon icon={faFile} />
          <span>
            <FormattedMessage id="menus-sidebar-reports" />
          </span>
        </NavLink>
      </li>
    </ul>
  </nav>
);

Sidebar.propTypes = {
  toggled: PropTypes.bool,
};

Sidebar.defaultProps = {
  toggled: false,
};

export default withTranslation(Sidebar, 'menus');
