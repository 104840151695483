import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from '#components/HOCs/withTranslation';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { faExchangeAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import useApi from '#hooks/api/useApi';
import InfoColumn from '#components/UI/general/infoColumn/infoColumn';
import NewstoreModal from '#components/modals/warehouses/newstoreModal';
import StoreContainer from '#components/UI/materialContainers/storeContainer';
import StoreDetails from '../storeDetails/storeDetails';
import WarehouseTransferModal from '#components/modals/warehouses/warehouseTransfer';
import styles from './warehousesDetails.module.css';
import useLoading from '#context/hooks/useLoading';

const WarehouseDetails = ({ translations }) => {
  const { id } = useParams();
  const api = useApi();
  const loading = useLoading();

  /** ******************************************* */
  /** Warehouse */
  /** ******************************************* */

  const [warehouse, setWarehouse] = React.useState({
    location_stores: [],
  });

  const getWarehouse = React.useCallback(async () => {
    if (id) {
      try {
        loading.set();
        const resp = await api.get(`/locations/${id}`);
        setWarehouse(resp);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }, [api, id, loading]);

  React.useEffect(() => {
    getWarehouse();
  }, [getWarehouse]);

  const location_stores = React.useMemo(() => {
    return warehouse.location_stores.map(ls => {
      let storage = 0;
      let used = 0;
      ls.store_materials.forEach(sm => {
        storage += sm.limit_quantity * sm.material.quantity;
      });
      ls.user_stores.forEach(us => {
        used += us.available_quantity * us.material.quantity;
      });
      return {
        id: ls.id_store,
        name: ls.name,
        storage,
        used,
        stores: ls.store_materials.length,
      };
    });
  }, [warehouse.location_stores]);

  const info = React.useMemo(() => {
    return warehouse.location_stores.reduce(
      (accumulator, ls) => {
        ls.store_materials.forEach(sm => {
          const unit = `${translations['materials-f-types'][sm.material.material_type - 1]} [${sm.material.unit_type}]`;
          if (!accumulator.units.includes(unit)) {
            accumulator.units.push(unit);
          }
          if (!accumulator.materials.includes(sm.material.id_material)) {
            accumulator.materials.push(sm.material.id_material);
          }
        });
        ls.user_stores.forEach(us => {
          if (!accumulator.clients.includes(us.id_user)) {
            accumulator.clients.push(us.id_user);
          }
        });
        return accumulator;
      },
      { materials: [], units: [], clients: [] },
    );
  }, [warehouse, translations]);

  /** ******************************************* */
  /** Modals */
  /** ******************************************* */

  const [modal, setModal] = React.useState({
    type: null,
    payload: null,
  });
  const closeModal = () => setModal({ type: null, payload: null });

  const renderModal = React.useMemo(() => {
    if (!modal.type) return null;
    switch (modal.type) {
      case 'edit':
        return (
          <NewstoreModal location={warehouse} updateData={getWarehouse} store={modal.payload} onClose={closeModal} />
        );
      case 'new':
        return <NewstoreModal location={warehouse} updateData={getWarehouse} onClose={closeModal} />;
      case 'transfer':
        return <WarehouseTransferModal updateData={getWarehouse} warehouse={warehouse} onClose={closeModal} />;
      default:
        return null;
    }
  }, [modal, warehouse, getWarehouse]);

  /** ******************************************* */
  /** Render */
  /** ******************************************* */

  return (
    <React.Fragment>
      <div>
        <CardHeader title={translations['warehouses-d-title']}>
          <button
            type="button"
            className="btn btn-primary with-icon"
            onClick={() => setModal(prev => ({ ...prev, type: 'transfer' }))}
          >
            <FontAwesomeIcon icon={faExchangeAlt} />
            <FormattedMessage id="warehouses-d-b-transfer" />
          </button>
          <button
            type="button"
            className="btn btn-primary with-icon"
            onClick={() => setModal(prev => ({ ...prev, type: 'new' }))}
          >
            <FontAwesomeIcon icon={faPlus} />
            <FormattedMessage id="warehouses-d-b-new" />
          </button>
        </CardHeader>
        <div className="card card-panel">
          <div className="card-body">
            <h5 className="card-subtitle">
              <FormattedMessage id="warehouses-d-subt-1" />
            </h5>

            <div className={styles['information']}>
              <div className="row">
                <InfoColumn
                  colSize="col-12 col-md-3"
                  title={translations['warehouses-d-info-name']}
                  info={warehouse.name}
                />
                <InfoColumn
                  colSize="col-12 col-md-3"
                  title={translations['warehouses-d-info-materials']}
                  info={`${info.materials.length} ${translations['warehouses-d-s-info-materials-desc']}`}
                />
                <InfoColumn
                  colSize="col-12 col-md-3"
                  title={translations['warehouses-d-info-units']}
                  info={info.units.join(', ')}
                />
                <InfoColumn
                  colSize="col-12 col-md-3"
                  title={translations['warehouses-d-info-clients']}
                  info={`${info.clients.length} ${translations['warehouses-d-s-info-clients-desc']}`}
                />
              </div>
            </div>

            <h5 className="card-subtitle">
              <FormattedMessage id="warehouses-d-subt-2" />
            </h5>

            {warehouse.location_stores.length < 1 && (
              <div className="row">
                <div className="col">
                  <div className={styles['no-stores']}>
                    <span>{translations['warehouses-d-stores-nostores']}</span>
                  </div>
                </div>
              </div>
            )}

            {warehouse.location_stores.length > 0 && (
              <div className={`row ${styles['custom-row']}`}>
                {location_stores.map(store => (
                  <div key={store.id_store} className="col-md-2">
                    <StoreContainer
                      total={store.storage}
                      used={store.used}
                      desc={`${translations['warehouses-d-s-stores']}: ${store.stores}`}
                    />
                    <span className={styles['store-name']}>{store.name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="divider" />

      {warehouse.location_stores.map(store => (
        <React.Fragment key={store.id_store}>
          <StoreDetails
            store={store}
            updateData={getWarehouse}
            openEdit={() => setModal(prev => ({ ...prev, type: 'edit', payload: store }))}
          />
          <div className="divider" />
        </React.Fragment>
      ))}

      {renderModal}
    </React.Fragment>
  );
};

WarehouseDetails.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  alerts: PropTypes.shape({
    success: PropTypes.func,
  }),
};

export default withTranslation(WarehouseDetails, ['warehouses', 'materials']);
