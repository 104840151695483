import React from 'react';
import PropTypes from 'prop-types';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import withTranslation from '#components/HOCs/withTranslation';
import FormDriver from '#components/forms/warehouses/formDriver';

const NewDriver = () => {
  const history = useHistory();
  const { id } = useParams();

  return (
    <div>
      <CardHeader
        title={<FormattedMessage id={id ? 'drivers-f-e-title' : 'drivers-f-title'} values={{ id }} />}
        showBack
        onBack={() => history.goBack()}
      />
      <div className="card card-panel">
        <div className="card-body">
          <h5 className="card-subtitle">
            <FormattedMessage id={id ? 'drivers-f-e-subtitle' : 'drivers-f-subtitle'} />
          </h5>
          <p>
            <FormattedMessage id="drivers-f-description" />
          </p>
          <FormDriver onSubmitSuccess={() => history.goBack()} />
        </div>
      </div>
    </div>
  );
};

NewDriver.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
};

export default withTranslation(NewDriver, 'drivers');
