import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import { FormattedMessage } from 'react-intl';
import FormShip from '#components/forms/ships/formShip';

const NewShip = () => {
  const history = useHistory();
  const { id } = useParams();

  return (
    <div>
      <CardHeader
        title={<FormattedMessage id={id ? 'ships-f-e-title' : 'ships-f-title'} values={{ id }} />}
        showBack
        onBack={() => history.goBack()}
      />
      <div className="card card-panel">
        <div className="card-body">
          <h5 className="card-subtitle">
            <FormattedMessage id={id ? 'ships-f-e-subtitle' : 'ships-f-subtitle'} />
          </h5>
          <p>
            <FormattedMessage id="ships-f-description" />
          </p>
          <FormShip onSubmitSuccess={() => history.goBack()} />
        </div>
      </div>
    </div>
  );
};

export default NewShip;
