import React from 'react';
import PanelContainer from '../panelContainer';
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import NewMaterial from './newMaterial';
import TableMaterials from './tableMaterials';

const Materials = () => {
  const match = useRouteMatch();

  return (
    <PanelContainer>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/list`} />
        <Route exact path={`${match.url}/new`} component={NewMaterial} />
        <Route exact path={`${match.url}/new/:id`} component={NewMaterial} />
        <Route exact path={`${match.url}/list`} component={TableMaterials} />
      </Switch>
    </PanelContainer>
  );
};

export default Materials;
