import React from 'react';
import PropTypes from 'prop-types';
import withTranslation from '#components/HOCs/withTranslation';
import BaseModal from '#components/modals/baseModal';
import { FormattedMessage } from 'react-intl';
import InfoColumn from '#components/UI/general/infoColumn/infoColumn';
import FormCustoms from '#components/forms/trains/formCustoms';
import moment from 'moment';

const CustomModal = ({ translations, equipment, onClose }) => {
  const customs = React.useMemo(() => {
    if (equipment.equipment_location.equipment_custom) {
      const {
        equipment_location: { equipment_custom },
      } = equipment;
      return {
        id_custom: equipment_custom.id_custom || '',
        placement_date: equipment_custom.placement_date || '',
        placement_end_date: equipment_custom.placement_end_date || '',
        modulation_start_date: equipment_custom.modulation_start_date || '',
        modulation_end_date: equipment_custom.modulation_end_date || '',
        liberation_date: equipment_custom.liberation_date || '',
      };
    }
    return {};
  }, [equipment]);

  return (
    <BaseModal
      title={translations['equipments-m-customs-i-title']}
      modalStyle={{ width: '48em', maxHeight: '80vh', minHeight: '40vh' }}
      closeModal={onClose}
    >
      <div className="row">
        <div className="col-12">
          <p>
            <FormattedMessage id="equipments-m-customs-i-desc" />
          </p>
        </div>
      </div>
      <div className="row">
        <InfoColumn title={translations['equipments-m-customs-i-location']} info={equipment.location.name} />
        <InfoColumn title={translations['equipments-m-customs-i-invoice']} info={equipment.customs_invoice} />
        <InfoColumn
          title={translations['equipments-m-customs-i-date']}
          info={equipment.customs_date ? moment(equipment.customs_date).format('DD MMMM YYYY') : 'NA'}
        />
      </div>
      <div className="row">
        <div className="col-12">
          <FormCustoms onSubmitSuccess={onClose} customs={customs} locationId={equipment.location.id_location} />
        </div>
      </div>
    </BaseModal>
  );
};

CustomModal.propTypes = {
  translations: PropTypes.any,
  equipment: PropTypes.any,
};

CustomModal.defaultProps = {
  equipmnet: {},
};

export default withTranslation(CustomModal, ['equipments']);
