import React from 'react';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import { useHistory } from 'react-router-dom';
import FormTrainExcel from '#components/forms/trains/formTrainExcel';
import useTranslation from '#hooks/redux/useTranslation';
import backupTemplate from '#assets/files/backup_template.xlsx';

const LoadExcel = () => {
  const history = useHistory();
  const { translations } = useTranslation();

  return (
    <div>
      <CardHeader
        title={translations['trains-excel-title']}
        showBack
        onBack={() => {
          history.goBack();
        }}
      />
      <div className="card card-panel">
        <div className="card-body">
          <p>{translations['trains-excel-desc']}</p>

          <p>Download the template: <a href={backupTemplate}>backup_template.xlsx</a></p>

          <FormTrainExcel onSubmitSuccess={() => history.goBack()} />
        </div>
      </div>
    </div>
  );
};

export default LoadExcel;
