import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTrash, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import styles from './filePicker.module.css';

const FilePicker = ({
  name,
  label,
  placeholder,
  customClass,
  fileTypes,
  handleInputChange,
  path,
  currentSavedFile,
  valid,
  touched,
  errors,
}) => {
  const [file, setFile] = React.useState('');
  const refInput = React.useRef(null);

  React.useEffect(() => {
    handleInputChange({
      target: {
        name,
        value: file,
      },
    });
  }, [name, file]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnChange = event => {
    const {
      target: { files },
    } = event;
    if (!files.length) {
      return;
    }
    setFile(files[0]);
  };

  const removeFile = () => {
    setFile('');
    refInput.current.value = '';
  };

  return (
    <div id="filepicker" className={`form-group wafo-input ${customClass}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={styles['file-selected']}>
        {currentSavedFile && !file ? (
          <a className={styles['file-preview-link']} href={path} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFileDownload} style={{ marginRight: '.5rem' }} />
            {currentSavedFile}
          </a>
        ) : (
          <span className={styles['file-preview']}>{file ? file.name : placeholder}</span>
        )}
        {file && (
          <button type="button" className={`btn btn-icon btn-danger ${styles['file-btn-remove']}`} onClick={removeFile}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        )}
        <label htmlFor={name} className={`btn btn-icon btn-s ${styles['file-btn']}`}>
          <FontAwesomeIcon icon={faFile} />
        </label>
      </div>
      <input
        style={{ display: 'none' }}
        type="file"
        id={name}
        name={name}
        accept={fileTypes}
        onChange={handleOnChange}
        ref={refInput}
      />
      {!valid && touched && (
        <ul className="errors">
          {errors.map(error => (
            <li key={error.error}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

FilePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  customClass: PropTypes.string,
  fileTypes: PropTypes.string,
  handleInputChange: PropTypes.func,
  path: PropTypes.string,
  currentSavedFile: PropTypes.string,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.any),
};

FilePicker.defaultProps = {
  label: '',
  placeholder: '',
  customClass: '',
  fileTypes: '.xml, .pdf, .docx, pptx, .xlsx, .png, .jpg, .jpeg',
  handleInputChange: f => f,
  path: '',
  currentSavedFile: '',
  valid: false,
  touched: false,
  errors: [],
};

export default FilePicker;
