import React from 'react';
import PanelContainer from '#scenes/panel/panelContainer';
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import TablesSegroveRoutes from '../tablesSegroveRoutes';
import NewLocation from './newLocation';

const Locations = () => {
  const match = useRouteMatch();

  return (
    <PanelContainer>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/list`} />
        <Route exact path={`${match.url}/new`} component={NewLocation} />
        <Route exact path={`${match.url}/new/:id`} component={NewLocation} />
        <Route exact path={`${match.url}/list`} component={TablesSegroveRoutes} />
      </Switch>
    </PanelContainer>
  );
};

export default Locations;
