import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput, WafoFormSelect, WafoFormTextArea } from '@wafo/forms';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import useApi from '#hooks/api/useApi';
import styles from './warehouses.module.css';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormWarehouseTransfer = ({ locale, translations, onSubmitSuccess, warehouse }) => {
  const [originStore, setOriginStore] = React.useState(null);
  const [originUser, setOriginUser] = React.useState(null);
  const [maxAmount, setMaxAmount] = React.useState(0);

  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  async function handleSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();

        const material = user_stores.find(x => x.id_user_store === values.id_user_store).material;
        await api.post('stores/transfers/store', {
          id_user_store: values.id_user_store,
          id_store: values.to_store,
          tranfer_quantity: values.transfer_weight / material.quantity,
          ...(values.reason && { reason: values.reason }),
        });

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });

        onSubmitSuccess();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }

  const handleSetMaxAmount = id => {
    const index = user_stores.findIndex(us => us.id_user_store === id);
    if (index !== -1) {
      setMaxAmount(user_stores[index].available_quantity * user_stores[index].material.quantity);
    }
  };

  const usersInStore = React.useMemo(() => {
    if (originStore) {
      const storeIndex = warehouse.location_stores.findIndex(ls => ls.id_store === originStore);
      if (storeIndex !== -1) {
        return warehouse.location_stores[storeIndex].user_stores.reduce((accumulator, us) => {
          const index = accumulator.findIndex(user => us.id_user === user.id_user);
          if (index === -1) {
            accumulator.push({
              ...us.user,
              id_user: us.id_user,
            });
          }
          return accumulator;
        }, []);
      }
    }
    return [];
  }, [originStore, warehouse]);

  const user_stores = React.useMemo(() => {
    if (originStore && originUser) {
      const storeIndex = warehouse.location_stores.findIndex(ls => ls.id_store === originStore);
      if (storeIndex !== -1) {
        return warehouse.location_stores[storeIndex].user_stores.filter(us => us.id_user === originUser);
      }
    }
    return [];
  }, [warehouse, originStore, originUser]);

  // TODO: Bug de usabilidad.
  // Si seleccionas una bodega origen, se filtra de las destino.
  // Pero si ya seleccionaste una bodega destino y cambias el origen, se puede transferir a ella misma.

  return (
    <div>
      <WafoForm formId="form-transfer" locale={locale} onSubmit={handleSubmit} values={{}}>
        <div className="col-12">
          <p className={styles['p-divider']}>
            <FormattedMessage id="warehouses-transfer-f-divider-1" />
          </p>
        </div>

        <WafoFormSelect
          name="from_store"
          label={translations['warehouses-transfer-f-label-fromstore']}
          defaultValue={translations['warehouses-transfer-f-placeholder-fromstore']}
          customClass="col-12 col-md-6"
          options={warehouse.location_stores.map(ls => ({
            value: ls.id_store,
            display: ls.name,
          }))}
          validations={{ required: true }}
          onChangeCallback={({ target: { value } }) => setOriginStore(value)}
        />

        <WafoFormSelect
          name="from_user"
          label={translations['warehouses-transfer-f-label-fromuser']}
          defaultValue={translations['warehouses-transfer-f-placeholder-fromuser']}
          customClass="col-12 col-md-6"
          options={usersInStore.map(user => ({
            value: user.id_user,
            display: `#${user.id_user} - ${user.name} ${user.last_name}`,
          }))}
          validations={{ required: true }}
          onChangeCallback={({ target: { value } }) => setOriginUser(value)}
          extraProps={{
            disabled: !originStore,
          }}
        />

        <WafoFormSelect
          name="id_user_store"
          label={translations['warehouses-transfer-f-label-fromuserstore']}
          defaultValue={translations['warehouses-transfer-f-placeholder-fromuserstore']}
          customClass="col-12 col-md-6"
          options={user_stores.map(us => ({
            value: us.id_user_store,
            display: `#${us.id_user_store} - ${us.material.name} | ${(us.available_quantity * us.material.quantity).toFixed(3)} ${
              us.material.unit_type
            }`,
          }))}
          validations={{ required: true }}
          onChangeCallback={({ target: { value } }) => handleSetMaxAmount(value)}
          extraProps={{
            disabled: !originUser,
          }}
        />

        <div className="col-12">
          <p className={styles['p-divider']}>
            <FormattedMessage id="warehouses-transfer-f-divider-2" />
          </p>
        </div>

        <WafoFormSelect
          name="to_store"
          label={translations['warehouses-transfer-f-label-tostore']}
          defaultValue={translations['warehouses-transfer-f-placeholder-tostore']}
          customClass="col-12 col-md-6"
          options={warehouse.location_stores
            .filter(x => originStore !== x.id_store)
            .map(ls => ({
              value: ls.id_store,
              display: ls.name,
            }))}
          validations={{ required: true }}
          extraProps={{
            disabled: !originStore,
          }}
        />

        <WafoFormInput
          type="number"
          name="transfer_weight"
          label={translations['warehouses-transfer-f-label-transfer']}
          placeholder={translations['warehouses-transfer-f-placeholder-transfer']}
          customClass="col-12 col-md-6"
          validations={{
            required: true,
            validationFunction: {
              value: value => value <= maxAmount,
              message: translations['warehouses-transfer-f-overlimit'],
            },
          }}
          extraProps={{
            min: 0,
            max: maxAmount,
          }}
        />

        <WafoFormTextArea
          name="reason"
          label={translations['warehouses-transfer-f-label-reason']}
          placeholder={translations['warehouses-transfer-f-placeholder-reason']}
          customClass="col-12"
          validations={{
            minLength: 5,
            maxLength: 255,
          }}
        />
      </WafoForm>
      <div className="col-12 text-center">
        <button type="submit" form="form-transfer" className="btn btn-s btn-submit">
          <FormattedMessage id="form-submit" />
        </button>
      </div>
    </div>
  );
};

FormWarehouseTransfer.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
  warehouse: PropTypes.any,
};

export default withTranslation(FormWarehouseTransfer, 'warehouses');
