import React from 'react';
import PropTypes from 'prop-types';
import styles from './storeContainer.module.css';

const StoreContainer = ({ total, used, backgroundColor, barColor, unit, desc }) => {
  const percent = total === 0 ? 100 : (used * 100) / total;

  return (
    <div className={styles['container-wrapper']} style={{ backgroundColor }}>
      <div className={styles['container-bar']} style={{ height: `${percent}%`, backgroundColor: barColor }} />
      <div className={styles['container-text']}>
        <span>{percent.toFixed(2)}%</span>
        {!desc && (
          <span>
            {used.toFixed(3)} {unit ? `${unit}.` : ''}
          </span>
        )}
        <span>{desc}</span>
      </div>
    </div>
  );
};

StoreContainer.propTypes = {
  total: PropTypes.number,
  used: PropTypes.number,
  backgroundColor: PropTypes.string,
  barColor: PropTypes.string,
  unit: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

StoreContainer.defaultProps = {
  total: 100,
  used: 35,
  backgroundColor: '#f5f5f5',
  barColor: '#80E296',
  unit: '',
  desc: '',
};

export default StoreContainer;
