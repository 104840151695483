import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput, WafoFormSelect } from '@wafo/forms';
import { useParams } from 'react-router-dom';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormLocation = ({ locale, translations, onSubmitSuccess }) => {
  const [location, setLocation] = React.useState({});

  const { id } = useParams();
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  React.useEffect(() => {
    async function getLocation() {
      try {
        loading.set();
        const resp = await api.get(`/locations/${id}`);
        setLocation(resp);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    if (id) {
      getLocation();
    }
  }, [api, id, loading]);

  async function handleSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();
        const body = {
          name: values.name,
          type: values.type,
          ...(values.location_external && { location_external: values.location_external }),
        };
        if (location.id_location) {
          await api.put(`/locations/${location.id_location}`, body);
        } else {
          await api.post('/locations', body);
        }
        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        onSubmitSuccess();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }

  return (
    <div>
      <WafoForm formId="form-location" locale={locale} onSubmit={handleSubmit} values={location}>
        <WafoFormInput
          type="text"
          name="name"
          label={translations['locations-f-label-name']}
          placeholder={translations['locations-f-placeholder-name']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormSelect
          name="type"
          label={translations['locations-f-label-type']}
          defaultValue={translations['locations-f-placeholder-type']}
          customClass="col-12 col-md-4"
          options={[
            { value: '1', display: 'Train Station' },
            { value: '2', display: 'Customs (MX)' },
            { value: '3', display: 'Harbor (MX)' },
            { value: '4', display: 'Harbor & Warehouse' },
            { value: '5', display: 'Warehouse' },
          ]}
          validations={{ required: true }}
          extraProps={{
            disabled: location.type === 4,
          }}
        />

        <WafoFormInput
          type="text"
          name="location_external"
          label={translations['locations-f-label-external']}
          placeholder={translations['locations-f-placeholder-external']}
          customClass="col-12 col-md-4"
        />
      </WafoForm>
      <div className="row">
        <div className="col-12 text-center">
          <button type="submit" form="form-location" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </div>
    </div>
  );
};

FormLocation.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
};

export default withTranslation(FormLocation, 'locations');
