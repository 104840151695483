import React from 'react';
import PanelContainer from '../panelContainer';
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import WarehouseDetails from './details/warehouseDetails';
import NewLocation from '#scenes/panel/segroveRoutes/locations/newLocation';
import TablesWarehouse from './tablesWarehouse';
import NewVehicle from './vehicles/newVehicle';
import NewDriver from './drivers/newDriver';
import NewBelt from './belts/newBelt';

const Warehouses = () => {
  const match = useRouteMatch();

  return (
    <PanelContainer>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/list`} />
        <Route exact path={`${match.url}/details/:id`} component={WarehouseDetails} />
        <Route exact path={`${match.url}/new`} component={NewLocation} />
        <Route exact path={`${match.url}/new/:id`} component={NewLocation} />
        <Route exact path={`${match.url}/vehicles/new/:id`} component={NewVehicle} />
        <Route exact path={`${match.url}/vehicles/new`} component={NewVehicle} />
        <Route exact path={`${match.url}/drivers/new/:id`} component={NewDriver} />
        <Route exact path={`${match.url}/drivers/new`} component={NewDriver} />
        <Route exact path={`${match.url}/belts/new/:id`} component={NewBelt} />
        <Route exact path={`${match.url}/belts/new`} component={NewBelt} />
        <Route path={`${match.url}`} component={TablesWarehouse} />
      </Switch>
    </PanelContainer>
  );
};

export default Warehouses;
