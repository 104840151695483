import React from 'react';
// import * as Sentry from '@sentry/browser';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { SegroveProvider } from '#context/segrove-context';
import TranslationProvider from '#components/HOCs/translationProvider';
import Auth from '#scenes/auth/auth';
import AdminPanel from '#scenes/panel/panel';
import PrivateRoute from '#components/router/privateRoute';

// Sentry.init({ dsn: 'https://7869486fc87c4927b0d7cccdfaa597e6@sentry.io/4498717' });

// Checking if sessionStorage is available
try {
  sessionStorage.getItem('token');
} catch (error) {
  if (error.code === DOMException.SECURITY_ERR) {
    alert('AVISO: Para el funcionamiento correcto de la página se requiere acceso a Cookies y datos de sitio.');
  }
}

function App() {
  return (
    <div className="App">
      <SegroveProvider>
        <TranslationProvider>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/auth" />} />
              <Route path="/auth" component={Auth} />
              <PrivateRoute path="/panel" component={AdminPanel} allowed={[1]} />
            </Switch>
          </BrowserRouter>
        </TranslationProvider>
      </SegroveProvider>
    </div>
  );
}

export default App;
