import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import useSidebar from '#context/hooks/useSidebar';
import Navbar from '#components/UI/menus/navbar/navbar';
import Sidebar from '#components/UI/menus/sidebar/sidebar';
import AlertList from '#components/UI/alerts/alertList';
import WSNotifications from '#utils/wsNotifications';
import useScrollTop from '#hooks/useScrollTop';
import LoadingBar from '#components/UI/general/loadingBar/loadingBar';
import Trains from './trains/trains';
import Ships from './ships/ships';
import Materials from './materials/materials';
import Warehouses from './warehouses/warehouses';
import SegroveRoutes from './segroveRoutes/segroveRoutes';
import Locations from './segroveRoutes/locations/locations';
import Users from './users/users';
import Notifications from './notifications/notifications';
import Reports from './reports/reports';
import LoadingBlock from '#components/UI/general/loadingBar/loadingBlock';
import Profile from './profile/profile';
import styles from './panel.module.css';

const AdminPanel = () => {
  const location = useLocation();
  useScrollTop(location.pathname, 'panel-right');

  const sidebar = useSidebar();

  return (
    <div className={styles['panel']}>
      <div className={styles['header']}>
        <Navbar onToggleClick={() => sidebar.toggle()} />
      </div>
      <div className={styles['body']}>
        <LoadingBar show color="#2f80ed " />
        <div className={styles['left']}>
          <Sidebar toggled={sidebar.state} />
        </div>
        <div id="panel-right" className={styles['right']}>
          <LoadingBlock />
          <AlertList />
          <Switch>
            <Route exact path="/panel" render={() => <Redirect to="/panel/trains" />} />
            <Route path="/panel/trains" component={Trains} />
            <Route path="/panel/ships" component={Ships} />
            <Route path="/panel/materials" component={Materials} />
            <Route path="/panel/warehouses" component={Warehouses} />
            <Route path="/panel/routes" component={SegroveRoutes} />
            <Route path="/panel/locations" component={Locations} />
            <Route path="/panel/users" component={Users} />
            <Route path="/panel/notifications" component={Notifications} />
            <Route path="/panel/reports" component={Reports} />
            <Route path="/panel/profile" component={Profile} />
          </Switch>
        </div>
      </div>
      <WSNotifications />
    </div>
  );
};

export default AdminPanel;
