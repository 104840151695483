import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link, NavLink, useRouteMatch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarehouse, faUser, faTruck, faTrailer } from '@fortawesome/free-solid-svg-icons';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import TableWarehouses from './tableWarehouses';
import TableDrivers from './drivers/tableDrivers';
import TableVehicles from './vehicles/tableVehicles';
import TableBelts from './belts/tableBelts';

const TablesWarehouse = ({ translations }) => {
  const handleActive = (match, location, basePath) => {
    if (match) {
      return true;
    }
    const path = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
    return path === basePath;
  };

  const match = useRouteMatch();

  return (
    <div>
      <CardHeader title={translations['warehouses-t-title']}>
        <Link to={`/panel/warehouses/new`} className="btn btn-primary with-icon">
          <FontAwesomeIcon icon={faWarehouse} />
          <FormattedMessage id="warehouses-t-b-new" />
        </Link>
        <Link to={`/panel/warehouses/vehicles/new`} className="btn btn-primary with-icon">
          <FontAwesomeIcon icon={faTruck} />
          <FormattedMessage id="vehicles-t-b-new" />
        </Link>
        <Link to={`/panel/warehouses/drivers/new`} className="btn btn-primary with-icon">
          <FontAwesomeIcon icon={faUser} />
          <FormattedMessage id="drivers-t-b-new" />
        </Link>
        <Link to={`/panel/warehouses/belts/new`} className="btn btn-primary with-icon">
          <FontAwesomeIcon icon={faTrailer} />
          <FormattedMessage id="belts-t-b-new" />
        </Link>
      </CardHeader>
      <div className="card card-panel">
        <ul className="nav nav-tabs card-tabs">
          <li className="nav-item">
            <NavLink
              exact
              to="/panel/warehouses/list"
              isActive={(match, location) => handleActive(match, location, '/panel/warehouses')}
              className="nav-link"
              activeClassName="active"
            >
              <FormattedMessage id="warehouses-tab-1" />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to="/panel/warehouses/vehicles/list"
              isActive={(match, location) => handleActive(match, location, '/panel/vehicles')}
              className="nav-link"
              activeClassName="active"
            >
              <FormattedMessage id="warehouses-tab-2" />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to="/panel/warehouses/drivers/list"
              isActive={(match, location) => handleActive(match, location, '/panel/drivers')}
              className="nav-link"
              activeClassName="active"
            >
              <FormattedMessage id="warehouses-tab-3" />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to="/panel/warehouses/belts/list"
              isActive={(match, location) => handleActive(match, location, '/panel/belts')}
              className="nav-link"
              activeClassName="active"
            >
              <FormattedMessage id="warehouses-tab-4" />
            </NavLink>
          </li>
        </ul>
        <div className="card-body">
          <Switch>
            <Route exact path={`${match.url}/list`} component={TableWarehouses} />
            <Route exact path={`${match.url}/vehicles/list`} component={TableVehicles} />
            <Route exact path={`${match.url}/drivers/list`} component={TableDrivers} />
            <Route exact path={`${match.url}/belts/list`} component={TableBelts} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

TablesWarehouse.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
};

export default withTranslation(TablesWarehouse, ['warehouses', 'vehicles', 'drivers', 'belts']);
