import React from 'react';
import styles from './modals.module.css';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { createPortal } from 'react-dom';

const modalRoot = document.getElementById('modal-root');

const BaseModal = ({ closeModal, title, tabs, children, modalStyle, bodyStyle }) => {
  const [tab, setTab] = React.useState(0);
  const childrenToRender = tabs.length ? children[tab] : children;

  return createPortal(
    <div className={styles['modal']}>
      <div className={`card animated fadeInUp faster ${styles['content']}`} style={modalStyle}>
        <div className="card-header">
          <h6>{title}</h6>
          <button type="button" className="btn btn-link modal-close-button" onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <ul className="nav nav-tabs card-tabs">
          {tabs.map((t, i) => (
            <li key={i} className="nav-item">
              <button type="button" className={`nav-link ${i === tab && 'active'}`} onClick={() => setTab(i)}>
                {t}
              </button>
            </li>
          ))}
        </ul>
        <div className="card-body" style={bodyStyle}>
          {childrenToRender}
        </div>
      </div>
      <div className={styles['backdrop']} onClick={closeModal} />
    </div>,
    modalRoot,
  );
};

BaseModal.propTypes = {
  closeModal: PropTypes.func,
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element, () => null]),
  modalStyle: PropTypes.any,
  bodyStyle: PropTypes.any,
};

BaseModal.defaultProps = {
  closeModal: f => f,
  title: '',
  tabs: [],
  children: null,
  modalStyle: { width: '48em' },
  bodyStyle: { maxHeight: '80vh', overflowY: 'auto' },
};

export default BaseModal;
