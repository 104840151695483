import React from 'react';
import PropTypes from 'prop-types';
import LocationType from '#components/UI/extra/locationType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faMapMarkerAlt, faFlagCheckered } from '@fortawesome/free-solid-svg-icons';
import TimePicker from '#components/forms/customForms/timePicker/timePicker';
import withTranslation from '#components/HOCs/withTranslation';
import styles from './routeOrderList.module.css';

const RouteOrderList = ({ translations, route, onOrderChanged, onLocationRemove }) => {
  const [draggedItem, setDraggedItem] = React.useState(null);

  const onDragStart = (event, index) => {
    setDraggedItem(route[index]);
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/html', event.target);
    event.dataTransfer.setDragImage(event.target, 50, 50);
  };

  const onDragOver = index => {
    const draggedOverItem = route[index];
    // Ignore if dragged over itself
    if (draggedItem === draggedOverItem) {
      return;
    }
    // filter out the currently dragged item
    let items = route.filter(item => item !== draggedItem);
    // add the dragged item after the dragged over item
    items.splice(index, 0, draggedItem);

    onOrderChanged(items);
  };

  const onDragEnd = () => setDraggedItem(null);

  const onEstimateChange = ({ target: { name, value } }) => {
    const items = route.map(l => {
      if (l.uuid === name || l.id_rl === name) {
        return {
          ...l,
          estimate_time_arrival: value,
        };
      }
      return l;
    });
    onOrderChanged(items);
  };

  return (
    <div id="route-order">
      <ul className={styles['route-list']}>
        {route.map((l, i) => (
          <li key={i} className={styles['route-item']} onDragOver={() => onDragOver(i)}>
            <div className={styles['route-position']}>
              {(function() {
                if (i === 0) {
                  return <FontAwesomeIcon icon={faMapMarkerAlt} />;
                } else {
                  if (i + 1 === route.length) {
                    return <FontAwesomeIcon icon={faFlagCheckered} />;
                  }
                  return <span>{i + 1}</span>;
                }
              })()}
            </div>
            <div className={styles['route-card']} draggable onDragStart={e => onDragStart(e, i)} onDragEnd={onDragEnd}>
              <div className={styles['card-left']}>
                <FontAwesomeIcon icon={faBars} />
              </div>
              <div className={styles['card-right']}>
                <p className={styles['card-title']}>
                  #{l.id_location} {l.name}
                </p>
                <span>
                  <LocationType type={l.type} /> - {l.location_external && l.location_external}
                </span>
                {i !== 0 && (
                  <TimePicker
                    key={l.uuid || l.id_rl}
                    name={l.uuid || l.id_rl}
                    label={translations['routes-new-form-estimate']}
                    customClass={styles['timepicker']}
                    value={l.estimate_time_arrival}
                    handleInputChange={onEstimateChange}
                  />
                )}
              </div>
              <button type="button" className={styles['card-delete']} onClick={() => onLocationRemove(i)}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </li>
        ))}
      </ul>

      {/** No locations Card */}
      {route.length === 0 && (
        <div className={styles['list-pre']}>
          <p className={styles['list-pre-title']}>{translations['routes-f-nolocations-title']}</p>
          <p>{translations['routes-f-nolocations-msg']}</p>
        </div>
      )}
    </div>
  );
};

RouteOrderList.propTypes = {
  translations: PropTypes.any,
  route: PropTypes.array,
  onOrderChanged: PropTypes.func,
  onLocationRemove: PropTypes.func,
};

export default withTranslation(RouteOrderList, 'locations');
