import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/@wafo/forms/dist/index.css';            // Custom forms styling
import '../node_modules/@wafo/table/lib/styles.css';                // Custom table styling
import '../node_modules/bootstrap-css-only/css/bootstrap.min.css';  // Bootstrap styles
import '../node_modules/animate.css/animate.min.css';               // Animations (alerts)
import './globalStyles.css';                                        // Custom styles
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
