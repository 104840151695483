import React from 'react';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import { useHistory } from 'react-router-dom';
import useTranslation from '#hooks/redux/useTranslation';
import PanelContainer from '../panelContainer';
import { FormattedMessage } from 'react-intl';
import FormPassword from '#components/forms/profile/formPassword';
import UserInfo from './userInfo';
import FormUpAuth from '#components/forms/profile/formUpAuth';
import useAuth from '#hooks/useAuth';

const Profile = () => {
  const history = useHistory();
  const { translations } = useTranslation();

  const token = useAuth();
  console.log(token);

  const [key, setKey] = React.useState(0);
  const resetKey = () => setKey(Math.random());

  return (
    <PanelContainer key={key}>
      <CardHeader title={translations['profile-title']} showBack onBack={() => history.goBack()} />
      <div className="card card-panel">
        <div className="card-body">
          <h5 className="card-subtitle">
            <FormattedMessage id="profile-subtitle-1" />
          </h5>
          <UserInfo />
          <div className="divider" />
          <div className="divider" />
          <h5 className="card-subtitle">
            <FormattedMessage id="profile-subtitle-2" />
          </h5>
          <FormPassword onSubmitSuccess={resetKey} />
          {token && token.type === 1 && (
            <React.Fragment>
              <div className="divider" />
              <div className="divider" />
              <h5 className="card-subtitle">
                <FormattedMessage id="profile-subtitle-3" />
              </h5>
              <FormUpAuth onSubmitSuccess={resetKey} />
            </React.Fragment>
          )}
        </div>
      </div>
    </PanelContainer>
  );
};

export default Profile;
