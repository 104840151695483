import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormAutocomplete, WafoFormInput } from '@wafo/forms';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import useApi from '#hooks/api/useApi';
import LocationListItem from '#components/UI/locationListItem/locationListItem';
import { useParams } from 'react-router-dom';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormDriver = ({ locale, translations, onSubmitSuccess }) => {
  const [driver, setDriver] = React.useState({});
  const [locations, setLocations] = React.useState([]);

  const { id } = useParams();
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  React.useEffect(() => {
    async function getDriver() {
      try {
        loading.set();
        let helper = await api.get(`/transports/drivers/${id}`);

        const warehouse = locations.find(x => x.id_location === helper.id_location);
        if (warehouse) {
          helper = { ...helper, id_location: warehouse };
        }
        setDriver(helper);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    if (id && !!locations.length) {
      getDriver();
    }
  }, [api, id, loading, locations]);

  React.useEffect(() => {
    async function getLocations() {
      try {
        loading.set();
        const resp = await api.get('/locations', {
          params: {
            limit: 999,
            type: '4,5',
          },
        });
        setLocations(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getLocations();
  }, [api, loading]);

  async function handleSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();
        if (id) {
          await api.put(`/transports/drivers/${id}`, {
            ...values,
            id_location: values.id_location.id_location,
          });
        } else {
          await api.post('/transports/drivers', {
            ...values,
            id_location: values.id_location.id_location,
          });
        }
        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        onSubmitSuccess();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }

  return (
    <div>
      <WafoForm formId="form-driver" locale={locale} onSubmit={handleSubmit} values={driver}>
        <WafoFormInput
          type="text"
          name="name"
          label={translations['driver-f-label-name']}
          placeholder={translations['driver-f-placeholder-name']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormInput
          type="text"
          name="last_name"
          label={translations['driver-f-label-last_name']}
          placeholder={translations['driver-f-placeholder-last_name']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormInput
          type="text"
          name="document_number"
          label={translations['driver-f-label-document_number']}
          placeholder={translations['driver-f-placeholder-document_number']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormAutocomplete
          name="id_location"
          customClass="col-12 col-md-4"
          label={translations['driver-f-label-location']}
          placeholder={translations['driver-f-placeholder-location']}
          items={locations}
          filterItems={(items, query) => items.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)}
          renderInput={item => item.name}
          renderItem={item => <LocationListItem location={item} />}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          handleChange
          // onSelectCallback={onLocationSelect}
        />
      </WafoForm>
      <div className="row">
        <div className="col-12 text-center">
          <button type="submit" form="form-driver" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </div>
    </div>
  );
};

FormDriver.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
};

export default withTranslation(FormDriver, 'drivers');
