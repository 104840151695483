import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@wafo/table';
import { useHistory, useRouteMatch } from 'react-router-dom';
import withTranslation from '#components/HOCs/withTranslation';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import useApi from '#hooks/api/useApi';
import moment from 'moment';
import useLoading from '#context/hooks/useLoading';
import useQuery from '#hooks/router/useQuery';

const TableNotifications = ({ locale, translations }) => {
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const tableRef = React.useRef();

  const api = useApi();
  const loading = useLoading();

  const history = useHistory();
  const query = useQuery();
  const match = useRouteMatch();

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await api.get('/notifications', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
          },
        });
        setTotalRows(resp.count);
        setRows(
          resp.rows.map(row => ({
            id: row.id_notification,
            seen: row.seen,
            noti: row.notifications,
            date: row.createdAt,
            options: { id: row.id_notification, seen: row.seen },
          })),
        );
        history.replace({
          pathname: match.url,
          search: `?page=${page}`,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [api, loading, history, match.url],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: function col(val) {
        return <span>#{val}</span>;
      },
      seen: function col(val) {
        return (
          <span className={`badge table-badge ${val ? 'badge-success' : 'badge-warning'}`}>
            {val ? translations['notifications-t-seen'] : translations['notifications-t-unseen']}
          </span>
        );
      },
      noti: {
        render: function col(val) {
          return (
            <>
              <span style={{ fontWeight: 'bold' }}>{val.title}</span>
              <span>{val.message}</span>
            </>
          );
        },
        style: {
          display: 'flex',
          flexDirection: 'column',
          fontSize: '0.85em',
        },
      },
      date: {
        render: function col(val) {
          return <span>{moment(val).format('DD MMMM YYYY HH:mm')}</span>;
        },
        style: {
          whiteSpace: 'nowrap',
          fontSize: '0.9em',
        },
      },
      options: function col(val) {
        return <span></span>;
      },
    }),
    [translations],
  );

  return (
    <div>
      <CardHeader title={translations['notifications-t-title']} />
      <div className="card card-panel">
        <div className="card-body">
          <DataTable
            ref={tableRef}
            locale={locale}
            columns={translations['notifications-t-columns']}
            rows={rows}
            totalRows={totalRows}
            tableClass="table table-striped table-sm"
            onPagination={getData}
            noRowsMessage={translations['notifications-t-norows']}
            initialPage={parseInt(query.get('page'), 10)}
            columnsConfig={columnsConfig}
          />
        </div>
      </div>
    </div>
  );
};

TableNotifications.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  alerts: PropTypes.shape({
    success: PropTypes.func,
  }),
};

export default withTranslation(TableNotifications, 'notifications');
