import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '#components/modals/baseModal';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import useApi from '#hooks/api/useApi';
import styles from './unloadModal.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const UnloadModal = ({ translations, equipments, idPackage, onClose }) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  const [unload, setUnload] = React.useState(() => {
    const rows = equipments.reduce((accumulator, e) => {
      const weight = (e.quantity * e.material.quantity).toFixed(3);
      accumulator[e.equipment_id] = {
        id: e.id_equipment,
        origin: weight,
        unloaded: weight,
        material: e.material,
      };
      return accumulator;
    }, {});
    return {
      store: null,
      equipments: rows,
      expires: '',
    };
  });

  const [warehouse, setWarehouse] = React.useState({
    location_stores: [],
  });

  React.useEffect(() => {
    async function getWarehouse() {
      try {
        loading.set();
        const resp = await api.get(`/locations/${equipments[0].id_location}`);
        setWarehouse(resp);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getWarehouse();
  }, [api, equipments, loading]);

  const location_stores = React.useMemo(() => {
    return warehouse.location_stores.map(ls => {
      let storage = 0;
      let used = 0;
      ls.store_materials.forEach(sm => {
        storage += sm.limit_quantity * sm.material.quantity;
      });
      ls.user_stores.forEach(us => {
        used += us.available_quantity * us.material.quantity;
      });
      return {
        id: ls.id_store,
        name: ls.name,
        storage,
        used,
        stores: ls.store_materials.length,
        default_store: ls.default_store,
      };
    });
  }, [warehouse.location_stores]);

  const handleOnChange = ({ target: { name, value } }) => {
    setUnload(prev => ({
      ...prev,
      equipments: {
        ...prev.equipments,
        [name]: {
          ...prev.equipments[name],
          unloaded: value,
        },
      },
    }));
  };

  const handleSubmit = async () => {
    try {
      loading.set();
      if (unload.store || unload.expires) {
        await api.post('/movements/packages', {
          id_package: idPackage,
          id_store: unload.store,
          expire_date: moment(unload.expires).toISOString(),
          equipments: Object.entries(unload.equipments).map(([, entry]) => ({
            id_equipment: entry.id,
            quantity: entry.unloaded
              ? entry.unloaded / entry.material.quantity
              : entry.origin / entry.material.quantity,
          })),
        });

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });

        onClose();
      } else {
        alerts.set({
          type: 'warning',
          title: translations['equipments-m-unload-a-empty-title'],
          message: translations['equipments-m-unload-a-empty-message'],
          timer: 3000,
        });
      }

      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  return (
    <BaseModal
      title={translations['equipments-m-unload-title']}
      modalStyle={{ width: '48em', maxHeight: '80vh', minHeight: '40vh' }}
      closeModal={onClose}
    >
      <div className="row">
        <div className="col-12">
          <p>
            <FormattedMessage id="equipments-m-unload-desc" />
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <h6 className={styles['subtitle']}>
            <strong>
              <FormattedMessage id="equipments-m-unload-name" />:
            </strong>{' '}
            <span style={{ color: '#4f4f4f' }}>{warehouse.name}</span>
          </h6>
          <ul className={styles['store-list']}>
            {location_stores.map(store => {
              const percent = store.storage === 0 ? 100 : (store.used * 100) / store.storage;

              return (
                <li key={store.id} style={store.id === unload.store ? { backgroundColor: '#007bff20' } : {}}>
                  <span>
                    <strong>{store.name}</strong>
                    {store.default_store && <FontAwesomeIcon icon={faCertificate} className={styles['certified']} />}
                  </span>
                  <div className={styles['store-container']}>
                    <div style={{ width: `${percent}%` }} />
                    <span>
                      <strong>{percent.toFixed(2)} %</strong> ({store.stores}{' '}
                      <FormattedMessage id="warehouses-d-s-stores" />)
                    </span>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="btn btn-link btn-sm"
                      onClick={() => setUnload(prev => ({ ...prev, store: store.id }))}
                    >
                      <FontAwesomeIcon icon={store.id === unload.store ? faCheckSquare : faSquare} />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <p className="modal-warning-text">
            <FormattedMessage id="equipments-m-unload-warning" />
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className={styles['expires']}>
            <span>
              <FormattedMessage id="equipments-m-unload-f-label-expires" />
            </span>
            <input
              type="date"
              className="form-control"
              value={unload.expires}
              onChange={({ target: { value } }) => setUnload(prev => ({ ...prev, expires: value }))}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div>
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  {translations['equipments-m-unload-columns'].map((col, i) => (
                    <th key={i}>{col}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.entries(unload.equipments).map(([key, e]) => {
                  return (
                    <tr key={key}>
                      <td>{key}</td>
                      <td style={{ lineHeight: 1 }}>
                        <span>
                          {e.origin} {e.material.unit_type}
                        </span>
                        <br />
                        <span style={{ fontSize: '0.85em' }}>
                          #{e.material.id_material} {e.material.name} -{' '}
                          {translations['materials-f-types'][e.material.material_type]}
                        </span>
                      </td>
                      <td>
                        <input
                          type="number"
                          name={key}
                          className={styles['input']}
                          placeholder="0"
                          min="0"
                          onChange={handleOnChange}
                          value={e.unloaded}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 text-center">
          <button type="button" className="btn btn-s btn-submit" onClick={handleSubmit}>
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </div>
    </BaseModal>
  );
};

UnloadModal.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  equipments: PropTypes.array,
  idPackage: PropTypes.string,
};

UnloadModal.defaultProps = {
  equipments: [],
  idPackage: '',
};

export default withTranslation(UnloadModal, ['equipments', 'warehouses', 'materials']);
