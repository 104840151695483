import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@wafo/table';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff, faToggleOn, faEdit, faUser } from '@fortawesome/free-solid-svg-icons';
import withTranslation from '#components/HOCs/withTranslation';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import useApi from '#hooks/api/useApi';
import { makeModal } from '#components/modals/makeModal';
import ConfirmModal from '#components/modals/confirmModal/confirmModal';
import UserType from '#components/UI/extra/userType';
import useLoading from '#context/hooks/useLoading';
import useQuery from '#hooks/router/useQuery';

const TableUsers = ({ locale, translations }) => {
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const tableRef = React.useRef();

  const api = useApi();
  const loading = useLoading();

  const history = useHistory();
  const query = useQuery();
  const match = useRouteMatch();

  const toggleDisabled = React.useCallback(
    async (id, disabled) => {
      async function callDisable() {
        try {
          loading.set();
          await api.put(`/users/${id}`, { disabled: !disabled });
          loading.stop();
          tableRef.current.forceUpdate();
        } catch (error) {
          loading.stop();
        }
      }
      makeModal(ConfirmModal, {
        message: translations['users-t-confirm-m'],
        translations,
        onAccept: () => {
          callDisable();
        },
      });
    },
    [api, loading, translations],
  );

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await api.get('/users', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
          },
        });
        setTotalRows(resp.count);
        setRows(
          resp.rows.map(row => ({
            id: row.id_user,
            status: row.disabled,
            type: row.type,
            name: `${row.name} ${row.last_name}`,
            email: row.email,
            options: { id: row.id_user, disabled: row.disabled },
          })),
        );
        history.replace({
          pathname: match.url,
          search: `?page=${page}`,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [api, loading, history, match.url],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: function col(val) {
        return <span>#{val}</span>;
      },
      status: function col(val) {
        return (
          <span className={`badge table-badge ${!val ? 'badge-success' : 'badge-warning'}`}>
            <FormattedMessage id={!val ? 'users-t-enabled' : 'users-t-disabled'} />
          </span>
        );
      },
      type: function col(val) {
        return <UserType type={val} />;
      },
      options: function col(val) {
        return (
          <React.Fragment>
            <button
              type="button"
              className={`btn btn-table ${val.disabled ? 'btn-danger' : 'btn-success'}`}
              title={val.disabled ? 'Enable' : 'Disable'}
              onClick={() => {
                toggleDisabled(val.id, val.disabled);
              }}
            >
              <FontAwesomeIcon icon={val.disabled ? faToggleOff : faToggleOn} />
            </button>
            <Link to={`/panel/users/new/${val.id}`} className="btn btn-table btn-primary">
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: '0.5rem' }} />
              {translations['users-t-b-edit']}
            </Link>
          </React.Fragment>
        );
      },
    }),
    [translations, toggleDisabled],
  );

  return (
    <div>
      <CardHeader title={translations['users-t-title']}>
        <Link to={`/panel/users/new`} className="btn btn-primary with-icon">
          <FontAwesomeIcon icon={faUser} />
          <FormattedMessage id="users-t-b-new" />
        </Link>
      </CardHeader>
      <div className="card card-panel">
        <div className="card-body">
          <DataTable
            ref={tableRef}
            locale={locale}
            columns={translations['users-t-columns']}
            rows={rows}
            totalRows={totalRows}
            tableClass="table table-striped table-sm"
            onPagination={getData}
            noRowsMessage={translations['users-t-norows']}
            initialPage={parseInt(query.get('page'), 10)}
            columnsConfig={columnsConfig}
          />
        </div>
      </div>
    </div>
  );
};

TableUsers.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  alerts: PropTypes.shape({
    success: PropTypes.func,
  }),
};

export default withTranslation(TableUsers, 'users');
