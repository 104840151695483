import React from 'react';
import PropTypes from 'prop-types';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import { FormattedMessage } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import withTranslation from '#components/HOCs/withTranslation';
import FormBelt from '#components/forms/warehouses/formBelt';

const NewBelt = () => {
  const history = useHistory();
  const { id } = useParams();

  return (
    <div>
      <CardHeader
        title={<FormattedMessage id={id ? 'belts-f-e-title' : 'belts-f-title'} values={{ id }} />}
        showBack
        onBack={() => history.goBack()}
      />
      <div className="card card-panel">
        <div className="card-body">
          <h5 className="card-subtitle">
            <FormattedMessage id={id ? 'belts-f-e-subtitle' : 'belts-f-subtitle'} />
          </h5>
          <p>
            <FormattedMessage id="belts-f-description" />
          </p>
          <FormBelt onSubmitSuccess={() => history.goBack()} />
        </div>
      </div>
    </div>
  );
};

NewBelt.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
};

export default withTranslation(NewBelt, 'belts');
