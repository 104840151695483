import React from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { WafoForm, WafoFormInput, WafoFormAutocomplete } from '@wafo/forms';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';
import useTranslation from '#hooks/redux/useTranslation';
import DatetimePicker from '#components/forms/customForms/datetimePicker/datetimePicker';
import { FormattedMessage } from 'react-intl';
import MaterialType from '#components/UI/extra/materialType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import TimePicker from '#components/forms/customForms/timePicker/timePicker';
import moment from 'moment';
import styles from './ships.module.css';

const FormShip = ({ onSubmitSuccess }) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();
  const { locale, translations } = useTranslation();

  /** **************************************** */
  /** Extras */
  /** **************************************** */

  const [clients, setClients] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [materials, setMaterials] = React.useState([]);

  React.useEffect(() => {
    async function getClients() {
      try {
        loading.set();
        const resp = await api.get('/users', {
          params: {
            limit: 999,
            disabled: false,
            type: 2,
          },
        });
        setClients(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }

    async function getLocations() {
      loading.set();
      try {
        const resp = await api.get('/locations', {
          params: {
            limit: 999,
            disabled: false,
            type: '3, 4',
          },
        });
        setLocations(resp.rows);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }

    async function getMaterials() {
      try {
        loading.set();
        const resp = await api.get('/materials', {
          params: {
            limit: 999,
            disabled: false,
          },
        });
        setMaterials(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }

    getClients();
    getLocations();
    getMaterials();
  }, [api, loading]);

  /** **************************************** */
  /** Ship */
  /** **************************************** */
  const { id } = useParams();

  const [ship, setShip] = React.useState({
    id_ship: 0,
    shipment_storages: [],
  });

  React.useEffect(() => {
    async function getShip() {
      loading.set();
      try {
        const resp = await api.get(`/ships/${id}`);
        setShip({
          ship_id: resp.ship_id,
          name: resp.name,
          id_user: clients.find(x => x.id_user === resp.id_user),
          id_location: locations.find(x => x.id_location === resp.id_location),
          eta_date: new Date(resp.eta_date),
          ...(resp.arrival_date && { arrival_date: new Date(resp.arrival_date) }),
          ...(resp.dock_date && { dock_date: new Date(resp.dock_date) }),
          ...(resp.sail_date && { sail_date: moment(resp.sail_date).format('YYYY-MM-DD') }),
        });

        setShip({
          ...resp,
        });
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }

    if (id && !!locations.length && !!clients.length) {
      getShip();
    }
  }, [api, loading, id, clients, locations]);

  const addStore = () => {
    setShip(prev => ({ ...prev, shipment_storages: [...prev.shipment_storages, { id: uuidv4() }] }));
  };

  const removeStore = id => {
    setShip(prev => {
      const dummy = [...prev.shipment_storages];
      const index = dummy.findIndex(x => x.id === id);

      if (index !== -1) dummy.splice(index, 1);

      return {
        ...prev,
        shipment_storages: dummy,
      };
    });
  };

  async function handleSubmit(form, values) {
    if (form.valid) {
      loading.set();
      try {
        const sail_time = moment.duration(values.sail_time).asSeconds();

        const toSend = {
          name: values.name,
          id_user: values.id_user.id_user,
          ...(!id && { id_location: values.id_location.id_location }),
          ship_id: values.ship_id,
          eta_date: moment(values.eta_date).toISOString(),
          ...(values.arrival_date && { arrival_date: moment(values.arrival_date).toISOString() }),
          ...(values.dock_date && { dock_date: moment(values.dock_date).toISOString() }),
          ...(values.sail_date && { sail_date: moment(values.sail_date, 'YYYY-MM-DD').toISOString() }),
          ...(sail_time && { sail_time }),
          ...(!id && {
            shipment_storages: Object.entries(values.shipment_storages).map(([, value]) => ({
              id_material: value.id_material.id_material,
              limit_quantity: value.limit_quantity / parseFloat(value.id_material.quantity),
              stowage_plan: value.stowage_plan / parseFloat(value.id_material.quantity),
            })),
          }),
        };

        let resp = null;
        if (id) {
          resp = await api.put(`/ships/${id}`, toSend);
        } else {
          resp = await api.post('/ships', toSend);
        }

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        onSubmitSuccess(resp);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
  }

  /** **************************************** */
  /** Render */
  /** **************************************** */

  return (
    <div>
      <WafoForm formId="form-ship" locale={locale} onSubmit={handleSubmit} values={ship}>
        <WafoFormInput
          type="text"
          name="ship_id"
          label={translations['ships-f-label-id']}
          placeholder={translations['ships-f-placeholder-id']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />
        <WafoFormInput
          type="text"
          name="name"
          label={translations['ships-f-label-name']}
          placeholder={translations['ships-f-placeholder-name']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />
        <WafoFormAutocomplete
          name="id_user"
          customClass="col-12 col-md-4"
          label={translations['ships-f-label-client']}
          placeholder={translations['ships-f-placeholder-client']}
          items={clients}
          filterItems={(items, query) =>
            items.filter(
              item =>
                item.name.toLowerCase().indexOf(query) !== -1 || item.last_name.toLowerCase().indexOf(query) !== -1,
            )
          }
          renderInput={item => `${item.name} ${item.last_name}`}
          renderItem={item => (
            <p style={{ margin: '0', padding: '0.75rem 0.5rem', borderBottom: '1px solid #f5f5f5' }}>
              <span>{`${item.name} ${item.last_name}`}</span>
            </p>
          )}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          handleChange
        />
        <WafoFormAutocomplete
          name="id_location"
          customClass="col-12 col-md-4"
          label={translations['ships-f-label-location']}
          placeholder={translations['ships-f-placeholder-location']}
          items={locations}
          filterItems={(items, query) => items.filter(item => item.name.toLowerCase().indexOf(query) !== -1)}
          renderInput={item => item.name}
          renderItem={item => (
            <p style={{ margin: '0', padding: '0.75rem 0.5rem', borderBottom: '1px solid #f5f5f5' }}>
              #{item.id_location} - <span>{item.name}</span>
            </p>
          )}
          extraProps={{
            autoComplete: 'off',
            disabled: id,
          }}
          validations={{ required: true }}
          handleChange
        />

        <div className="col-12">
          <div className="divider" />
          <h5>
            <FormattedMessage id="ships-f-sub-1" />
          </h5>
        </div>

        <DatetimePicker
          name="eta_date"
          customClass="col-12 col-md-4"
          label={translations['ships-f-label-eta']}
          validations={{ required: true }}
          handleChange
        />
        <DatetimePicker
          name="arrival_date"
          customClass="col-12 col-md-4"
          label={translations['ships-f-label-arrival']}
          validations={{ required: false }}
          handleChange
        />
        <DatetimePicker
          name="dock_date"
          customClass="col-12 col-md-4"
          label={translations['ships-f-label-dock']}
          validations={{ required: false }}
          handleChange
        />
        <WafoFormInput
          type="date"
          name="sail_date"
          label={translations['ships-f-label-saildate']}
          placeholder={translations['ships-f-label-saildate']}
          customClass="col-12 col-md-4"
          validations={{ required: false }}
        />
        <TimePicker
          name="sail_time"
          label={translations['ships-f-label-sailtime']}
          customClass="col-12 col-md-4"
          validations={{ required: false }}
          handleChange
        />

        {!id && (
          <React.Fragment>
            <div className="col-12">
              <div className="divider" />
              <div className="form-divider-with-btn">
                <h5>
                  <FormattedMessage id="ships-f-sub-2" />
                </h5>
                <button type="button" className="btn btn-sm btn-link with-icon" onClick={addStore}>
                  <FontAwesomeIcon icon={faPlus} />
                  <FormattedMessage id="warehouses-f-store-b-add" />
                </button>
              </div>
            </div>

            <div className="col-12" groupname="shipment_storages">
              {ship.shipment_storages?.map((store, index) => (
                <div key={store.id} className="row" groupname={store.id}>
                  <div className="col-12 col-md-1" style={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}>
                    <span>#{index+1}</span>
                  </div>
                  <WafoFormAutocomplete
                    name="id_material"
                    customClass="col-12 col-md-4"
                    label={translations['ships-f-store-label-material']}
                    placeholder={translations['ships-f-store-placeholder-material']}
                    items={materials}
                    filterItems={(items, query) =>
                      items.filter(i => i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                    }
                    renderInput={item =>
                      `[${item.unit_type} - ${translations['materials-f-types'][item.material_type - 1]}] ${item.name}`
                    }
                    renderItem={item => (
                      <p style={{ margin: '0', padding: '0.5rem', lineHeight: 1, borderBottom: '1px solid #f5f5f5' }}>
                        <span>{item.name}</span>
                        <br />
                        <span style={{ fontSize: '0.85em', color: '#777777' }}>
                          {item.unit_type}
                          {' - '}
                          <MaterialType translations={translations} type={item.material_type} />
                        </span>
                      </p>
                    )}
                    extraProps={{
                      autoComplete: 'off',
                    }}
                    validations={{ required: true }}
                    handleChange
                  />

                  <WafoFormInput
                    type="number"
                    name="limit_quantity"
                    label={translations['ships-f-store-label-limit']}
                    placeholder={translations['ships-f-store-placeholder-limit']}
                    customClass="col-12 col-md-3"
                    validations={{ required: true }}
                  />

                  <WafoFormInput
                    type="number"
                    name="stowage_plan"
                    label={translations['ships-f-store-label-stowage']}
                    placeholder={translations['ships-f-store-placeholder-stowage']}
                    customClass="col-12 col-md-3"
                    validations={{ required: true }}
                  />

                  <div className={`col-12 col-md-1 ${styles['row-button']}`}>
                    <button type="button" className="btn btn-link" onClick={() => removeStore(store.id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              ))}
              {ship.shipment_storages?.length === 0 && (
                <p className="form-well-msg">
                  <FormattedMessage id="ships-f-nostores" />
                </p>
              )}
            </div>
          </React.Fragment>
        )}

        <div className="col-12 text-center">
          <div className="divider" />
          <button type="submit" form="form-ship" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </WafoForm>
    </div>
  );
};

export default FormShip;
