import React from 'react';
import PropTypes from 'prop-types';
import styles from './storeContainer.module.css';

const StoreContainerH = ({ total, used, backgroundColor, barColor, unit }) => {
  const percent = total === 0 ? 100 : (used * 100) / total;

  return (
    <div className={styles['container-wrapper-h']} style={{ backgroundColor }}>
      <div className={styles['container-bar-h']} style={{ backgroundColor: barColor, width: `${percent}%` }} />
      <div className={styles['container-text-h']}>
        <span>{percent.toFixed(2)}%</span>
        <span>
          {used.toFixed(3)} {unit}.
        </span>
      </div>
    </div>
  );
};

StoreContainerH.propTypes = {
  total: PropTypes.number,
  used: PropTypes.number,
  backgroundColor: PropTypes.string,
  barColor: PropTypes.string,
  unit: PropTypes.string,
};

StoreContainerH.defaultProps = {
  total: 100,
  used: 35,
  backgroundColor: '#f5f5f5',
  barColor: '#80E296',
  unit: 'NA',
};

export default StoreContainerH;
