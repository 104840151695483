import React from 'react';
import BaseModal from '#components/modals/baseModal';
import useTranslation from '#hooks/redux/useTranslation';
import { LocalTable } from '@wafo/table';
import FormShipIncident from '../../forms/ships/formShipIncident';
import MomentDate from '../../UI/general/momentDate/momentDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCheck, faTrash, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ButtonDropdown from '../../UI/general/buttonDropdown/buttonDropdown';
import { FormattedMessage } from 'react-intl';
import useLoading from '#context/hooks/useLoading';
import useApi from '#hooks/api/useApi';
import moment from 'moment';
import styles from './ships.module.css';
import { WafoFormSelect } from '@wafo/forms';

const ShipIncidentsModal = ({ ship, incidents, updateData, onClose }) => {
  const { translations, locale } = useTranslation();
  const loading = useLoading();
  const api = useApi();

  const handleClose = () => {
    updateData();
    onClose();
  };

  const [filters, setFilters] = React.useState(() => ({
    type: '',
  }));

  React.useEffect(() => {
    updateData(filters);
  }, [filters, updateData]);

  const [modalKey, setModalKey] = React.useState(0);

  const [edit, setEdit] = React.useState(false);

  const onEdit = React.useCallback(
    id => {
      const incident = incidents.find(i => i.id_ship_incident === id);
      setEdit(incident);
    },
    [incidents],
  );

  const releaseIncident = React.useCallback(
    async id => {
      loading.set();
      try {
        await api.put(`/ships/incidents/${id}`, {
          date_released: moment().toISOString(),
        });
        updateData();
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    },
    [api, loading, updateData],
  );

  const deleteIncident = React.useCallback(
    async id => {
      loading.set();
      try {
        await api.delete(`/ships/incidents/${id}`);
        updateData();
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    },
    [api, loading, updateData],
  );

  const rows = React.useMemo(() => {
    return incidents.map(row => ({
      id: row.id_ship_incident,
      type: row.type,
      dates: {
        incident: row.date_incident,
        released: row.date_released,
      },
      desc: {
        comment: row.comment,
        store: row.id_ship_storage,
      },
      options: row,
    }));
  }, [incidents]);

  const columnsConfig = React.useMemo(
    () => ({
      id: {
        render: val => <span>#{val}</span>,
        style: { fontSize: '0.85em' },
      },
      type: {
        render: val => <span>{translations['s-incidents-types'][val - 1]}</span>,
        style: { fontSize: '0.85em' },
      },
      dates: {
        render: val => (
          <React.Fragment>
            <span>
              <MomentDate date={val.incident} title="Incident date" />
            </span>
            <br />
            <span style={{ fontSize: '0.85em', color: '#777' }}>
              <MomentDate date={val.released} title="Release date" />
            </span>
          </React.Fragment>
        ),
        style: { fontSize: '0.85em', whiteSpace: 'nowrap' },
      },
      desc: {
        render: val => (
          <React.Fragment>
            {val.store && (
              <span>
                <strong>Store:</strong> #{val.store}
              </span>
            )}
            {val.comment && <p style={{ margin: 0 }}>{val.comment}</p>}
            {!val.store && !val.comment && <span>Not available</span>}
          </React.Fragment>
        ),
        style: { fontSize: '0.85em' },
      },
      options: {
        render: val => (
          <React.Fragment>
            <ButtonDropdown text="Opciones" btnStyle={{ fontSize: ' 0.8rem', padding: '0.25rem .75rem' }} side="left">
              <button
                type="button"
                className="btn btn-sm btn-link with-icon"
                onClick={() => onEdit(val.id_ship_incident)}
              >
                <FontAwesomeIcon icon={faEdit} />
                <FormattedMessage id="s-incidents-t-b-edit" />
              </button>
              <button
                type="button"
                className="btn btn-sm btn-link with-icon"
                onClick={() => releaseIncident(val.id_ship_incident)}
                disabled={val.date_released}
              >
                <FontAwesomeIcon icon={faCheck} />
                <FormattedMessage id="s-incidents-t-b-release" />
              </button>
              <button
                type="button"
                className="btn btn-sm btn-link with-icon"
                onClick={() => deleteIncident(val.id_ship_incident)}
              >
                <FontAwesomeIcon icon={faTrash} />
                <FormattedMessage id="s-incidents-t-b-delete" />
              </button>
            </ButtonDropdown>
          </React.Fragment>
        ),
        style: { whiteSpace: 'nowrap' },
      },
    }),
    [translations, deleteIncident, releaseIncident, onEdit],
  );

  return (
    <BaseModal
      key={modalKey}
      title={translations['s-incidents-title']}
      modalStyle={{ width: '48em', maxHeight: '80vh', minHeight: '40vh' }}
      closeModal={handleClose}
      tabs={translations['s-incidents-tabs']}
    >
      <div className="row">
        <div className="col-12">
          {!edit && (
            <React.Fragment>
              <LocalTable
                locale={locale}
                columns={translations['s-incidents-t-columns']}
                rows={rows}
                tableWrapperClass="table-noreload"
                tableClass="table table-striped table-sm"
                columnsConfig={columnsConfig}
                noRowsMessage={translations['s-incidents-t-norows']}
                updateTable={updateData}
                keepPage
                controls={
                  <div className="row">
                    <WafoFormSelect
                      name="type"
                      defaultValue="Filter by type"
                      customClass="col-12 col-md-6"
                      options={translations['s-incidents-types'].map((x, i) => ({
                        value: i + 1,
                        display: x,
                      }))}
                      value={filters.type}
                      onChangeCallback={({ target: { value } }) => setFilters(prev => ({ ...prev, type: value }))}
                    />
                  </div>
                }
              />
              <div className="divider" />
            </React.Fragment>
          )}
          {edit && (
            <>
              <div className={styles['go-back']}>
                <button
                  type="button"
                  className={`btn btn-sm btn-link ${styles['btn-back']}`}
                  onClick={() => setEdit(false)}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                <h6>
                  <strong>{translations['global-t-b-edit']}:</strong> #{edit.id_ship_incident}{' '}
                  {translations['s-incidents-types'][edit.type - 1]}
                </h6>
              </div>
              <FormShipIncident
                key="1"
                shipId={ship.id_ship}
                stores={ship.shipment_storages}
                onSubmitSuccess={() => {
                  updateData();
                  setModalKey(Math.random());
                  setEdit(false);
                }}
                incident={edit}
              />
            </>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <FormShipIncident
            key="2"
            shipId={ship.id_ship}
            stores={ship.shipment_storages}
            onSubmitSuccess={() => {
              updateData();
              setModalKey(Math.random());
            }}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default ShipIncidentsModal;
