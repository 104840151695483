import React from 'react';
import { WafoForm, WafoFormAutocomplete, WafoFormInput } from '@wafo/forms';
import { useParams } from 'react-router-dom';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';
import useTranslation from '#hooks/redux/useTranslation';
import LocationListItem from '#components/UI/locationListItem/locationListItem';
import { FormattedMessage } from 'react-intl';

const FormBelt = ({ onSubmitSuccess }) => {
  const { id } = useParams();
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();
  const { translations, locale } = useTranslation();

  const [belt, setBelt] = React.useState({});
  const [locations, setLocations] = React.useState([]);

  React.useEffect(() => {
    async function getLocations() {
      try {
        loading.set();
        const resp = await api.get('/locations', {
          params: {
            limit: 999,
            type: '4,5',
          },
        });
        setLocations(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getLocations();
  }, [api, loading]);

  React.useEffect(() => {
    async function getBelt() {
      loading.set();
      try {
        const resp = await api.get(`/belts/${id}`);
        setBelt({
          ...resp,
          id_location: locations.find(x => x.id_location === resp.id_location) || '',
        });
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }

    if (id && !!locations.length) {
      getBelt();
    }
  }, [api, id, loading, locations]);

  async function handleSubmit(form, values) {
    if (form.valid) {
      loading.set();
      try {
        if (id) {
          await api.put(`/belts/${id}`, {
            ...values,
            id_location: values.id_location.id_location,
          });
        } else {
          await api.post('/belts', {
            ...values,
            id_location: values.id_location.id_location,
          });
        }
        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        onSubmitSuccess();
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
  }

  return (
    <div>
      <WafoForm formId="form-belt" locale={locale} onSubmit={handleSubmit} values={belt}>
        <WafoFormInput
          type="text"
          name="name"
          label={translations['belts-f-label-name']}
          placeholder={translations['belts-f-placeholder-name']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormAutocomplete
          name="id_location"
          customClass="col-12 col-md-4"
          label={translations['belts-f-label-location']}
          placeholder={translations['belts-f-placeholder-location']}
          items={locations}
          filterItems={(items, query) =>
            items.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
          }
          renderInput={item => item.name}
          renderItem={item => <LocationListItem location={item} />}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          handleChange
          // onSelectCallback={onLocationSelect}
        />
      </WafoForm>
      <div className="row">
        <div className="col-12 text-center">
          <button type="submit" form="form-belt" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormBelt;
