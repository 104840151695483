import React from 'react';
import Alert from './alert';
import { useSegroveContext } from '#context/segrove-context';
import styles from './alert.module.css';

const AlertList = () => {
  const {
    state: { alerts },
    dispatch,
  } = useSegroveContext();

  return (
    <ul className={styles['list']}>
      {alerts
        .slice(0)
        .reverse()
        .map((alert, i) => {
          if (i > 2) {
            return null;
          }
          return (
            <Alert
              key={alert.uuid}
              {...alert}
              removeAlert={() => dispatch({ type: 'ALERT_REMOVE', payload: alert.uuid })}
            />
          );
        })}
    </ul>
  );
};

export default AlertList;
