import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '#components/modals/baseModal';
import withTranslation from '#components/HOCs/withTranslation';
import useApi from '#hooks/api/useApi';
import EquipmentInfo from './tabs/equipmentInfo';
import EquipmentTimeline from './tabs/equipmentTimeline/equipmentTimeline';
import EquipmentIncidents from './tabs/equipmentIncidents/equipmentIncidents';
import EquipmentLogs from './tabs/equipmentLogs/equipmentLogs';
import EquipmentCustoms from './tabs/equipmentCustoms/equipmentCustoms';
import useLoading from '#context/hooks/useLoading';

const EquipmentDetailsModal = ({ translations, equipments, onClose }) => {
  const api = useApi();
  const loading = useLoading();

  const [equipment, setEquipment] = React.useState({
    bill_number: 0,
    bl_number: '',
    createdAt: '',
    customs_date: '',
    customs_expires: '',
    customs_file: '',
    customs_invoice: '',
    date_departed: '',
    disabled: false,
    ended: false,
    equipment_id: '',
    equipment_incidents: [],
    equipment_locations: [],
    equipment_logs: [],
    id_equipment: '',
    id_location: '',
    id_material: '',
    updatedAt: '',
    waybill: 0,
    weight: '',
    material: {},
  });

  const getEquipment = React.useCallback(async () => {
    if (equipments.length > 0) {
      try {
        loading.set();
        const eq = await api.get(`/equipments/${equipments[0].id_equipment}`);
        setEquipment({ ...equipments[0], ...eq });
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }, [api, equipments, loading]);

  React.useEffect(() => {
    getEquipment();
  }, [getEquipment]);

  return (
    <BaseModal
      title={`${translations['equipments-m-details-title']} [${equipment.equipment_id}]`}
      modalStyle={{ width: '48em', maxHeight: '80vh' }}
      tabs={translations['equipments-m-details-tabs']}
      closeModal={onClose}
    >
      <div>
        <EquipmentInfo equipment={equipment} />
        <div className="divider" />
        <EquipmentTimeline locations={equipment.equipment_locations} updateEquipment={getEquipment} />
      </div>
      <EquipmentCustoms
        equipment={equipment}
        locations={equipment.equipment_locations}
        updateEquipment={getEquipment}
      />
      <EquipmentIncidents
        equipmentId={equipment.id_equipment}
        locations={equipment.equipment_locations}
        incidents={equipment.equipment_incidents}
        updateEquipment={getEquipment}
      />
      <EquipmentLogs translations={translations} logs={equipment.equipment_logs} />
    </BaseModal>
  );
};

EquipmentDetailsModal.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  equipments: PropTypes.array,
  onClose: PropTypes.func,
};

EquipmentDetailsModal.defaultProps = {
  equipments: [],
  onClose: f => f,
};

export default withTranslation(EquipmentDetailsModal, ['equipments']);
