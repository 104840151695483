import React from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { getToken, isTokenExpired, refreshToken } from '#utils/authService';
import { useSegroveContext } from '#context/segrove-context';
import { errors as errorTranslations } from '#translations';

const ignoreErrors = [];

const useApi = () => {
  const {
    dispatch,
    state: { locale },
  } = useSegroveContext();

  const api = React.useMemo(() => {
    const api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    async function settingToken() {
      // Getting token
      let token = getToken();
      if (isTokenExpired(token)) {
        token = await refreshToken();
      }
      return token;
    }

    api.interceptors.request.use(async config => {
      const token = await settingToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    });

    api.interceptors.response.use(
      async response => {
        if (response.headers['content-type'].indexOf('application/json') !== -1) {
          return response.data;
        }
        return response;
      },
      error => {
        const errorAlert = {
          type: 'danger',
          title: 'Error',
          message: errorTranslations[locale]['default'],
          timer: 60000,
          ignore: false,
        };

        if (error.response) {
          // The request was made and the server responded with a status code.
          const {
            data: { type },
          } = error.response;

          if (type && ignoreErrors.includes(type)) {
            console.log('ignores');
            errorAlert.ignore = true;
          }

          if (type && errorTranslations[locale][type]) {
            errorAlert.message = (
              <p className="no-margin">
                <span>{errorTranslations[locale][type]}</span>
                <br />
                <span style={{ fontSize: '0.85em' }}>Code: [{type}]</span>
              </p>
            );
          }
        } else if (error.request) {
          // The request was made but no response was received
        } else {
          // Something happened and the request failed.
        }

        if (!errorAlert.ignore) {
          dispatch({
            type: 'ALERT_ADD',
            payload: errorAlert,
          });
        }

        // Sending error to Sentry.
        Sentry.captureException(error);
        // Sending error to calling block.
        return Promise.reject(error);
      },
    );

    return api;
  }, [dispatch, locale]);

  return api;
};

export default useApi;
