import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

export function makeModal(Component, properties, id = 'wafo-makemodal') {
  let divTarget = document.getElementById(id);
  if (!divTarget) {
    divTarget = document.createElement('div');
    divTarget.setAttribute('id', id);
    document.body.appendChild(divTarget);
  }
  render(<Component {...properties} />, divTarget);
}

export function removeModal(id = 'wafo-makemodal') {
  const target = document.getElementById(id);
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
  }
}
