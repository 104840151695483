import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import FilePicker from '#components/forms/customForms/filePicker/filePicker';
import useApi from '#hooks/api/useApi';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormPedimento = ({ translations, locale, onSubmitSuccess, equipment }) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  async function handleFormSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();

        await api.put(`/equipments/${equipment.id_equipment}`, {
          // equipment_id: currentPedimento.equipment,
          customs_invoice: values.customs_invoice,
          customs_date: values.customs_date,
          // customs_expires: values.customs_expires,
        });

        // File if added
        if (values.document) {
          const formModel = new FormData();
          formModel.append('document', values.document);

          await api.post(`/equipments/${equipment.id_equipment}/customsfile`, formModel);
        }

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });

        onSubmitSuccess();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }

  const pedimento = React.useMemo(() => {
    return {
      customs_invoice: equipment.customs_invoice,
      customs_date: equipment.customs_date ? equipment.customs_date.split('T')[0] : '',
      // customs_expires: equipment.customs_expires ? equipment.customs_expires.split('T')[0] : '',
      file: equipment.customs_file ? equipment.customs_file : '',
    };
  }, [equipment]);

  return (
    <div className="row">
      <div className="col-12">
        <WafoForm formId="form-pedimento" onSubmit={handleFormSubmit} values={pedimento} locale={locale}>
          <WafoFormInput
            type="text"
            name="customs_invoice"
            label={translations['customs-f-label-invoice']}
            placeholder={translations['customs-f-placeholder-invoice']}
            customClass="col-12"
            validations={{ required: true }}
          />

          <WafoFormInput
            type="date"
            name="customs_date"
            label={translations['customs-f-label-date']}
            placeholder={translations['customs-f-placeholder-date']}
            customClass="col-12"
            validations={{ required: true }}
          />

          {/* <WafoFormInput
            type="date"
            name="customs_expires"
            label={translations['pedimentos-modal-form-expires-label']}
            placeholder={translations['pedimentos-modal-form-expires-placeholder']}
            customClass="col-12"
            validations={{ required: true }}
          /> */}

          <FilePicker
            name="document"
            label={translations['customs-f-label-document']}
            placeholder={translations['customs-f-placeholder-document']}
            customClass="col-12"
            path={`${process.env.REACT_APP_FILES_EQUIPMENTS_URL}/${pedimento.file}`}
            currentSavedFile={pedimento.file}
            handleChange
          />
        </WafoForm>
      </div>
      <div className="col-12 text-center">
        <button type="submit" form="form-pedimento" className="btn btn-s btn-submit">
          <FormattedMessage id="form-submit" />
        </button>
      </div>
    </div>
  );
};

FormPedimento.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
  equipment: PropTypes.any,
};

export default withTranslation(FormPedimento, 'customs');
