import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@wafo/table';
import useApi from '#hooks/api/useApi';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import withTranslation from '#components/HOCs/withTranslation';
import { makeModal } from '#components/modals/makeModal';
import ConfirmModal from '#components/modals/confirmModal/confirmModal';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff, faToggleOn, faEdit } from '@fortawesome/free-solid-svg-icons';
import useQuery from '#hooks/router/useQuery';
import useLoading from '#context/hooks/useLoading';
import { WafoFormSelect } from '@wafo/forms';

const TableDrivers = ({ translations, locale }) => {
  const api = useApi();
  const loading = useLoading();

  const query = useQuery();
  const history = useHistory();
  const match = useRouteMatch();

  /** ******************************************************* */
  /** Warehouses */
  /** ******************************************************* */

  const [warehouses, setWarehouses] = React.useState([]);
  const [filters, setFilters] = React.useState({
    id_location: '',
  });

  React.useEffect(() => {
    async function getWarehouses() {
      loading.set();
      try {
        const resp = await api.get('/locations', {
          params: {
            limit: 999,
            page: 1,
            type: '4,5',
          },
        });
        setWarehouses(resp.rows);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
    getWarehouses();
  }, [api, loading]);

  /** ******************************************************* */
  /** Table */
  /** ******************************************************* */

  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const tableRef = React.useRef();

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await api.get('/transports/drivers', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
            ...(filters.id_location && { id_location: filters.id_location }),
          },
        });
        setTotalRows(resp.count);
        setRows(
          resp.rows.map(row => ({
            id: row.id_driver,
            status: row.disabled,
            name: {
              name: row.name,
              last: row.last_name,
            },
            license: row.document_number,
            warehouse: row.id_location,
            options: { id: row.id_driver, disabled: row.disabled },
          })),
        );

        history.replace({
          pathname: match.url,
          search: `?page=${page}`,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [api, loading, history, match.url, filters],
  );

  const toggleDisabled = React.useCallback(
    async (id, disabled) => {
      async function callDisable() {
        try {
          loading.set();
          await api.put(`/transports/drivers/${id}`, { disabled: !disabled });
          loading.stop();
          tableRef.current.forceUpdate();
        } catch (error) {
          loading.stop();
        }
      }
      makeModal(ConfirmModal, {
        message: translations['drivers-t-confirm-m'],
        translations,
        onAccept: () => {
          callDisable();
        },
      });
    },
    [api, loading, translations],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: function col(val) {
        return <span>#{val}</span>;
      },
      status: function col(val) {
        return (
          <span className={`badge table-badge ${!val ? 'badge-success' : 'badge-warning'}`}>
            <FormattedMessage id={!val ? 'drivers-t-enabled' : 'drivers-t-disabled'} />
          </span>
        );
      },
      name: function col(val) {
        return (
          <span>
            {val.name} {val.last}
          </span>
        );
      },
      warehouse: function col(val) {
        return <span>#{val}</span>;
      },
      options: function col(val) {
        return (
          <React.Fragment>
            <button
              type="button"
              className={`btn btn-table ${val.disabled ? 'btn-danger' : 'btn-success'}`}
              title={val.disabled ? 'Enable' : 'Disable'}
              onClick={() => {
                toggleDisabled(val.id, val.disabled);
              }}
            >
              <FontAwesomeIcon icon={val.disabled ? faToggleOff : faToggleOn} />
            </button>
            <Link to={`/panel/warehouses/drivers/new/${val.id}`} className="btn btn-table btn-primary">
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: '0.5rem' }} />
              {translations['drivers-t-b-edit']}
            </Link>
          </React.Fragment>
        );
      },
    }),
    [toggleDisabled, translations],
  );

  return (
    <div>
      <DataTable
        ref={tableRef}
        locale={locale}
        columns={translations['drivers-t-columns']}
        rows={rows}
        totalRows={totalRows}
        tableClass="table table-striped table-sm"
        onPagination={getData}
        noRowsMessage={translations['drivers-t-norows']}
        initialPage={parseInt(query.get('page'), 10)}
        columnsConfig={columnsConfig}
        controls={
          <div className="row">
            <WafoFormSelect
              name="warehouse"
              defaultValue={translations['drivers-t-filters-warehouse']}
              customClass="col-12 col-md-6"
              options={warehouses.map(w => ({
                value: w.id_location,
                display: `#${w.id_location} - ${w.name}`,
              }))}
              value={filters.id_location}
              onChangeCallback={({ target: { value } }) => setFilters(prev => ({ ...prev, id_location: value }))}
            />
          </div>
        }
      />
    </div>
  );
};

TableDrivers.propTypes = {
  translations: PropTypes.any,
  locale: PropTypes.string,
};

export default withTranslation(TableDrivers, 'drivers');
