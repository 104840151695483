import React from 'react';
import PropTypes from 'prop-types';

const MaterialType = ({ translations, type }) => {
  switch (type) {
    case 1:
      return <span>{translations['materials-f-types'][0]}</span>;
    case 2:
      return <span>{translations['materials-f-types'][1]}</span>;
    case 3:
      return <span>{translations['materials-f-types'][2]}</span>;
    default:
      return <span>NA</span>;
  }
};

MaterialType.propTypes = {
  translations: PropTypes.any,
  type: PropTypes.number,
};

export default MaterialType;
