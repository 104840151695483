import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './breadcrumb.module.css';

const BreadcrumbRouterNav = ({ breadcrumbs: baseCrumbs, current }) => {
  const bcIndex = baseCrumbs.findIndex(x => x.path === current);
  const breadcrumbs = baseCrumbs.slice(0, (bcIndex + 1));

  return (
    <nav>
      <ol className={`breadcrumb ${styles['breadcrumb']}`}>
        {breadcrumbs.map((bc) => {
          if (current === bc.path) {
            return <li key={bc.path} className="breadcrumb-item active">{bc.display}</li>;
          }
          return (
            <li key={bc.path} className="breadcrumb-item">
              <Link className={`btn btn-link ${styles['button']}`} to={bc.path}>
                {bc.display}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

BreadcrumbRouterNav.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    display: PropTypes.string,
    path: PropTypes.string,
  })),
  current: PropTypes.string,
};

BreadcrumbRouterNav.defaultProps = {
  breadcrumbs: [],
  current: '',
};

export default BreadcrumbRouterNav;
