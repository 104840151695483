import React from 'react';
import useTranslation from '#hooks/redux/useTranslation';
import FormTransportCharge from '#components/forms/ships/formTransportCharge';
import FormShipCharge from '#components/forms/ships/formShipCharge';
import useApi from '#hooks/api/useApi';
import useLoading from '#context/hooks/useLoading';
import { DataTable } from '@wafo/table';
import useQuery from '#hooks/router/useQuery';
import { useHistory, useRouteMatch } from 'react-router-dom';
import MomentDate from '#components/UI/general/momentDate/momentDate';
import MaterialType from '#components/UI/extra/materialType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare as faCheckSolid, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './shipDetails.module.css';
import { makeModal } from '#components/modals/makeModal';
import ConfirmModal from '#components/modals/confirmModal/confirmModal';
import { WafoFormSelect } from '@wafo/forms';
import { FormattedMessage } from 'react-intl';

const ShipChargesTable = ({ ship }) => {
  const { translations, locale } = useTranslation();
  const api = useApi();
  const loading = useLoading();

  /** ************************************** */
  /** Warehouse */
  /** ************************************** */

  const [warehouse, setWarehouse] = React.useState({
    location_stores: [],
  });

  const getWarehouse = React.useCallback(async () => {
    loading.set();
    try {
      const resp = await api.get(`/locations/${ship.id_location}`);
      setWarehouse(resp);
    } catch (error) {
      console.error(error);
    }
    loading.stop();
  }, [api, ship.id_location, loading]);

  React.useEffect(() => {
    if (ship.id_location) {
      getWarehouse();
    }
  }, [getWarehouse, ship.id_location]);

  const userStores = React.useMemo(() => {
    // TODO: Filtrar también por material?
    const test = warehouse.location_stores.reduce((accumulator, store) => {
      const storeInfo = {
        id_store: store.id_store,
        store_name: store.name,
      };
      const userStores = store.user_stores.filter(x => x.id_user === ship.id_user).map(x => ({ ...x, ...storeInfo }));
      return [...accumulator, ...userStores];
    }, []);

    return test;
  }, [warehouse, ship.id_user]);

  /** ************************************** */
  /** Form */
  /** ************************************** */

  const [form, setForm] = React.useState(null);

  const [vehicles, setVehicles] = React.useState([]);
  const [drivers, setDrivers] = React.useState([]);

  React.useEffect(() => {
    async function getVehicles() {
      loading.set();
      try {
        const resp = await api.get('/transports/vehicles', {
          params: {
            limit: 999,
            id_location: ship.id_location,
          },
        });
        setVehicles(resp.rows);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }

    async function getDrivers() {
      loading.set();
      try {
        const resp = await api.get('/transports/drivers', {
          params: {
            limit: 999,
            id_location: ship.id_location,
          },
        });
        setDrivers(resp.rows);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }

    if (ship.id_location) {
      getVehicles();
      getDrivers();
    }
  }, [api, ship.id_location, loading]);

  const handleDelete = React.useCallback(
    async ids => {
      console.log(ids);
      const { id_ship_charge, id_transport_charge } = ids;

      makeModal(ConfirmModal, {
        message: 'Desea eliminar el registro de carga?',
        translations,
        onAccept: async () => {
          try {
            loading.set();
            if (id_ship_charge) {
              await api.delete(`/ships/charges/${id_ship_charge}`);
            } else if (id_transport_charge) {
              await api.delete(`/transports/charges/materials/${id_transport_charge}`);
            }
            tableRef.current.forceUpdate();
            loading.stop();
          } catch (error) {
            loading.stop();
          }
        },
      });
    },
    [api, loading, translations],
  );

  /** ************************************** */
  /** Filters */
  /** ************************************** */

  const [filters, setFilters] = React.useState(() => ({
    id_vehicle: '',
    id_driver: '',
  }));

  /** ************************************** */
  /** Table */
  /** ************************************** */

  const query = useQuery();
  const history = useHistory();
  const match = useRouteMatch();

  const tableRef = React.useRef();

  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [loads, setLoads] = React.useState([]);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const selectRow = React.useCallback(
    id => {
      const isSelected = selectedRows.findIndex(x => x.id_transport_charge === id);

      if (isSelected === -1) {
        const row = rows.find(x => x.id_transport_charge === id);
        if (row) {
          setSelectedRows(prev => [...prev, row]);
        }
      } else {
        setSelectedRows(prev => {
          const dummy = [...prev];
          dummy.splice(isSelected, 1);
          return dummy;
        });
      }
    },
    [rows, selectedRows],
  );

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      if (!ship.id_ship) return;
      loading.set();
      try {
        const transport = await api.get('/transports/charges', {
          params: {
            limit: size,
            page,
            id_ship: ship.id_ship,
            ...(search && { search }),
            ...(filters.id_vehicle && { id_vehicle: filters.id_vehicle }),
            ...(filters.id_driver && { id_driver: filters.id_driver }),
          },
        });

        setTotalRows(transport.count);
        setRows(transport.rows);

        history.replace({
          pathname: match.url,
          search: `?page=${page}`,
        });
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    },
    [ship.id_ship, loading, api, history, match.url, filters],
  );

  // Get ship loads.
  React.useEffect(() => {
    async function getLoads() {
      try {
        // Promise.all(rows.map(row => api.get('/ships/charges')));
        const charges = rows.map(row => row.charge_materials[0].id_charge_material);
        const resp = await api.get('/ships/charges', {
          params: {
            id_charge_material: charges.join(','),
            limit: rows.length,
            page: 1,
          },
        });
        setLoads(resp.rows);
      } catch (error) {
        console.error(error);
      }
    }
    if (rows.length > 0) getLoads();
  }, [rows, api]);

  const prepRows = React.useMemo(() => {
    return rows.reduce((accumulator, transport) => {
      const charge_material = transport.charge_materials[0];

      const select = selectedRows.findIndex(x => x.id_transport_charge === transport.id_transport_charge);
      const load = loads.find(x => x.id_charge_material === charge_material.id_charge_material);

      const row = {
        select: {
          id_transport_charge: transport.id_transport_charge,
          id_charge_material: load?.id_charge_material,
          id_ship_charge: load?.id_ship_charge,
          select: select !== -1,
        },
        material: {
          charge_material,
          ...(load && {
            ship_charge: {
              quantity: load?.quantity,
              belt: load?.id_belt,
              ship_store: load?.id_ship_storage,
            },
          }),
        },
        transport_charge: {
          bod: transport.bod,
          ticket: transport.ticket,
          transport_vehicle: transport.transport_vehicle,
          transport_driver: transport.transport_driver,
        },
        transport_dates: {
          placement_date_start: transport.placement_date_start,
          placement_date_end: transport.placement_date_end,
          charge_date_start: transport.charge_date_start,
          charge_date_end: transport.charge_date_end,
          departure_date: transport.departure_date,
          updatedAt: transport.updatedAt,
        },
        load_dates: {
          placement_date_start: load?.placement_date_start,
          placement_date_end: load?.placement_date_end,
          charge_date_start: load?.charge_date_start,
          charge_date_end: load?.charge_date_end,
          departure_date: load?.departure_date,
          updatedAt: load?.updatedAt,
        },
        options: {
          id_transport_charge: transport.id_transport_charge,
          id_charge_material: load?.id_charge_material,
          id_ship_charge: load?.id_ship_charge,
        },
      };

      return [...accumulator, row];
    }, []);
  }, [loads, rows, selectedRows]);

  const columnsConfig = React.useMemo(
    () => ({
      select: {
        render: val => {
          return val.id_charge_material ? (
            <span className={styles['btn']}>
              <FontAwesomeIcon icon={faCheckSolid} color="#28a745" />
            </span>
          ) : (
            <button className={`btn btn-table ${styles['btn']}`} onClick={() => selectRow(val.id_transport_charge)}>
              <FontAwesomeIcon icon={val.select ? faCheckSquare : faSquare} />
            </button>
          );
        },
        style: { verticalAlign: 'middle' },
      },
      material: val => {
        const {
          id_material,
          name,
          unit_type,
          material_type,
          quantity: material_quantity,
        } = val.charge_material.material;
        const material = `#${id_material} - ${name}`;

        const { original_quantity } = val.charge_material;
        // current_quantity se utilizaba pero se queda en 0 al subir la carga al buque.

        const { belt, ship_store } = val.ship_charge || {};

        return (
          <table className={styles['table-table']}>
            <tbody>
              <tr>
                <th>Cantidad</th>
                <td>
                  {`${original_quantity * parseFloat(material_quantity)}${unit_type}`} (
                  <MaterialType translations={translations} type={material_type} />)
                </td>
              </tr>
              <tr>
                <th>Material</th>
                <td>{material}</td>
              </tr>
              {val.ship_charge && (
                <React.Fragment>
                  <tr>
                    <th>Banda</th>
                    <td>#{belt}</td>
                  </tr>
                  <tr>
                    <th>Bodega</th>
                    <td>#{ship_store}</td>
                  </tr>
                </React.Fragment>
              )}
            </tbody>
          </table>
        );
      },
      transport_charge: val => {
        const { id_vehicle, brand, model, year, plates } = val.transport_vehicle;
        const vehicle = `#${id_vehicle} [${plates}] - ${brand} ${model} ${year}`;

        const { id_driver, name, last_name } = val.transport_driver;
        const driver = `#${id_driver} - ${name} ${last_name}`;

        return (
          <table className={styles['table-table']}>
            <tbody>
              <tr>
                <th>Ticket</th>
                <td>{val.ticket || 'Not available'}</td>
              </tr>
              <tr>
                <th>BOD</th>
                <td>{val.bod || 'Not available'}</td>
              </tr>
              <tr>
                <th>Vehículo</th>
                <td>{vehicle}</td>
              </tr>
              <tr>
                <th>Chofer</th>
                <td>{driver}</td>
              </tr>
            </tbody>
          </table>
        );
      },
      transport_dates: val => (
        <table className={styles['table-table']}>
          <tbody>
            <tr>
              <th>Colocación</th>
              <td>
                <MomentDate title="Start" date={val.placement_date_start} />
              </td>
              <td>
                <MomentDate title="End" date={val.placement_date_end} />
              </td>
            </tr>
            <tr>
              <th>Carga</th>
              <td>
                <MomentDate title="Start" date={val.charge_date_start} />
              </td>
              <td>
                <MomentDate title="End" date={val.charge_date_end} />
              </td>
            </tr>
            <tr>
              <th>Partida</th>
              <td>
                <MomentDate date={val.departure_date} />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      ),
      load_dates: val => (
        <table className={styles['table-table']}>
          <tbody>
            <tr>
              <th>Colocación</th>
              <td>
                <MomentDate title="Start" date={val.placement_date_start} />
              </td>
              <td>
                <MomentDate title="End" date={val.placement_date_end} />
              </td>
            </tr>
            <tr>
              <th>Carga</th>
              <td>
                <MomentDate title="Start" date={val.charge_date_start} />
              </td>
              <td>
                <MomentDate title="End" date={val.charge_date_end} />
              </td>
            </tr>
            <tr>
              <th>Partida</th>
              <td>
                <MomentDate date={val.departure_date} />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      ),
      options: val => (
        <React.Fragment>
          <button
            type="button"
            className="btn btn-table btn-danger with-icon"
            onClick={() => handleDelete(val)}
            // disabled={!val.id_ship_charge}
          >
            <FontAwesomeIcon icon={faTimes} />
            <span>Eliminar</span>
          </button>
        </React.Fragment>
      ),
    }),
    [translations, selectRow, handleDelete],
  );

  /** ************************************** */
  /** Render */
  /** ************************************** */

  return (
    <div className="card">
      <div className="card-body">
        <div className={styles['head']}>
          <h5 className="card-subtitle">
            <FormattedMessage id="ships-t-load-title" />
          </h5>
          <div>
            <button type="button" className="btn btn-link" onClick={() => setForm('prepare')} disabled={form !== null}>
              <FormattedMessage id="ships-t-load-b-transport" />
            </button>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => setForm('load')}
              disabled={form !== null || selectedRows.length === 0}
            >
              <FormattedMessage id="ships-t-load-b-load" />
            </button>
          </div>
        </div>
      </div>
      {form !== null && (
        <div className={styles['forms']}>
          {form === 'prepare' && (
            <FormTransportCharge
              shipId={ship.id_ship}
              userStores={userStores}
              vehicles={vehicles}
              drivers={drivers}
              onCancel={() => setForm(null)}
              onSubmitSuccess={() => {
                setForm(null);
                getWarehouse();
                tableRef.current.forceUpdate();
              }}
            />
          )}
          {form === 'load' && (
            <FormShipCharge
              charges={selectedRows}
              stores={ship.shipment_storages}
              locationId={ship.id_location}
              onCancel={() => {
                setForm(null);
                setSelectedRows([]);
              }}
              onSubmitSuccess={() => {
                setForm(null);
                getWarehouse();
                setSelectedRows([]);
                tableRef.current.forceUpdate();
              }}
            />
          )}
        </div>
      )}
      <div className="card-body">
        <DataTable
          ref={tableRef}
          locale={locale}
          columns={translations['ships-t-load-columns']}
          rows={prepRows}
          totalRows={totalRows}
          tableClass={`table table-striped ${styles['table']}`}
          tableWrapperClass="table-wrapper table-responsive"
          onPagination={getData}
          noRowsMessage="No"
          initialPage={parseInt(query.get('page'), 10)}
          columnsConfig={columnsConfig}
          controls={
            <div className="row">
              <WafoFormSelect
                name="id_vehicle"
                defaultValue="Filtrar por vehículo"
                customClass="col-12 col-md-4"
                options={vehicles.map(v => ({
                  value: v.id_vehicle,
                  display: `#${v.id_vehicle} - ${v.brand} ${v.model} ${v.year}`,
                }))}
                value={filters.id_vehicle}
                onChangeCallback={({ target: { value } }) => setFilters(prev => ({ ...prev, id_vehicle: value }))}
              />
              <WafoFormSelect
                name="id_driver"
                defaultValue="Filtrar por conductor"
                customClass="col-12 col-md-4"
                options={drivers.map(d => ({
                  value: d.id_driver,
                  display: `#${d.id_driver} - ${d.name} ${d.last_name}`,
                }))}
                value={filters.id_driver}
                onChangeCallback={({ target: { value } }) => setFilters(prev => ({ ...prev, id_driver: value }))}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ShipChargesTable;
