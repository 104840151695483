import React from 'react';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import useTranslation from '#hooks/redux/useTranslation';
import { WafoForm, WafoFormSelect, WafoFormAutocomplete } from '@wafo/forms';
import { FormattedMessage } from 'react-intl';
import DatetimePicker from '#components/forms/customForms/datetimePicker/datetimePicker';
import useLoading from '#context/hooks/useLoading';
import useApi from '#hooks/api/useApi';
import moment from 'moment';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/free-solid-svg-icons';

const GenerateReports = () => {
  const { translations, locale } = useTranslation();
  const loading = useLoading();
  const api = useApi();

  /** *************************************** */
  /** Filtros */
  /** *************************************** */

  const [type, setType] = React.useState(null);

  const [packages, setPackages] = React.useState([]);

  const [locations, setLocations] = React.useState([]);

  const [ships, setShips] = React.useState([]);

  React.useEffect(() => {
    async function getPackages() {
      loading.set();
      try {
        const resp = await api.get('/packages', {
          params: {
            limit: 999,
            order: '-id_package',
          },
        });
        setPackages(resp.rows);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }

    async function getLocations() {
      loading.set();
      try {
        const resp = await api.get('/locations', {
          params: {
            limit: 999,
            order: '-id_location',
          },
        });
        setLocations(resp.rows);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }

    async function getShips() {
      loading.set();
      try {
        const resp = await api.get('/ships', {
          params: {
            limit: 999,
            order: '-id_ship',
          },
        });
        setShips(resp.rows);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }

    getPackages();
    getLocations();
    getShips();
  }, [loading, api]);

  /** *************************************** */
  /** Form */
  /** *************************************** */

  const [key, setKey] = React.useState(0);
  const resetForm = () => {
    setKey(Math.random());
    setType(null);
  };

  const handleSubmit = async (form, values) => {
    if (form.valid) {
      loading.set();
      try {
        let resp = null;
        let filename = null;

        const from = moment(values.from).toISOString();
        const to = moment(values.to).toISOString();

        switch (values.type) {
          case 'package-cars':
            filename = 'equipments_report';
            resp = await api.get(`/packages/reports/cars/${values.packageid.id_package}`, {
              responseType: 'blob',
              params: { from, to },
            });
            break;
          case 'package-shipments':
            filename = 'shipment_report';
            resp = await api.get('/packages/reports/shipments', {
              responseType: 'blob',
              params: { from, to },
            });
            break;
          case 'package-customs':
            filename = 'customs_report';
            resp = await api.get(`/packages/reports/customs/${values.idLocation.id_location}`, {
              responseType: 'blob',
              params: { from, to },
            });
            break;
          case 'scorecard':
            filename = 'scorecard';
            resp = await api.get('/packages/reports/scorecard', {
              responseType: 'blob',
              params: { from, to },
            });
            break;
          case 'ship-charges':
            filename = 'ship_report';
            resp = await api.get(`/packages/reports/ship/${values.idShip.id_ship}/charges`, {
              responseType: 'blob',
              params: { from, to },
            });
            break;
          default:
            break;
        }

        FileSaver.saveAs(resp.data, filename);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
  };

  return (
    <div>
      <CardHeader title={translations['reports-title']}>
        <button type="button" className="btn btn-s with-icon" onClick={resetForm}>
          <FontAwesomeIcon icon={faEraser} />
          <FormattedMessage id="reports-f-b-clear" />
        </button>
      </CardHeader>
      <div className="card card-panel">
        <div className="card-body">
          <WafoForm key={key} formId="form-reports" locale={locale} onSubmit={handleSubmit} values={{}}>
            <WafoFormSelect
              name="type"
              label={translations['reports-f-label-type']}
              defaultValue={translations['reports-f-placeholder-type']}
              customClass="col-12 col-md-4"
              options={translations['reports-f-types']}
              validations={{ required: true }}
              onChangeCallback={({ target: { value } }) => setType(value)}
            />

            <div className="col-12">
              <p className="form-p-divider">
                <FormattedMessage id="reports-f-divider-1" />
              </p>
            </div>

            <WafoFormAutocomplete
              name="packageid"
              customClass="col-12 col-md-4"
              label={translations['reports-f-label-package']}
              placeholder={translations['reports-f-placeholder-package']}
              items={packages}
              filterItems={(items, query) =>
                items.filter(
                  i =>
                    i.train_id.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                    i.waybill.toLowerCase().indexOf(query.toLowerCase()) !== -1,
                )
              }
              renderInput={item => item.train_id}
              renderItem={item => (
                <p style={{ margin: '0', padding: '0.5rem', lineHeight: 1, borderBottom: '1px solid #f5f5f5' }}>
                  <span>Train: {item.train_id}</span>
                  <br />
                  <span style={{ color: '#777', fontSize: '0.85em' }}>Waybill: {item.waybill}</span>
                </p>
              )}
              extraProps={{
                autoComplete: 'off',
                disabled: !['package-cars'].includes(type),
              }}
              validations={{
                required: ['package-cars'].includes(type),
              }}
              handleChange
            />

            <WafoFormAutocomplete
              name="idLocation"
              customClass="col-12 col-md-4"
              label={translations['reports-f-label-location']}
              placeholder={translations['reports-f-placeholder-location']}
              items={locations}
              filterItems={(items, query) =>
                items.filter(i => i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
              }
              renderInput={item => item.name}
              renderItem={item => (
                <p style={{ margin: '0', padding: '0.5rem', lineHeight: 1, borderBottom: '1px solid #f5f5f5' }}>
                  <span>
                    #{item.id_location} - {item.name}
                  </span>
                </p>
              )}
              extraProps={{
                autoComplete: 'off',
                disabled: !['package-customs'].includes(type),
              }}
              validations={{ required: ['package-customs'].includes(type) }}
              handleChange
            />

            <WafoFormAutocomplete
              name="idShip"
              customClass="col-12 col-md-4"
              label={translations['reports-f-label-ship']}
              placeholder={translations['reports-f-placeholder-ship']}
              items={ships}
              filterItems={(items, query) =>
                items.filter(
                  i =>
                    i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                    i.ship_id.toLowerCase().indexOf(query.toLowerCase()) !== -1,
                )
              }
              renderInput={item => item.name}
              renderItem={item => (
                <p style={{ margin: '0', padding: '0.5rem', lineHeight: 1, borderBottom: '1px solid #f5f5f5' }}>
                  <span>Ship: {item.name}</span>
                  <br />
                  <span style={{ color: '#777', fontSize: '0.85em' }}>ID: {item.ship_id}</span>
                </p>
              )}
              extraProps={{
                autoComplete: 'off',
                disabled: !['ship-charges'].includes(type),
              }}
              validations={{ required: ['ship-charges'].includes(type) }}
              handleChange
            />

            <DatetimePicker
              name="from"
              customClass="col-12 col-md-4"
              label={translations['reports-f-label-from']}
              disabled={
                !['package-cars', 'package-shipments', 'package-customs', 'scorecard', 'ship-charges'].includes(type)
              }
              validations={{
                required: [
                  'package-cars',
                  'package-shipments',
                  'package-customs',
                  'scorecard',
                  'ship-charges',
                ].includes(type),
              }}
              handleChange
            />

            <DatetimePicker
              name="to"
              customClass="col-12 col-md-4"
              label={translations['reports-f-label-to']}
              disabled={
                !['package-cars', 'package-shipments', 'package-customs', 'scorecard', 'ship-charges'].includes(type)
              }
              validations={{
                required: [
                  'package-cars',
                  'package-shipments',
                  'package-customs',
                  'scorecard',
                  'ship-charges',
                ].includes(type),
              }}
              handleChange
            />

            <div className="col-12 text-center">
              <div className="divider" />
              <button type="submit" form="form-reports" className="btn btn-s">
                <FormattedMessage id="reports-f-b-submit" />
              </button>
            </div>
          </WafoForm>
        </div>
      </div>
    </div>
  );
};

export default GenerateReports;
