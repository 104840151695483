import React from 'react';
import PropTypes from 'prop-types';
import styles from './storeContainer.module.css';

const ShipContainer = ({ total, stowage, used, backgroundColor, stowageColor, usedColor, unit, desc, index }) => {
  const stowagePercent = total === 0 ? 100 : (stowage * 100) / total;
  const percent = stowage === 0 ? 100 : (used * 100) / stowage;

  return (
    <div className={styles['container-wrapper']} style={{ backgroundColor }}>
      <div
        className={styles['container-bar']}
        style={{ height: `${stowagePercent}%`, backgroundColor: stowageColor }}
      />
      <div className={styles['container-bar']} style={{ height: `${percent}%`, backgroundColor: usedColor }} />
      <div className={styles['container-text']}>
        <span>{percent.toFixed(2)}%</span>
        {!desc && (
          <span>
            {used.toFixed(3)} {unit ? `${unit}.` : ''}
          </span>
        )}
        <span>{desc}</span>
        <span>Bodega: #{index}</span>
      </div>
    </div>
  );
};

ShipContainer.propTypes = {
  total: PropTypes.number,
  stowage: PropTypes.number,
  used: PropTypes.number,
  backgroundColor: PropTypes.string,
  stowageColor: PropTypes.string,
  usedColor: PropTypes.string,
  unit: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

ShipContainer.defaultProps = {
  total: 100,
  stowage: 90,
  used: 35,
  backgroundColor: '#f5f5f5',
  stowageColor: '#0FA0FA50',
  usedColor: '#80E296',
  unit: '',
  desc: '',
};

export default ShipContainer;
