import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import FormUser from '#components/forms/users/formUser';

const NewUser = () => {
  const history = useHistory();
  const { id } = useParams();

  return (
    <div>
      <CardHeader
        title={<FormattedMessage id={id ? 'users-f-e-title' : 'users-f-title'} values={{ id }} />}
        showBack
        onBack={() => history.goBack()}
      />
      <div className="card card-panel">
        <div className="card-body">
          <h5 className="card-subtitle">
            <FormattedMessage id={id ? 'users-f-e-subtitle' : 'users-f-subtitle'} />
          </h5>
          <p>
            <FormattedMessage id="users-f-description" />
          </p>
          <FormUser onSubmitSuccess={() => history.goBack()} />
        </div>
      </div>
    </div>
  );
};

NewUser.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
};

export default withTranslation(NewUser, 'users');
