import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LocationType from '#components/UI/extra/locationType';
import withTranslation from '#components/HOCs/withTranslation';
import InfoColumn from '#components/UI/general/infoColumn/infoColumn';
import { FormattedMessage } from 'react-intl';

const EquipmentInfo = ({ translations, equipment }) => {
  const currentLocation = equipment.equipment_locations.find(l => l.id_location === equipment.id_location);

  return (
    <div>
      <div className="row">
        <InfoColumn title={translations['equipments-m-details-info-id']} info={equipment.equipment_id} />
        <InfoColumn title={translations['equipments-m-details-info-bl']} info={equipment.bl_number} />
        <InfoColumn title={translations['equipments-m-details-info-customs']} info={equipment.customs_invoice} />
        <InfoColumn
          title={translations['equipments-m-details-info-material']}
          info={
            <div style={{ lineHeight: 1 }}>
              <span>
                {(equipment.quantity * equipment.material.quantity).toFixed(3)} {equipment.material.unit_type}
              </span>
              <br />
              <span style={{ fontSize: '0.85em' }}>
                #{equipment.material.id_material} {equipment.material.name} -{' '}
                {translations['materials-f-types'][equipment.material.material_type]}
              </span>
            </div>
          }
        />
        <InfoColumn
          title={translations['equipments-m-details-info-location']}
          info={
            <div style={{ lineHeight: 1 }}>
              <span>{currentLocation ? currentLocation.location.name : 'NA'}</span>
              {currentLocation && (
                <>
                  <br />
                  <span>
                    <FormattedMessage id="locations-f-label-type" />
                    <span style={{ marginRight: '.25rem' }}>:</span>
                    <span style={{ fontSize: '0.85em' }}>
                      <LocationType type={currentLocation.location.type} />
                    </span>
                  </span>
                </>
              )}
            </div>
          }
        />
        <InfoColumn
          title={translations['equipments-m-details-info-date']}
          info={
            <div style={{ lineHeight: 1 }}>
              <span>{moment(equipment.updatedAt).format('DD MMMM YYYY HH:mm')}</span>
              <br />
              <span style={{ fontSize: '0.8em' }} title="created">
                ({moment(equipment.createdAt).format('DD MMMM YYYY HH:mm')})
              </span>
            </div>
          }
        />
      </div>
    </div>
  );
};

EquipmentInfo.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  equipment: PropTypes.any,
};

export default withTranslation(EquipmentInfo, ['equipments', 'locations', 'materials']);
