import React from 'react';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrain, faList, faFileExcel, faCheck } from '@fortawesome/free-solid-svg-icons';
import { DataTable } from '@wafo/table';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import useApi from '#hooks/api/useApi';
import useQuery from '#hooks/router/useQuery';
import useTranslation from '#hooks/redux/useTranslation';
import useLoading from '#context/hooks/useLoading';
import moment from 'moment';

const TableTrains = () => {
  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const { translations, locale } = useTranslation();
  const loading = useLoading();

  const api = useApi();

  const query = useQuery();
  const history = useHistory();
  const match = useRouteMatch();

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await api.get('/packages', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
            order: '-external_id',
          },
        });
        setRows(
          resp.rows.map(row => ({
            ended: row.ended,
            id: { id_package: row.id_package, external_id: row.external_id, train_id: row.train_id },
            waybill: row.waybill,
            equipments: row.total_equipments,
            current: row.current_location,
            updated: { created: row.createdAt, updated: row.updatedAt },
            options: { id_package: row.id_package, waybill: row.waybill, ended: row.ended },
          })),
        );
        setTotalRows(resp.count);
        // history.replace(`${page}`);
        history.replace({
          pathname: match.url,
          search: `?page=${page}`,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [api, history, loading, match.url],
  );

  const columnsConfig = React.useMemo(
    () => ({
      ended: {
        render: function col(val) {
          const status = val ? 'finished' : 'ongoing';
          return <span className={`table-status ${status}`} />;
        },
        style: { verticalAlign: 'middle' },
      },
      id: {
        render: function col(val) {
          const col = (
            <React.Fragment>
              <span>#{val.external_id ? val.external_id : val.id_package}</span>
              <br />
              <span style={{ fontSize: '0.85em' }}> {val.train_id ? val.train_id : '[Train]'}</span>
            </React.Fragment>
          );
          return col;
        },
        style: { fontSize: '0.9em', lineHeight: 1.2 },
      },
      current: {
        render: function col(val) {
          if (!val) {
            return <span>NA</span>;
          }
          return <span>{val.name}</span>;
        },
        style: { lineHeight: 1.2 },
      },
      updated: {
        render: val => {
          const col = (
            <React.Fragment>
              <span title="updated">{moment(val.updated).format('DD MMMM YYYY HH:mm')}</span>
              <br />
              <span style={{ fontSize: '0.8em' }} title="created">
                ({moment(val.created).format('DD MMMM YYYY HH:mm')})
              </span>
            </React.Fragment>
          );
          return col;
        },
        style: { fontSize: '0.9em', lineHeight: 1.2 },
      },
      options: val => {
        const col = (
          <React.Fragment>
            <Link className="btn btn-table btn-s" to={`/panel/trains/details/${val.id_package}`}>
              <FontAwesomeIcon icon={faList} style={{ marginRight: '0.5rem' }} />
              <FormattedMessage id="trains-t-b-details" />
            </Link>
            <button
              disabled={val.ended}
              type="button"
              className="btn btn-table btn-success"
              title="Finish"
              onClick={f => f}
              // onClick={() => confirmEnded(val.id_package)}
            >
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </React.Fragment>
        );
        return col;
      },
    }),
    [],
  );

  return (
    <div>
      <CardHeader title={translations['trains-t-title']}>
        <Link to={`/panel/trains/new`} className="btn btn-primary with-icon">
          <FontAwesomeIcon icon={faTrain} />
          <FormattedMessage id="trains-t-b-new" />
        </Link>
        <Link to={`/panel/trains/excel`} className="btn btn-primary with-icon">
          <FontAwesomeIcon icon={faFileExcel} />
          <FormattedMessage id="trains-t-b-newexcel" />
        </Link>
      </CardHeader>
      <div className="card card-panel">
        <div className="card-body">
          <DataTable
            locale={locale}
            columns={translations['trains-t-columns']}
            rows={rows}
            totalRows={totalRows}
            tableClass="table table-striped table-sm"
            onPagination={getData}
            noRowsMessage={translations['trains-t-norows']}
            initialPage={parseInt(query.get('page'), 10)}
            columnsConfig={columnsConfig}
          />
        </div>
      </div>
    </div>
  );
};

export default TableTrains;
