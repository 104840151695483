import React from 'react';
import PropTypes from 'prop-types';
import styles from './timePicker.module.css';

const TimePicker = ({ name, label, customClass, handleInputChange, value, valid, touched, errors, disabled }) => {
  React.useEffect(() => {
    if (typeof value === 'number') {
      let hours = Math.floor(value / 3600);
      if (hours < 10) {
        hours = `0${hours}`;
      }
      let minutes = (value % 3600) / 60;
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      handleInputChange({
        target: {
          name,
          value: `${hours}:${minutes}:00`,
        },
        type: 'change',
      });
    }
  }, [value, handleInputChange, name]);

  const time = React.useMemo(() => {
    if (typeof value === 'string') {
      const [hours, minutes] = value.split(':');
      return { hours: hours || '', minutes: minutes || '' };
    }
    return '';
  }, [value]);

  const handleOnChange = event => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    const {
      target: { name: inputName, value: inputValue },
    } = event;
    const [hours, minutes] = value.split(':');

    switch (inputName) {
      case 'picker-hours': {
        let h = parseInt(inputValue, 10);
        h = h > 23 ? 23 : h;
        h = h < 10 ? `0${h}` : h.toString();
        if (isNaN(h)) {
          h = '';
        }
        handleInputChange({
          target: {
            name,
            value: `${h}:${minutes ? minutes : '00'}:00`,
          },
          type: 'change',
        });
        break;
      }
      case 'picker-minutes': {
        let m = parseInt(inputValue, 10);
        m = m > 59 ? 59 : m;
        m = m < 10 ? `0${m}` : m.toString();
        if (isNaN(m)) {
          m = '';
        }
        handleInputChange({
          target: {
            name,
            value: `${hours ? hours : '00'}:${m}:00`,
          },
          type: 'change',
        });
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={`wafo-wrapper ${customClass}`}>
      <div className="form-group wafo-input">
        {label && <label htmlFor="picker-time">{label}</label>}
        <div className={styles['wrapper']}>
          <input
            type="number"
            name="picker-hours"
            className={`form-control no-arrows ${styles['input-hours']}`}
            placeholder="HH"
            min="0"
            max="23"
            autoComplete="off"
            value={time.hours}
            onChange={handleOnChange}
            onClick={event => event.target.select()}
            disabled={disabled}
            ignoreinput="true"
          />
          <span className={`${styles['input-colons']} ${disabled && styles['disabled']}`}>:</span>
          <input
            type="number"
            name="picker-minutes"
            className={`form-control no-arrows ${styles['input-minutes']}`}
            placeholder="MM"
            min="0"
            max="59"
            autoComplete="off"
            value={time.minutes}
            onChange={handleOnChange}
            onClick={event => event.target.select()}
            disabled={disabled}
            ignoreinput="true"
          />
        </div>
      </div>
      {!valid && touched && (
        <ul className="errors">
          {errors.map(error => (
            <li key={error.error}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

TimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  customClass: PropTypes.string,
  handleInputChange: PropTypes.func,
  value: PropTypes.any,
  valid: PropTypes.bool,
  touched: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.any),
  disabled: PropTypes.bool,
};

TimePicker.defaultProps = {
  label: '',
  customClass: '',
  handleInputChange: f => f,
  value: '',
  valid: false,
  touched: false,
  errors: [],
  disabled: false,
};

export default TimePicker;
