import React from 'react';
import { useSegroveContext } from '#context/segrove-context';

const useLocale = () => {
  const { state, dispatch } = useSegroveContext();

  const locale = React.useMemo(
    () => ({
      set: payload => dispatch({ type: 'LOCALE_CHANGE', payload }),
      toggle: () => {
        const newLocale = state.locale === 'en' ? 'es' : 'en';
        dispatch({ type: 'LOCALE_CHANGE', payload: newLocale });
      },
      state: state.locale,
    }),
    [dispatch, state],
  );

  return locale;
};

export default useLocale;
