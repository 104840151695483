import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';

const FormPassword = ({ onAfterSubmit }) => {
  const { locale, messages: translations } = useIntl();
  const { token } = useParams();

  const api = useApi();
  const alerts = useAlerts();

  const handleFormSubmit = async (form, values) => {
    if (!form.valid) {
      return;
    }
    try {
      await api.post('/auth/forgot/newpassword', {
        password: values.password,
        token: token,
      });
      onAfterSubmit('/auth/login');
      alerts.success({
        title: translations['auth-f-password-alert-title-success'],
        message: translations['auth-f-password-alert-msg-success'],
        timer: 3000,
      });
    } catch (error) {
      // handle
    }
  };

  React.useEffect(() => {
    async function checkToken() {
      try {
        await api.get('/auth/forgot', {
          params: {
            token: token,
          },
        });
      } catch (error) {
        console.error(error);
        onAfterSubmit('/auth/login');
      }
    }
    checkToken();
  }, [api, token, onAfterSubmit]);

  const checkPassword = value => value.value === value.tracking;

  return (
    <div>
      <WafoForm formId="password-form" locale={locale} onSubmit={handleFormSubmit}>
        <WafoFormInput
          type="password"
          name="password"
          label={translations['auth-f-password-label-pass']}
          placeholder={translations['auth-f-password-placeholder-pass']}
          customClass="col-12"
          validations={{ required: true }}
        />

        <WafoFormInput
          type="password"
          name="password2"
          label={translations['auth-f-password-label-pass2']}
          placeholder={translations['auth-f-password-placeholder-pass2']}
          customClass="col-12"
          validations={{
            track: 'password',
            validationFunction: {
              value: checkPassword,
              message: 'La contraseña no coincide.',
            },
          }}
        />
      </WafoForm>

      <button
        type="submit"
        form="password-form"
        className="btn btn-s"
        style={{ width: '100%', marginBottom: '1rem', marginTop: '1rem' }}
      >
        {translations['auth-f-password-button']}
      </button>
    </div>
  );
};

FormPassword.propTypes = {
  onAfterSubmit: PropTypes.func,
};

export default FormPassword;
