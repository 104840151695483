import React from 'react';
import PropTypes from 'prop-types';

const IncidentType = ({ translations, type }) => {
  switch (type) {
    case 1:
      return <span>{translations['incidents-types'][0]}</span>;
    case 2:
      return <span>{translations['incidents-types'][1]}</span>;
    case 3:
      return <span>{translations['incidents-types'][2]}</span>;
    case 4:
      return <span>{translations['incidents-types'][3]}</span>;
    case 5:
      return <span>{translations['incidents-types'][4]}</span>;
    case 6:
      return <span>{translations['incidents-types'][5]}</span>;
    default:
      return <span>NA</span>;
  }
};

IncidentType.propTypes = {
  translations: PropTypes.any,
  type: PropTypes.number,
};

export default IncidentType;
