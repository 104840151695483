import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from '#components/modals/baseModal';
import withTranslation from '#components/HOCs/withTranslation';
import FormIncident from '#components/forms/trains/formIncident';
import { FormattedMessage } from 'react-intl';

const IncidentsModal = ({ translations, equipments, onClose }) => {
  const rows = React.useMemo(() => {
    return equipments.map(e => ({
      id: e.equipment_id,
      currentLocation: { ...e.equipment_location, location: e.location },
      incidents: e.total_incidents,
    }));
  }, [equipments]);

  return (
    <BaseModal
      title={`${translations['equipments-m-incidents-i-title']}`}
      modalStyle={{ width: '48em', maxHeight: '80vh', minHeight: '40vh' }}
      closeModal={onClose}
    >
      <>
        <div className="row">
          <div className="col-12">
            <p>
              <FormattedMessage id="equipments-m-incidents-m-desc" />
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <h6>
              <strong>
                <FormattedMessage id="incidents-m-multi-subtitle" />
              </strong>
            </h6>
            <div className="modal-table-wrapper">
              <table className="table table-sm table-striped">
                <thead>
                  <tr>
                    {translations['incidents-m-multi-columns'].map((column, index) => (
                      <th key={index}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rows.map(row => {
                    const style = !row.currentLocation.location ? { opacity: '0.5' } : {};
                    return (
                      <tr key={row.id} style={style}>
                        <td>{row.id}</td>
                        <td>{row.currentLocation.location ? row.currentLocation.location.name : 'NA'}</td>
                        <td>{row.incidents}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <p className="modal-warning-text">
              <FormattedMessage id="incidents-m-multi-warning" />
            </p>
          </div>
          <div className="col-12 col-md-6">
            <FormIncident
              equipments={equipments}
              equipmentLocation={equipments[0].equipment_location.id_el}
              onSubmitSuccess={onClose}
            />
          </div>
          <div className="col-12 text-center">
            <button type="submit" form="form-incident" className="btn btn-s btn-submit">
              <FormattedMessage id="form-submit" />
            </button>
          </div>
        </div>
      </>
    </BaseModal>
  );
};

IncidentsModal.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  equipments: PropTypes.array,
};

IncidentsModal.defaultProps = {
  equipmnets: [],
};

export default withTranslation(IncidentsModal, ['equipments', 'incidents', 'locations']);
