import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { LocalTable } from '@wafo/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import withTranslation from '#components/HOCs/withTranslation';
import { useHistory } from 'react-router-dom';
import useApi from '#hooks/api/useApi';
import useLoading from '#context/hooks/useLoading';

const SelectWaybill = ({ locale, translations, waybills, equipment, handleWaybill }) => {
  const history = useHistory();
  const api = useApi();
  const loading = useLoading();

  React.useEffect(() => {
    if (!waybills.length) {
      history.replace('/panel/trains/new');
    }
  }, [waybills, history]);

  async function getWaybill(waybill) {
    try {
      loading.set();
      const resp = await api.get(`/equipments/up/${equipment}/detail/${waybill}`);
      handleWaybill(resp);
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  }

  const rows = waybills.map(w => ({
    rowData: {
      number: w.waybillNumber,
      shipper: w.shipperName,
      shipperLocation: `${w.shipperLocation.city}, ${w.shipperLocation.state}`,
      consignee: w.consigneeName,
      consigneeLocation: `${w.consigneeLocation.city}, ${w.consigneeLocation.state}`,
      comodity: w.commodity,
      date: w.waybillDate,
      options: w.waybillNumber,
    },
    rowStyle: {
      fontSize: '0.85em',
    },
  }));

  const columnsConfig = {
    date: { style: { whiteSpace: 'nowrap' } },
    options: {
      render: function col(val) {
        return (
          <button type="button" className="btn btn-primary btn-sm btn-table with-icon" onClick={() => getWaybill(val)}>
            <FontAwesomeIcon icon={faCheck} />
            <FormattedMessage id="trains-new-waybill-t-b-select" />
          </button>
        );
      },
      style: { whiteSpace: 'nowrap' },
    },
  };

  return (
    <div>
      <h5 className="card-subtitle">
        <FormattedMessage id="trains-new-waybills-sub" />
      </h5>
      <p className="description">
        <FormattedMessage id="trains-new-waybill-desc" />
      </p>
      <LocalTable
        columns={translations['trains-new-waybill-t-columns']}
        rows={rows}
        locale={locale}
        tableClass="table table-striped table-sm"
        tableWrapperClass="table-noheader"
        columnsConfig={columnsConfig}
      />
    </div>
  );
};

SelectWaybill.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  waybills: PropTypes.array,
  equipment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleWaybill: PropTypes.func,
};

export default withTranslation(SelectWaybill, 'trains');
