import React from 'react';
import useLoading from '#context/hooks/useLoading';
import useApi from '#hooks/api/useApi';
import InfoColumn from '#components/UI/general/infoColumn/infoColumn';
import useTranslation from '#hooks/redux/useTranslation';
import moment from 'moment';
import UserType from '#components/UI/extra/userType';

const UserInfo = () => {
  const loading = useLoading();
  const api = useApi();
  const { translations } = useTranslation();

  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    async function getUser() {
      loading.set();
      try {
        const resp = await api.get('/auth');
        setUser(resp);
      } catch (error) {
        console.error();
      }
      loading.stop();
    }

    getUser();
  }, [api, loading]);

  return (
    <div className="row">
      <InfoColumn title={translations['profile-i-type']} info={<UserType type={user.type} />} />
      <InfoColumn title={translations['profile-i-email']} info={user.email} />
      <InfoColumn title={translations['profile-i-name']} info={`${user.name} ${user.last_name}`} />
      <InfoColumn title={translations['profile-i-tz']} info={user.timezone} />
      <InfoColumn title={translations['profile-i-updated']} info={moment(user.updatedAt).format('DD/MM/YYYY HH:mm')} />
    </div>
  );
};

export default UserInfo;
