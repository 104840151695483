import React from 'react';
import { IntlProvider, injectIntl } from 'react-intl';
import useLocale from '#context/hooks/useLocale';

import * as translations from '../../translations';

const IntlWrapper = injectIntl(({ children, intl }) => {
  const renderChildren = React.Children.map(children, child => {
    return React.cloneElement(child, {
      locale: intl.locale,
      translations: intl.messages,
    });
  });

  return renderChildren;
});

function withTranslation(WrappedComponent, translation) {
  return function Component(props) {
    const { state: locale } = useLocale();

    // To allow multiple translations
    let translationObj = {};
    if (Array.isArray(translation)) {
      translation.forEach(x => {
        translationObj = {
          ...translationObj,
          ...translations[x][locale],
        };
      });
    } else if (translation) {
      translationObj = {
        ...translations[translation][locale],
      };
    }

    return (
      <IntlProvider locale={locale} messages={{ ...translations['global'][locale], ...translationObj }}>
        <IntlWrapper>
          <WrappedComponent {...props} />
        </IntlWrapper>
      </IntlProvider>
    );
  };
}

export default withTranslation;
