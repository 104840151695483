import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormAutocomplete, WafoFormInput } from '@wafo/forms';
import withTranslation from '#components/HOCs/withTranslation';
import useApi from '#hooks/api/useApi';
import MaterialType from '#components/UI/extra/materialType';
import useLoading from '#context/hooks/useLoading';

const FormNewTrainUP = ({ locale, translations, onMaterialSelected, onSubmit }) => {
  const api = useApi();
  const loading = useLoading();

  /** **************************************** */
  /** Autocompletes */
  /** **************************************** */
  const [clients, setClients] = React.useState([]);
  const [routes, setRoutes] = React.useState([]);
  const [materials, setMaterials] = React.useState([]);

  React.useEffect(() => {
    async function getClients() {
      try {
        loading.set();
        const resp = await api.get('/users', {
          params: {
            limit: 999,
            disabled: false,
            type: 2,
          },
        });
        setClients(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }

    async function getRoutes() {
      try {
        loading.set();
        const resp = await api.get('/routes', {
          params: {
            limit: 999,
          },
        });
        setRoutes(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }

    async function getMaterials() {
      try {
        loading.set();
        const resp = await api.get('/materials', {
          params: {
            limit: 999,
            disabled: false,
          },
        });
        setMaterials(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    // Execution zone
    getClients();
    getRoutes();
    getMaterials();
  }, [api, loading]);

  /** **************************************** */
  /** Submit */
  /** **************************************** */
  async function handleSubmit(form, values) {
    if (onSubmit) {
      onSubmit(form, values);
    } else {
      // TODO: Fill this maybe?
    }
  }

  return (
    <div>
      <WafoForm formId="form-package" locale={locale} onSubmit={handleSubmit}>
        <WafoFormInput
          type="text"
          name="train_id"
          customClass="col-12 col-md-4"
          label={translations['trains-new-f-label-trainid']}
          placeholder={translations['trains-new-f-placeholder-trainid']}
          validations={{ required: true }}
        />
        <WafoFormInput
          type="number"
          name="external_id"
          customClass="col-12 col-md-4"
          label={translations['trains-new-f-label-externalid']}
          placeholder={translations['trains-new-f-placeholder-externalid']}
          validations={{ required: true }}
        />
        <WafoFormInput
          type="text"
          name="bol_number"
          customClass="col-12 col-md-4"
          label={translations['trains-new-f-label-bol']}
          placeholder={translations['trains-new-f-placeholder-bol']}
          validations={{ required: true }}
        />
        <WafoFormAutocomplete
          name="id_user"
          customClass="col-12 col-md-4"
          label={translations['trains-new-f-label-client']}
          placeholder={translations['trains-new-f-placeholder-client']}
          items={clients}
          filterItems={(items, query) =>
            items.filter(
              item =>
                item.name.toLowerCase().indexOf(query) !== -1 || item.last_name.toLowerCase().indexOf(query) !== -1,
            )
          }
          renderInput={item => `${item.name} ${item.last_name}`}
          renderItem={item => (
            <p style={{ margin: '0', padding: '0.75rem 0.5rem', borderBottom: '1px solid #f5f5f5' }}>
              <span>{`${item.name} ${item.last_name}`}</span>
            </p>
          )}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          handleChange
        />
        <WafoFormAutocomplete
          name="id_route"
          customClass="col-12 col-md-4"
          label={translations['trains-new-f-label-route']}
          placeholder={translations['trains-new-f-placeholder-route']}
          items={routes}
          filterItems={(items, query) =>
            items.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
          }
          renderInput={item => item.name}
          renderItem={item => (
            <p style={{ margin: '0', padding: '0.75rem 0.5rem', borderBottom: '1px solid #f5f5f5' }}>
              <span>{item.name}</span>
            </p>
          )}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          handleChange
        />
        <WafoFormAutocomplete
          name="material"
          customClass="col-12 col-md-4"
          label={translations['trains-new-f-label-material']}
          placeholder={translations['trains-new-f-placeholder-material']}
          items={materials}
          filterItems={(items, query) => items.filter(i => i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)}
          renderInput={item =>
            `[${item.unit_type} - ${translations['materials-f-types'][item.material_type - 1]}] ${item.name}`
          }
          renderItem={item => (
            <p style={{ margin: '0', padding: '0.5rem', lineHeight: 1, borderBottom: '1px solid #f5f5f5' }}>
              <span>{item.name}</span>
              <br />
              <span style={{ fontSize: '0.85em', color: '#777777' }}>
                {item.unit_type}
                {' - '}
                <MaterialType translations={translations} type={item.material_type} />
              </span>
            </p>
          )}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          onSelectCallback={onMaterialSelected}
          handleChange
        />
      </WafoForm>
    </div>
  );
};

FormNewTrainUP.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onMaterialSelected: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default withTranslation(FormNewTrainUP, ['trains', 'materials']);
