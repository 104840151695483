import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import { useIntl } from 'react-intl';
import { storeToken } from '#utils/authService';
import useApi from '#hooks/api/useApi';

const FormLogin = ({ onAfterSubmit }) => {
  const { locale, messages: translations } = useIntl();

  const api = useApi();

  const handleFormSubmit = async (form, values) => {
    if (form.valid) {
      try {
        const resp = await api.post('/auth/login', values);
        storeToken(resp.token);
        onAfterSubmit('/panel');
      } catch (error) {
        // handle something
      }
    }
  };

  return (
    <div>
      <WafoForm formId="login-form" locale={locale} onSubmit={handleFormSubmit}>
        <WafoFormInput
          type="email"
          name="email"
          label={translations['auth-f-login-label-email']}
          placeholder={translations['auth-f-login-placeholder-email']}
          customClass="col-12"
          validations={{
            required: true,
            email: true,
          }}
        />

        <WafoFormInput
          type="password"
          name="password"
          label={translations['auth-f-login-label-password']}
          placeholder={translations['auth-f-login-placeholder-password']}
          customClass="col-12"
          validations={{
            required: true,
          }}
        />
      </WafoForm>

      <button
        type="submit"
        form="login-form"
        className="btn btn-s"
        style={{ width: '100%', marginBottom: '1rem', marginTop: '1rem' }}
      >
        {translations['auth-f-login-button']}
      </button>
    </div>
  );
};

FormLogin.propTypes = {
  onAfterSubmit: PropTypes.func,
};

export default FormLogin;
