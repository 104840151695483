import React from 'react';
import { WafoForm, WafoFormAutocomplete, WafoFormInput } from '@wafo/forms';
import useTranslation from '#hooks/redux/useTranslation';
import { FormattedMessage } from 'react-intl';
import useApi from '#hooks/api/useApi';
import useLoading from '#context/hooks/useLoading';
import MaterialType from '../../UI/extra/materialType';
import useAlerts from '#context/hooks/useAlerts';
import DatetimePicker from '../customForms/datetimePicker/datetimePicker';
import moment from 'moment';
import { materialQuantity } from '#utils/utils';

const FormTransportCharge = ({ shipId, userStores, vehicles, drivers, onCancel, onSubmitSuccess }) => {
  const { translations, locale } = useTranslation();
  const api = useApi();
  const loading = useLoading();
  const alerts = useAlerts();

  const [preload, setPreload] = React.useState();

  const handleSubmit = async (form, values) => {
    if (form.valid) {
      loading.set();
      try {
        await api.post('/transports/charges', {
          id_ship: shipId,
          id_vehicle: values.id_vehicle.id_vehicle,
          id_driver: values.id_driver.id_driver,
          ticket: values.ticket,
          charges: [
            {
              id_user_store: values.id_user_store.id_user_store,
              quantity: values.quantity / parseFloat(values.id_user_store.material.quantity),
            },
          ],
          ...(values.bod && { bod: values.bod }),
          ...(values.placement_date_start && {
            placement_date_start: moment(values.placement_date_start).toISOString(),
          }),
          ...(values.placement_date_end && {
            placement_date_end: moment(values.placement_date_end).toISOString(),
          }),
          ...(values.charge_date_start && {
            charge_date_start: moment(values.charge_date_start).toISOString(),
          }),
          ...(values.charge_date_end && {
            charge_date_end: moment(values.charge_date_end).toISOString(),
          }),
          ...(values.departure_date && {
            departure_date: moment(values.departure_date).toISOString(),
          }),
        });

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });

        onSubmitSuccess();
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
  };

  return (
    <div>
      <WafoForm formId="form-transportcharge" locale={locale} onSubmit={handleSubmit} values={preload}>
        <WafoFormInput
          type="number"
          name="ticket"
          label={translations['ships-f-transport-label-ticket']}
          placeholder={translations['ships-f-transport-placeholder-ticket']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormAutocomplete
          name="id_vehicle"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-vehicle']}
          placeholder={translations['ships-f-transport-placeholder-vehicle']}
          items={vehicles}
          filterItems={(items, query) =>
            items.filter(
              item =>
                item.plates.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                `${item.brand} ${item.model} ${item.year}`.toLowerCase().indexOf(query.toLowerCase()) !== -1,
            )
          }
          renderInput={item => `${item.brand} ${item.model} ${item.year}`}
          renderItem={item => (
            <div style={{ padding: '.5rem 1rem' }}>
              <span>
                #{item.id_vehicle} - {`${item.brand} ${item.model} ${item.year}`}
              </span>
              {item.transport_driver && (
                <React.Fragment>
                  <br />
                  <span style={{ fontSize: '0.85em', color: '#777' }}>
                    Conductor: #{item.transport_driver.id_driver} -{' '}
                    {`${item.transport_driver.name} ${item.transport_driver.last_name}`}
                  </span>
                </React.Fragment>
              )}
            </div>
          )}
          onSelectCallback={item => {
            if (item.transport_driver) {
              setPreload({ id_driver: item.transport_driver });
            }
          }}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          handleChange
        />

        <WafoFormAutocomplete
          name="id_driver"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-driver']}
          placeholder={translations['ships-f-transport-placeholder-driver']}
          items={drivers}
          filterItems={(items, query) =>
            items.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
          }
          renderInput={item => `${item.name} ${item.last_name}`}
          renderItem={item => (
            <div style={{ padding: '.5rem 1rem' }}>
              #{item.id_driver} {item.name} {item.last_name}
            </div>
          )}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          handleChange
        />

        <WafoFormAutocomplete
          name="id_user_store"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-store']}
          placeholder={translations['ships-f-transport-placeholder-store']}
          items={userStores}
          filterItems={(items, query) =>
            items.filter(item => item.store_name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
          }
          renderInput={item =>
            `${item.available_quantity * parseFloat(item.material.quantity)} ${item.material.unit_type}`
          }
          renderItem={item => (
            <div style={{ padding: '.5rem 1rem' }}>
              <span>
                Disponible: {materialQuantity(item.material, item.available_quantity)} {item.material.unit_type}
              </span>
              <br />
              <div style={{ fontSize: '0.85em', color: '#777', lineHeight: 1 }}>
                <span>
                  <strong>Material:</strong> #{item.material.id_material} - {item.material.name}{' '}
                </span>
                <br />
                <span>
                  <strong>Tipo:</strong> <MaterialType translations={translations} type={item.material.material_type} />
                </span>
                <br />
                <span>
                  <strong>Bodega:</strong> #{item.id_store} - {item.store_name}
                </span>
              </div>
            </div>
          )}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          handleChange
        />

        <WafoFormInput
          type="number"
          name="quantity"
          label={translations['ships-f-transport-label-quantity']}
          placeholder={translations['ships-f-transport-placeholder-quantity']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormInput
          type="number"
          name="bod"
          label={translations['ships-f-transport-label-bod']}
          placeholder={translations['ships-f-transport-placeholder-bod']}
          customClass="col-12 col-md-4"
          validations={{ required: false }}
        />

        <div className="col-12">
          <p className="form-p-divider">
            <FormattedMessage id="ships-f-transport-divider-1" />
          </p>
        </div>

        <DatetimePicker
          name="placement_date_start"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-placement-start']}
          validations={{ required: false }}
          handleChange
        />

        <DatetimePicker
          name="placement_date_end"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-placement-end']}
          validations={{ required: false }}
          handleChange
        />

        <DatetimePicker
          name="charge_date_start"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-charge-start']}
          validations={{ required: false }}
          handleChange
        />

        <DatetimePicker
          name="charge_date_end"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-charge-end']}
          validations={{ required: false }}
          handleChange
        />

        <DatetimePicker
          name="departure_date"
          customClass="col-12 col-md-4"
          label={translations['ships-f-transport-label-departure']}
          validations={{ required: false }}
          handleChange
        />

        <div className="col-12 text-right">
          <div className="divider" />
          <button type="button" className="btn btn-link" onClick={onCancel}>
            <FormattedMessage id="confirm-deny" />
          </button>
          <button type="submit" form="form-transportcharge" className="btn btn-s">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </WafoForm>
    </div>
  );
};

export default FormTransportCharge;
