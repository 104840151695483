import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormAutocomplete, WafoFormInput } from '@wafo/forms';
import withTranslation from '#components/HOCs/withTranslation';
import useApi from '#hooks/api/useApi';
import LocationListItem from '#components/UI/locationListItem/locationListItem';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormVehicle = ({ locale, translations, onSubmitSuccess }) => {
  const [vehicle, setVehicle] = React.useState({});
  const [location, setLocation] = React.useState(null);
  const [locations, setLocations] = React.useState([]);
  const [drivers, setDrivers] = React.useState([]);

  const { id } = useParams();
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  React.useEffect(() => {
    async function getVehicle() {
      try {
        loading.set();
        let helper = await api.get(`/transports/vehicles/${id}`);

        setLocation(helper.id_location);
        const warehouse = locations.find(x => x.id_location === helper.id_location);
        if (warehouse) {
          helper = {
            ...helper,
            id_location: warehouse,
            id_driver: helper.transport_driver,
          };
        }
        setVehicle(helper);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    if (id && !!locations.length) {
      getVehicle();
    }
  }, [api, id, loading, locations]);

  React.useEffect(() => {
    async function getLocations() {
      try {
        loading.set();
        const resp = await api.get('/locations', {
          params: {
            limit: 999,
            type: '4,5',
          },
        });
        setLocations(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    getLocations();
  }, [api, loading]);

  React.useEffect(() => {
    async function getDrivers() {
      try {
        loading.set();
        const resp = await api.get('/transports/drivers', {
          params: {
            limit: 999,
            id_location: location,
          },
        });
        setDrivers(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
    if (location) {
      getDrivers();
    }
  }, [api, loading, location]);

  async function handleSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();
        const toSend = {
          ...(values.id_driver && { id_driver: values.id_driver.id_driver }),
          id_location: values.id_location.id_location,
          brand: values.brand,
          model: values.model,
          year: values.year,
          serial_number: values.serial_number,
          plates: values.plates,
        };
        if (id) {
          await api.put(`/transports/vehicles/${id}`, toSend);
        } else {
          await api.post('/transports/vehicles', toSend);
        }
        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        onSubmitSuccess();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }

  // TODO: Deseleccionar chofer si el almacen cambia y el chofer ya no esta disponible ?

  return (
    <div>
      <WafoForm formId="form-vehicle" locale={locale} onSubmit={handleSubmit} values={vehicle}>
        <WafoFormInput
          type="text"
          name="brand"
          label={translations['vehicle-f-label-brand']}
          placeholder={translations['vehicle-f-placeholder-brand']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormInput
          type="text"
          name="model"
          label={translations['vehicle-f-label-model']}
          placeholder={translations['vehicle-f-placeholder-model']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormInput
          type="number"
          name="year"
          label={translations['vehicle-f-label-year']}
          placeholder={translations['vehicle-f-placeholder-year']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormInput
          type="string"
          name="serial_number"
          label={translations['vehicle-f-label-serial']}
          placeholder={translations['vehicle-f-placeholder-serial']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormInput
          type="string"
          name="plates"
          label={translations['vehicle-f-label-plates']}
          placeholder={translations['vehicle-f-placeholder-plates']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />

        <WafoFormAutocomplete
          name="id_location"
          customClass="col-12 col-md-4"
          label={translations['vehicle-f-label-location']}
          placeholder={translations['vehicle-f-placeholder-location']}
          items={locations}
          filterItems={(items, query) => items.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)}
          renderInput={item => item.name}
          renderItem={item => <LocationListItem location={item} />}
          extraProps={{
            autoComplete: 'off',
          }}
          validations={{ required: true }}
          handleChange
          onSelectCallback={location => setLocation(location.id_location)}
        />

        <WafoFormAutocomplete
          name="id_driver"
          customClass="col-12 col-md-4"
          label={translations['vehicle-f-label-driver']}
          placeholder={translations['vehicle-f-placeholder-driver']}
          items={drivers}
          filterItems={(items, query) => items.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)}
          renderInput={item => `${item.name} ${item.last_name}`}
          renderItem={item => (
            <div style={{ padding: '.5rem 1rem' }}>
              #{item.id_driver} {item.name} {item.last_name}
            </div>
          )}
          extraProps={{
            autoComplete: 'off',
            disabled: !location,
          }}
          handleChange
        />
      </WafoForm>
      <div className="row">
        <div className="col-12 text-center">
          <button type="submit" form="form-vehicle" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </div>
    </div>
  );
};

FormVehicle.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
};

export default withTranslation(FormVehicle, 'vehicles');
