import React from 'react';
import styles from './loadingBar.module.css';
import { useSegroveContext } from '#context/segrove-context';

const LoadingBlock = () => {
  const {
    state: { loading },
  } = useSegroveContext();

  if (!loading.length) return null;
  return <div className={styles['block']} />;
};

export default LoadingBlock;
