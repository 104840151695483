import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import styles from './cardHeader.module.css';

const CardHeader = ({ title, showBack, onBack, children }) => {
  return (
    <div className={styles['card-header']}>
      <div className={styles['content']}>
        <div className={styles['left']}>
          {showBack && (
            <button type="button" className={`btn btn-link ${styles['back']}`} onClick={onBack}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
          )}
          <h4 className={styles['title']}>{title}</h4>
        </div>
        <div className={styles['right']}>{children}</div>
      </div>
      <div className={styles['white-bottom']}></div>
    </div>
  );
};

CardHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showBack: PropTypes.bool,
  onBack: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};

CardHeader.defaultProps = {
  title: '',
  showBack: false,
  onBack: f => f,
  children: null,
};

export default CardHeader;
