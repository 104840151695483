import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoute, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import TableRoutes from './tableRoutes';
import TableLocations from './locations/tableLocations';

const tablesSegroveRoutes = ({ translations }) => {
  const handleActive = (match, location, basePath) => {
    if (match) {
      return true;
    }
    const path = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
    return path === basePath;
  };

  return (
    <div>
      <CardHeader title={translations['routeslocations-title']}>
        <Link to="/panel/routes/new" className="btn btn-primary with-icon">
          <FontAwesomeIcon icon={faRoute} />
          {translations['routeslocations-b-route']}
        </Link>
        <Link to="/panel/locations/new" className="btn btn-primary with-icon">
          <FontAwesomeIcon icon={faMapMarkedAlt} />
          {translations['routeslocations-b-location']}
        </Link>
      </CardHeader>
      <div className="card card-panel">
        <ul className="nav nav-tabs card-tabs">
          <li className="nav-item">
            <NavLink
              exact
              to="/panel/routes"
              isActive={(match, location) => handleActive(match, location, '/panel/routes')}
              className="nav-link"
              activeClassName="active"
            >
              <FormattedMessage id="routeslocations-tab-1" />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              to="/panel/locations"
              isActive={(match, location) => handleActive(match, location, '/panel/locations')}
              className="nav-link"
              activeClassName="active"
            >
              <FormattedMessage id="routeslocations-tab-2" />
            </NavLink>
          </li>
        </ul>
        <div className="card-body">
          <Switch>
            <Route exact path="/panel/routes/list" component={TableRoutes} />
            <Route exact path="/panel/locations/list" component={TableLocations} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

tablesSegroveRoutes.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
};

export default withTranslation(tablesSegroveRoutes, 'locations');
