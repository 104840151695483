import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput, WafoFormAutocomplete } from '@wafo/forms';
import withTranslation from '#components/HOCs/withTranslation';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormModalTrain = ({ translations, locale, onSubmitSuccess, train }) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  /** **************************************** */
  /** Routes */
  /** **************************************** */
  const [routes, setRoutes] = React.useState([]);

  React.useEffect(() => {
    async function getRoutes() {
      try {
        const resp = await api.get('/routes', {
          params: {
            limit: 999,
          },
        });
        setRoutes(resp.rows);
      } catch (error) {
        // handle error
      }
    }
    getRoutes();
  }, [api]);

  /** **************************************** */
  /** Modal */
  /** **************************************** */

  async function handleFormSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();
        const body = {
          external_id: values.external_id,
          bol_number: values.bol_number,
          train_id: values.train_id,
          ...(!train.current_location && { id_route: values.id_route.id_route }),
          id_user: 9,
        };
        // TODO: Err 400: child id_user is required ?? Remove id_user from above, its just till the fix is commited.
        await api.put(`/packages/${train.id_package}`, body);
        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        loading.stop();
        onSubmitSuccess();
      } catch (error) {
        loading.stop();
      }
    }
  }

  const values = React.useMemo(() => {
    const route = routes.find(x => x.id_route === train.route.id_route);
    return {
      id_route: route,
      external_id: train.external_id,
      bol_number: train.bol_number,
      train_id: train.train_id,
    };
  }, [train, routes]);

  return (
    <div>
      <WafoForm formId="form-edittrain" locale={locale} onSubmit={handleFormSubmit} values={values}>
        <WafoFormInput
          type="text"
          name="train_id"
          customClass="col-12"
          label={translations['trains-f-trainedit-label-id']}
          placeholder={translations['trains-f-trainedit-placeholder-id']}
          validations={{ required: true }}
        />

        <WafoFormInput
          type="number"
          name="external_id"
          customClass="col-12"
          label={translations['trains-f-trainedit-label-external']}
          placeholder={translations['trains-f-trainedit-placeholder-external']}
          validations={{ required: true }}
        />

        <WafoFormInput
          type="text"
          name="bol_number"
          customClass="col-12"
          label={translations['trains-f-trainedit-label-bol']}
          placeholder={translations['trains-f-trainedit-placeholder-bol']}
          validations={{ required: true }}
        />

        <WafoFormAutocomplete
          name="id_route"
          customClass="col-12"
          label={translations['trains-f-trainedit-label-route']}
          placeholder={translations['trains-f-trainedit-placeholder-route']}
          items={routes}
          filterItems={(items, query) =>
            items.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
          }
          renderInput={item => item.name}
          renderItem={item => (
            <p style={{ margin: '0', lineHeight: 1, padding: '0 0.5rem' }}>
              <span>{item.name}</span>
            </p>
          )}
          extraProps={{
            autoComplete: 'off',
            disabled: train.current_location,
          }}
          validations={{ required: true }}
          handleChange
        />
      </WafoForm>

      <div className="row">
        <div className="col text-center">
          <button type="submit" form="form-edittrain" className="btn btn-s">
            {translations['trains-f-trainedit-submit']}
          </button>
        </div>
      </div>
    </div>
  );
};

FormModalTrain.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  train: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
};

FormModalTrain.defaultProps = {
  train: {},
};

export default withTranslation(FormModalTrain, 'trains');
