import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormAutocomplete, WafoFormTextArea } from '@wafo/forms';
import moment from 'moment';
import FilePicker from '#components/forms/customForms/filePicker/filePicker';
import DatetimePicker from '#components/forms/customForms/datetimePicker/datetimePicker';
import useApi from '#hooks/api/useApi';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormIncident = ({
  translations,
  locale,
  equipmentId,
  equipmentLocation,
  incident,
  onSubmitSuccess,
  equipments,
}) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  const incidentTypes = React.useMemo(
    () => [
      { id: 1, desc: 'empty', text: translations['incidents-types'][0] },
      { id: 2, desc: 'half_empty', text: translations['incidents-types'][1] },
      { id: 3, desc: 'undocumented', text: translations['incidents-types'][2] },
      { id: 4, desc: 'mechanical_failure', text: translations['incidents-types'][3] },
      { id: 5, desc: 'customs_red', text: translations['incidents-types'][4] },
      { id: 6, desc: 'other', text: translations['incidents-types'][5] },
    ],
    [translations],
  );

  async function onFormSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();

        if (equipments.length === 0) {
          if (incident) {
            await api.put(`/equipments/incidents/${incident.id_ei}`, {
              date_incident: moment(values.date_incident).toISOString(),
              ...(values.date_released && { date_released: moment(values.date_released).toISOString() }),
              comment: values.comment,
            });

            if (values.documents) {
              // if currently a file, delete it
              if (incident.eincident_images.length) {
                await api.delete(`/equipments/incidents/documents/${incident.eincident_images[0].id_image}`);
              }
              const formModel = new FormData();
              formModel.append('documents', values.documents);
              await api.post(`/equipments/incidents/${incident.id_ei}/documents`, formModel, {
                headers: { 'Content-Type': 'multipart/form-data' },
              });
            }
          } else {
            const formModel = new FormData();
            // formModel.append('idEl', baseEquipment.equipment_location.id_el);
            formModel.append('type', values.type.id);
            formModel.append('date_incident', moment(values.date_incident).toISOString());
            if (values.date_released) {
              formModel.append('date_released', moment(values.date_released).toISOString());
            }
            if (values.comment) {
              formModel.append('comment', values.comment);
            }
            if (values.documents) {
              formModel.append('documents', values.documents);
            }
            await api.post(`/equipments/locations/${equipmentLocation}/incidentsmulti`, formModel, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
          }
        } else {
          await Promise.all(
            equipments
              .filter(e => e.equipment_location)
              .map(e =>
                api.post(`/equipments/locations/${e.equipment_location.id_el}/incidents`, {
                  type: values.type.id,
                  date_incident: moment(values.date_incident).toISOString(),
                  ...(values.date_released && { date_released: moment(values.date_released).toISOString() }),
                  comment: values.comment,
                }),
              ),
          );
        }

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });

        onSubmitSuccess();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }

  let preparedIncident = null;
  if (incident) {
    preparedIncident = {
      type: incidentTypes.find(t => t.id === incident.type),
      date_incident: moment(incident.date_incident).format('YYYY-MM-DD:HH:mm'),
      ...(incident.date_released && { date_released: moment(incident.date_released).format('YYYY-MM-DD:HH:mm') }),
      comment: incident.comment,
      file: incident.eincident_images.length ? incident.eincident_images[0] : { filename: '' },
    };
  }

  return (
    <div id="forms-incident" className="row">
      <div className="col-12">
        <WafoForm formId="form-incident" onSubmit={onFormSubmit} ignoreEmpty locale={locale} values={preparedIncident}>
          <WafoFormAutocomplete
            name="type"
            customClass={equipments.length ? 'col-12' : 'col-12 col-md-6'}
            label={translations['incidents-f-label-type']}
            placeholder={translations['incidents-f-placeholder-type']}
            items={incidentTypes}
            filterItems={(items, query) => items.filter(i => i.text.toLowerCase().indexOf(query.toLowerCase()) !== -1)}
            renderInput={item => item.text}
            renderItem={item => (
              <p style={{ margin: '0', padding: '0.5rem', borderBottom: '1px solid #f5f5f5' }}>
                <span>{item.text}</span>
              </p>
            )}
            extraProps={{
              autoComplete: 'off',
              disabled: preparedIncident,
            }}
            validations={{ required: true }}
            handleChange
          />

          <DatetimePicker
            name="date_incident"
            customClass={equipments.length ? 'col-12' : 'col-12 col-md-6'}
            label={translations['incidents-f-label-incident']}
            validations={{ required: true }}
            handleChange
          />

          <DatetimePicker
            name="date_released"
            customClass={equipments.length ? 'col-12' : 'col-12 col-md-6'}
            label={translations['incidents-f-label-release']}
            // validations={{ required: true }}
            handleChange
          />

          {equipments.length === 0 && (
            <FilePicker
              name="documents"
              label={translations['incidents-f-label-file']}
              placeholder={translations['incidents-f-placeholder-file']}
              customClass="col-12 col-md-6"
              // currentSavedFile={currentPedimento.file}
              path={
                preparedIncident
                  ? `${process.env.REACT_APP_FILES_EQUIPMENTS_URL}/${equipmentId}/${preparedIncident.file.filename}`
                  : ''
              }
              currentSavedFile={preparedIncident ? preparedIncident.file.filename : ''}
              handleChange
            />
          )}

          <WafoFormTextArea
            name="comment"
            customClass={equipments.length ? 'col-12' : 'col-12 col-md-6'}
            label={translations['incidents-f-label-comment']}
            placeholder={translations['incidents-f-placeholder-comment']}
          />
        </WafoForm>
      </div>

      {equipments.length === 0 && (
        <div className="col-12 text-center">
          <button type="submit" form="form-incident" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      )}
    </div>
  );
};

FormIncident.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
  incident: PropTypes.any,
  equipmentId: PropTypes.string,
  equipmentLocation: PropTypes.any,
  equipments: PropTypes.array,
};

FormIncident.defaultProps = {
  onSubmitSuccess: f => f,
  equipments: [],
};

export default withTranslation(FormIncident, 'incidents');
