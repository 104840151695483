import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import TableNewEquipments from './tableNewEquipments';
import FormNewTrainUP from '#components/forms/trains/formNewTrainUP';
import withTranslation from '#components/HOCs/withTranslation';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';
import styles from './waybillConfirmation.module.css';

const WaybillConfirmation = ({ translations, waybill, onChangeBL, onChangeIgnore, onMaterialChange }) => {
  const history = useHistory();
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();

  React.useEffect(() => {
    // if (!waybill) {
    if (!waybill.billingInformation.waybillNumber) {
      history.replace('/panel/trains/new');
    }
  }, [waybill, history]);

  const {
    shipmentInformation,
    billingInformation,
    weightInformation,
    additionalReferenceIdentification,
    priceDocuments,
    customerInformation,
    routeInformation,
    ladingDescription,
    shipmentSpecialConditions,
    multiCarInformation,
  } = waybill;

  async function handleSubmit(form, values) {
    console.log(form);
    console.log(values);
    if (form.valid) {
      try {
        loading.set();
        // TODO:
        // convert material on the entire array on material select
        // that way I dont have to convert on table config and on submit
        // That has to happen on newTrain.js
        const equipmentsToSend = waybill.multiCarInformation.multiCarDetail
          .filter(x => !x.ignore)
          .map(e => ({
            equipment_id: `${e.equipmentId.initial}${e.equipmentId.number}`,
            quantity: e.materialWeight ? e.materialWeight.value : e.netWeight.value,
            id_material: values.material.id_material,
            ...(e.bl && { bl_number: e.bl }),
          }));
        await api.post('/equipments', {
          type: 1,
          waybill: waybill.billingInformation.waybillNumber,
          id_route: values.id_route.id_route,
          equipments: equipmentsToSend,
          external_id: values.external_id,
          bol_number: values.bol_number,
          train_id: values.train_id,
          id_user: values.id_user.id_user,
        });
        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        // TODO: navigate back to train list
        history.push('/panel/trains');
        loading.stop();
      } catch (error) {
        loading.stop();
        console.error(error);
      }
    } else {
      alerts.set({
        type: 'warning',
        title: translations['form-warning-title'],
        message: translations['form-warning-message'],
        timer: 3000,
      });
    }
  }

  return (
    <div>
      <h5 className="card-subtitle">
        <FormattedMessage id="trains-new-confirmation-sub" />
      </h5>
      <p className="description">
        <FormattedMessage id="trains-new-confirmation-desc" />
      </p>
      <div className="information">
        <div className="row">
          <div className="col-12 col-md-6">
            {/** shipment information */}
            <div className={styles['section']}>
              <h6 className={styles['desc-header']}>
                <FormattedMessage id="trains-new-confirmation-header-1" />
              </h6>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-1-1" />
                </span>
                <span>
                  {shipmentInformation.equipmentId.initial}
                  {shipmentInformation.equipmentId.number}
                </span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-1-2" />
                </span>
                <span>{shipmentInformation.carKind}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-1-3" />
                </span>
                <span>{shipmentInformation.loadEmpty}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-1-4" />
                </span>
                <span>{shipmentInformation.commodity}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-1-5" />
                </span>
                <span>
                  {shipmentInformation.originDetail.city}, {shipmentInformation.originDetail.state}
                </span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-1-6" />
                </span>
                <span>
                  {shipmentInformation.destinationDetail.city}, {shipmentInformation.destinationDetail.state}
                </span>
              </div>
            </div>

            {/** billing information */}
            <div className={styles['section']}>
              <h6 className={styles['desc-header']}>
                <FormattedMessage id="trains-new-confirmation-header-2" />
              </h6>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-2-1" />
                </span>
                <span>{billingInformation.waybillNumber}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-2-2" />
                </span>
                <span>{billingInformation.waybillDate}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-2-3" />
                </span>
                <span>{billingInformation.receiptLocalDateTime}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-2-4" />
                </span>
                <span>{billingInformation.waybillType}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-2-5" />
                </span>
                <span>{billingInformation.equipmentUse}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-2-6" />
                </span>
                <span>{billingInformation.bolNumber}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-2-7" />
                </span>
                <span>{billingInformation.payment}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-2-8" />
                </span>
                <span>{billingInformation.routeType}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-2-9" />
                </span>
                <span>{billingInformation.mvauth}</span>
              </div>
            </div>

            {/** weight information */}
            <div className={styles['section']}>
              <h6 className={styles['desc-header']}>
                <FormattedMessage id="trains-new-confirmation-header-3" />
              </h6>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-3-1" />
                </span>
                <span>{weightInformation.numCars}</span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-3-2" />
                </span>
                <span>
                  {weightInformation.netWeight.value} {weightInformation.netWeight.unit}
                </span>
              </div>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-3-3" />
                </span>
                {weightInformation.materialWeight && (
                  <span>
                    {weightInformation.materialWeight.value} {weightInformation.materialWeight.unit}
                  </span>
                )}
              </div>
            </div>

            {/** additional reference identification */}
            <div className={styles['section']}>
              <h6 className={styles['desc-header']}>
                <FormattedMessage id="trains-new-confirmation-header-4" />
              </h6>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-4-1" />
                </span>
                <span>{additionalReferenceIdentification.shipmentExtendedReferenceIdentificationInformations[0].value}</span>
              </div>
            </div>

            {/** rate information */}
            <div className={styles['section']}>
              <h6 className={styles['desc-header']}>
                <FormattedMessage id="trains-new-confirmation-header-5" />
              </h6>
              <div className={styles['desc-horizontal']}>
                <span>
                  <FormattedMessage id="trains-new-confirmation-desc-5-1" />
                </span>
                {priceDocuments.rateAuthority && (
                  <span>
                    {priceDocuments.rateAuthority.authorityType}
                    {priceDocuments.rateAuthority.referenceNumber}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            {/** customer information */}
            <div className={styles['section']}>
              <h6 className={styles['desc-header']}>
                <FormattedMessage id="trains-new-confirmation-header-6" />
              </h6>
              {customerInformation.mainPartyList &&
                customerInformation.mainPartyList.map((party, i) => (
                  <div key={i} className={styles['desc-vertical']}>
                    <span>
                      <strong>{party.partyDescription}</strong>
                    </span>
                    <span>{party.partyName}</span>
                    <span>
                      {party.city}, {party.state} {party.zip}
                    </span>
                  </div>
                ))}
            </div>

            {/** aditional parties */}
            <div className={styles['section']}>
              <h6 className={styles['desc-header']}>
                <FormattedMessage id="trains-new-confirmation-header-7" />
              </h6>
              {customerInformation.otherPartyList &&
                customerInformation.otherPartyList.map((party, i) => (
                  <div key={i} className={styles['desc-vertical']}>
                    <span>
                      <strong>{party.partyDescription}</strong>
                    </span>
                    <span>{party.partyName}</span>
                    <span>
                      {party.city}, {party.state} {party.zip}
                    </span>
                  </div>
                ))}
            </div>

            {/** route information */}
            <div className={styles['section']}>
              <h6 className={styles['desc-header']}>
                <FormattedMessage id="trains-new-confirmation-header-8" />
              </h6>
              <div className={styles['desc-vertical']}>
                <span>
                  <strong>Carrier, Juntion</strong>
                </span>
                {routeInformation.junctionsAndCarriers &&
                  routeInformation.junctionsAndCarriers.map((item, i) => (
                    <React.Fragment key={i}>
                      <span>{item.carrier}</span>
                      <span>{item.junction}</span>
                    </React.Fragment>
                  ))}
              </div>
            </div>

            {/** landing description */}
            <div className={styles['section']}>
              <h6 className={styles['desc-header']}>
                <FormattedMessage id="trains-new-confirmation-header-9" />
              </h6>
              <div className={styles['landing-desc']}>{ladingDescription.packages.map(pack => pack.descriptions.join(' '))}</div>
            </div>

            {/** shipment special condition information */}
            <div className={styles['section']}>
              <h6 className={styles['desc-header']}>
                <FormattedMessage id="trains-new-confirmation-header-10" />
              </h6>
              <div className={styles['landing-desc']}>
                {shipmentSpecialConditions.conditions &&
                  shipmentSpecialConditions.conditions.map((condition, i) => (
                    <div key={i}>
                      <span>
                        <strong>{condition.code} </strong>
                      </span>
                      <span>{condition.descriptions}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h6 className={styles['desc-header']}>
              <FormattedMessage id="trains-new-confirmation-header-table" />
            </h6>

            <FormNewTrainUP onMaterialSelected={material => onMaterialChange(material)} onSubmit={handleSubmit} />

            <TableNewEquipments equipments={multiCarInformation.multiCarDetail} onChangeBL={onChangeBL} onChangeIgnore={onChangeIgnore} />

            <div className="col-12 text-center">
              <button type="submit" form="form-package" className="btn btn-s btn-submit">
                <FormattedMessage id="form-submit" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WaybillConfirmation.propTypes = {
  translations: PropTypes.any,
  waybill: PropTypes.any,
  onChangeBL: PropTypes.func,
  onChangeIgnore: PropTypes.func,
  onMaterialChange: PropTypes.func,
};

export default withTranslation(WaybillConfirmation, ['trains', 'materials']);
