import React from 'react';
import { WafoForm, WafoFormAutocomplete, WafoFormInput } from '@wafo/forms';
import { v4 as uuidv4 } from 'uuid';
import useTranslation from '#hooks/redux/useTranslation';
import MaterialType from '#components/UI/extra/materialType';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import styles from './ships.module.css';

const FormShipStores = ({ ship, stores: propStores, onSubmitSuccess }) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();
  const { translations, locale } = useTranslation();

  /** *************************************** */
  /** Extras */
  /** *************************************** */

  const [materials, setMaterials] = React.useState([]);

  React.useEffect(() => {
    async function getMaterials() {
      try {
        loading.set();
        const resp = await api.get('/materials', {
          params: {
            limit: 999,
            disabled: false,
          },
        });
        setMaterials(resp.rows);
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }

    getMaterials();
  }, [api, loading]);

  /** *************************************** */
  /** Stores */
  /** *************************************** */

  const [stores, setStores] = React.useState(() => {
    if (propStores && propStores.length) {
      return {
        new_stores: [],
        current_stores: propStores.map(x => ({
          id_ship_storage: x.id_ship_storage,
          id_material: x.material,
          limit_quantity: x.limit_quantity,
          stowage_plan: x.stowage_plan,
        })),
      };
    }
    return {
      current_stores: [],
      new_stores: [],
    };
  });

  const removeStore = id => {
    setStores(prev => {
      const dummy = [...prev.current_stores];
      const index = dummy.findIndex(x => x.id_ship_storage === id);

      if (index !== -1) dummy.splice(index, 1);

      return {
        ...prev,
        current_stores: dummy,
      };
    });
  };

  const addNewStore = () => {
    setStores(prev => ({ ...prev, new_stores: [...prev.new_stores, { id: uuidv4() }] }));
  };

  const removeNewStore = id => {
    setStores(prev => {
      const dummy = [...prev.new_stores];
      const index = dummy.findIndex(x => x.id === id);

      if (index !== -1) dummy.splice(index, 1);

      return {
        ...prev,
        new_stores: dummy,
      };
    });
  };

  async function handleSubmit(form, values) {
    if (form.valid) {
      loading.set();
      try {
        // Deletes
        await Promise.all(
          propStores
            .filter(x => !Object.keys(values.current_stores).includes(x.id_ship_storage))
            .map(x => api.delete(`/ships/storages/${x.id_ship_storage}`)),
        );

        // Edits.
        await Promise.all(
          Object.entries(values.current_stores).map(([key, value]) =>
            api.put(`/ships/storages/${key}`, {
              // current_quantity: value.current_quantity,
              limit_quantity: value.limit_quantity / parseFloat(value.id_material.quantity),
              stowage_plan: value.stowage_plan / parseFloat(value.id_material.quantity),
            }),
          ),
        );

        // New stores
        await Promise.all(
          Object.entries(values.new_stores).map(([, value]) =>
            api.post('/ships/storages', {
              id_ship: ship,
              id_material: value.id_material.id_material,
              limit_quantity: value.limit_quantity / parseFloat(value.id_material.quantity),
              stowage_plan: value.stowage_plan / parseFloat(value.id_material.quantity),
            }),
          ),
        );

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });

        onSubmitSuccess();
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
  }

  /** *************************************** */
  /** Render */
  /** *************************************** */

  const initialValues = React.useMemo(() => {
    return propStores.reduce(
      (accumulator, x) => {
        accumulator.current_stores[x.id_ship_storage] = {
          id_ship_storage: x.id_ship_storage,
          id_material: x.material,
          limit_quantity: x.limit_quantity,
          stowage_plan: x.stowage_plan?.stowage_plan,
        };
        return accumulator;
      },
      { current_stores: {} },
    );
  }, [propStores]);

  return (
    <div>
      <WafoForm formId="form-shipstores" locale={locale} onSubmit={handleSubmit} values={initialValues}>
        <div className="col-12">
          <div className="form-divider">
            <span>
              <strong>
                <FormattedMessage id="ships-f-store-sub-1" />
              </strong>
            </span>
          </div>
        </div>

        <div className="col-12" groupname="current_stores">
          {stores.current_stores.map(store => (
            <div key={store.id_ship_storage} className="row" groupname={store.id_ship_storage}>
              <div className={`col-12 col-md-1 ${styles['row-id']}`}>
                <span>#{store.id_ship_storage}</span>
              </div>

              <WafoFormAutocomplete
                name="id_material"
                customClass="col-12 col-md-4"
                label={translations['ships-f-store-label-material']}
                placeholder={translations['ships-f-store-placeholder-material']}
                items={materials}
                filterItems={(items, query) =>
                  items.filter(i => i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                }
                renderInput={item =>
                  `[${item.unit_type} - ${translations['materials-f-types'][item.material_type - 1]}] ${item.name}`
                }
                renderItem={item => (
                  <p style={{ margin: '0', padding: '0.5rem', lineHeight: 1, borderBottom: '1px solid #f5f5f5' }}>
                    <span>{item.name}</span>
                    <br />
                    <span style={{ fontSize: '0.85em', color: '#777777' }}>
                      {item.unit_type}
                      {' - '}
                      <MaterialType translations={translations} type={item.material_type} />
                    </span>
                  </p>
                )}
                extraProps={{
                  autoComplete: 'off',
                  disabled: true,
                }}
                validations={{ required: true }}
                handleChange
              />

              <WafoFormInput
                type="number"
                name="limit_quantity"
                label={translations['ships-f-store-label-limit']}
                placeholder={translations['ships-f-store-placeholder-limit']}
                customClass="col-12 col-md-3"
                validations={{ required: true }}
              />

              <WafoFormInput
                type="number"
                name="stowage_plan"
                label={translations['ships-f-store-label-stowage']}
                placeholder={translations['ships-f-store-placeholder-stowage']}
                customClass="col-12 col-md-3"
                validations={{ required: true }}
              />

              <div className={`col-12 col-md-1 ${styles['row-button']}`}>
                <button type="button" className="btn btn-link" onClick={() => removeStore(store.id_ship_storage)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))}
          {!stores.current_stores.length && (
            <p className="form-well-msg">
              <FormattedMessage id="ships-f-store-current-empty" />
            </p>
          )}
        </div>

        <div className="col-12">
          <div className="form-divider-with-btn">
            <span>
              <strong>
                <FormattedMessage id="ships-f-store-sub-2" />
              </strong>
            </span>
            <button type="button" className="btn btn-sm btn-link with-icon" onClick={addNewStore}>
              <FontAwesomeIcon icon={faPlus} />
              <FormattedMessage id="ships-f-store-b-add" />
            </button>
          </div>
        </div>

        <div className="col-12" groupname="new_stores">
          {stores.new_stores.map(store => (
            <div key={store.id} className="row" groupname={store.id}>
              <WafoFormAutocomplete
                name="id_material"
                customClass="col-12 col-md-4"
                label={translations['ships-f-store-label-material']}
                placeholder={translations['ships-f-store-placeholder-material']}
                items={materials}
                filterItems={(items, query) =>
                  items.filter(i => i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                }
                renderInput={item =>
                  `[${item.unit_type} - ${translations['materials-f-types'][item.material_type - 1]}] ${item.name}`
                }
                renderItem={item => (
                  <p style={{ margin: '0', padding: '0.5rem', lineHeight: 1, borderBottom: '1px solid #f5f5f5' }}>
                    <span>{item.name}</span>
                    <br />
                    <span style={{ fontSize: '0.85em', color: '#777777' }}>
                      {item.unit_type}
                      {' - '}
                      <MaterialType translations={translations} type={item.material_type} />
                    </span>
                  </p>
                )}
                extraProps={{
                  autoComplete: 'off',
                }}
                validations={{ required: true }}
                handleChange
              />

              <WafoFormInput
                type="number"
                name="limit_quantity"
                label={translations['ships-f-store-label-limit']}
                placeholder={translations['ships-f-store-placeholder-limit']}
                customClass="col-12 col-md-3"
                validations={{ required: true }}
              />

              <WafoFormInput
                type="number"
                name="stowage_plan"
                label={translations['ships-f-store-label-stowage']}
                placeholder={translations['ships-f-store-placeholder-stowage']}
                customClass="col-12 col-md-3"
                validations={{ required: true }}
              />

              <div className={`col-12 col-md-2 ${styles['row-button']}`}>
                <button type="button" className="btn btn-link" onClick={() => removeNewStore(store.id)}>
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </div>
          ))}
          {!stores.new_stores.length && (
            <p className="form-well-msg">
              <FormattedMessage id="ships-f-store-new-empty" />
            </p>
          )}
        </div>

        <div className="col-12 text-center">
          <div className="divider" />
          <button type="submit" form="form-shipstores" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </WafoForm>
    </div>
  );
};

export default FormShipStores;
