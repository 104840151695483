import React from 'react';
import { useSegroveContext } from '#context/segrove-context';

const useAlerts = () => {
  const { dispatch } = useSegroveContext();

  const alerts = React.useMemo(
    () => ({
      set: alert => dispatch({ type: 'ALERT_ADD', payload: alert }),
      remove: uuid => dispatch({ type: 'ALERT_REMOVE', payload: uuid }),
      success: ({ title, message, timer }) =>
        dispatch({
          type: 'ALERT_ADD',
          payload: {
            type: 'success',
            title,
            message,
            timer,
          },
        }),
    }),
    [dispatch],
  );

  return alerts;
};

export default useAlerts;
