import React from 'react';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import useTranslation from '#hooks/redux/useTranslation';
import { FormattedMessage } from 'react-intl';
import useLoading from '#context/hooks/useLoading';
import useApi from '#hooks/api/useApi';
import useAlerts from '#context/hooks/useAlerts';

const FormPassword = ({ onSubmitSuccess }) => {
  const { translations, locale } = useTranslation();
  const loading = useLoading();
  const api = useApi();
  const alerts = useAlerts();

  const [password, setPassword] = React.useState('');

  const handleSubmit = async (form, values) => {
    if (form.valid) {
      loading.set();
      try {
        await api.post('/auth/password', {
          password: values.password,
        });

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });
        onSubmitSuccess();
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
  };

  return (
    <div>
      <p>
        <FormattedMessage id="profile-f-desc" />
      </p>
      <WafoForm formId="form-password" locale={locale} onSubmit={handleSubmit} values={{}}>
        <WafoFormInput
          type="password"
          name="password"
          label={translations['profile-f-pass-label-pass']}
          placeholder={translations['profile-f-pass-placeholder-pass']}
          customClass="col-12 col-md-4"
          validations={{ required: true, maxLength: 100, minLength: 6 }}
          onChangeCallback={({ target: { value } }) => setPassword(value)}
        />

        <WafoFormInput
          type="password"
          name="password2"
          label={translations['profile-f-pass-label-pass2']}
          placeholder={translations['profile-f-pass-placeholder-pass2']}
          customClass="col-12 col-md-4"
          validations={{
            required: true,
            validationFunction: {
              value: pass2 => pass2 === password,
              message: translations['profile-f-pass-nomatch'],
            },
          }}
        />
        <div className="col-12">
          <button type="submit" form="form-password" className="btn btn-s btn-submit">
            <FormattedMessage id="form-submit" />
          </button>
        </div>
      </WafoForm>
    </div>
  );
};

export default FormPassword;
