import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput as Input } from '@wafo/forms';
import { FormattedMessage } from 'react-intl';
import withTranslation from '#components/HOCs/withTranslation';
import useApi from '#hooks/api/useApi';
import useLoading from '#context/hooks/useLoading';

const SearchByEquipment = ({ locale, translations, handleSearch }) => {
  const api = useApi();
  const loading = useLoading();

  async function handleSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();
        const resp = await api.get(`/equipments/up/${values.equipment}`);
        handleSearch({
          equipment: values.equipment,
          waybills: resp,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }

  return (
    <div>
      <h5 className="card-subtitle">
        <FormattedMessage id="trains-new-search-sub" />
      </h5>
      <p className="description">
        <FormattedMessage id="trains-new-search-dec" />
      </p>
      <WafoForm formId="form-equipment" onSubmit={handleSubmit} locale={locale}>
        <Input
          type="text"
          name="equipment"
          label={translations['trains-new-f-label-equipment']}
          placeholder={translations['trains-new-f-placeholder-equipment']}
          customClass="col-12 col-md-4"
          validations={{ required: true }}
        />
      </WafoForm>
      <button type="submit" form="form-equipment" className="btn btn-primary">
        <FormattedMessage id="trains-new-search-submit" />
      </button>
    </div>
  );
};

SearchByEquipment.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  handleSearch: PropTypes.func,
};

export default withTranslation(SearchByEquipment, 'trains');
