import React from 'react';
import CardHeader from '#components/UI/cards/cardHeader/cardHeader';
import { useLocation, useParams, Link } from 'react-router-dom';
import useLoading from '#context/hooks/useLoading';
import useApi from '#hooks/api/useApi';
import InfoColumn from '#components/UI/general/infoColumn/infoColumn';
import useTranslation from '#hooks/redux/useTranslation';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import ShipContainer from '#components/UI/materialContainers/shipContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCog, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ShipStoresModal from '#components/modals/ships/shipStoresModal';
import ShipChargesTable from './shipChargesTable';
import { materialQuantity } from '#utils/utils';
import ShipIncidentsModal from '#components/modals/ships/shipIncidentsModal';

const ShipDetails = () => {
  const loading = useLoading();
  const api = useApi();
  const { translations } = useTranslation();

  const { id } = useParams();
  const { state: locationState } = useLocation();

  const [ship, setShip] = React.useState(() => {
    if (locationState && locationState.ship) {
      return { ...locationState.ship };
    }
    return {};
  });

  const getShip = React.useCallback(async () => {
    loading.set();
    try {
      const resp = await api.get(`/ships/${id}`);
      setShip(resp);
    } catch (error) {
      console.error(error);
    }
    loading.stop();
  }, [api, loading, id]);

  React.useEffect(() => {
    getShip();
  }, [getShip]);

  const stores = React.useMemo(() => {
    if (!ship.shipment_storages) return { materials: [], units: [] };
    return ship.shipment_storages.reduce(
      (accumulator, ss) => {
        const unit = `${translations['materials-f-types'][ss.material.material_type - 1]} [${ss.material.unit_type}]`;
        if (!accumulator.units.includes(unit)) {
          accumulator.units.push(unit);
        }
        if (!accumulator.materials.includes(ss.material.id_material)) {
          accumulator.materials.push(ss.material.id_material);
        }
        return accumulator;
      },
      { materials: [], units: [] },
    );
  }, [ship.shipment_storages, translations]);

  /** ******************************************* */
  /** Incidents */
  /** ******************************************* */

  const [incidents, setIncidents] = React.useState([]);

  const getIncidents = React.useCallback(
    async (filters = {}) => {
      if (!ship.id_ship) return;
      loading.set();
      try {
        const resp = await api.get('/ships/incidents', {
          params: {
            id_ship: ship.id_ship,
            order: '-id_ship_incident',
            limit: 999,
            ...(filters.type && { type: filters.type }),
          },
        });

        setIncidents(resp.rows);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    },
    [api, loading, ship.id_ship],
  );

  React.useEffect(() => {
    if (ship.id_ship) {
      getIncidents();
    }
  }, [ship.id_ship, getIncidents]);

  /** ******************************************* */
  /** Modals */
  /** ******************************************* */

  const [modal, setModal] = React.useState({
    type: null,
    payload: null,
  });
  const openModal = (type, payload) => setModal({ type: type || null, payload: payload || null });
  const closeModal = () => setModal({ type: null, payload: null });

  const renderModal = React.useMemo(() => {
    if (!modal.type) return null;
    switch (modal.type) {
      case 'stores':
        return <ShipStoresModal ship={ship} updateData={getShip} onClose={closeModal} />;
      case 'incidents':
        return <ShipIncidentsModal ship={ship} incidents={incidents} updateData={getIncidents} onClose={closeModal} />;
      default:
        return null;
    }
  }, [modal, ship, getShip, incidents, getIncidents]);

  /** ******************************************* */
  /** Renders */
  /** ******************************************* */

  return (
    <React.Fragment>
      <CardHeader title={translations['ships-d-title']}>
        <Link to={{ pathname: `/panel/ships/new/${id}`, state: { ship } }} className="btn btn-primary with-icon">
          <FontAwesomeIcon icon={faEdit} />
          <FormattedMessage id="ships-t-b-edit" />
        </Link>
        <button type="button" className="btn btn-primary with-icon" onClick={() => openModal('stores')}>
          <FontAwesomeIcon icon={faCog} />
          <FormattedMessage id="ships-t-b-stores" />
        </button>
        <button type="button" className="btn btn-primary with-icon" onClick={() => openModal('incidents')}>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          <FormattedMessage id="ships-t-b-incidents" />
          <span className="badge badge-light" style={{ marginLeft: '0.5rem' }}>
            {incidents.filter(x => !x.date_released).length}
          </span>
        </button>
      </CardHeader>
      <div className="card card-panel">
        <div className="card-body">
          <div className="card-subtitle">
            <FormattedMessage id="ships-d-subt-1" />
          </div>
          <div className="row">
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['ships-d-info-id']}
              info={`${ship.ship_id} (#${ship.id_ship})`}
            />
            <InfoColumn colSize="col-12 col-md-3" title={translations['ships-d-info-name']} info={ship.name} />
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['ships-d-info-stores']}
              info={
                <React.Fragment>
                  <span>
                    {stores.materials.length} {translations['warehouses-d-s-info-materials-desc']}
                  </span>
                  <br />
                  <span style={{ fontSize: '0.85em' }}>{stores.units.join(', ')}</span>
                </React.Fragment>
              }
            />
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['ships-d-info-client']}
              info={`#${ship.user?.id_user} - ${ship.user?.name} ${ship.user?.last_name}`}
            />
            <InfoColumn colSize="col-12 col-md-3" title={translations['ships-d-info-harbor']} info={ship.id_location} />
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['ships-d-info-arrival']}
              info={ship.arrival_date ? moment(ship.arrival_date).format('DD MMMM YYYY HH:mm') : ''}
            />
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['ships-d-info-eta']}
              info={ship.eta_date ? moment(ship.eta_date).format('DD MMMM YYYY HH:mm') : ''}
            />
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['ships-d-info-dock']}
              info={ship.dock_date ? moment(ship.dock_date).format('DD MMMM YYYY HH:mm') : ''}
            />
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['ships-d-info-sail']}
              info={ship.sail_date ? moment(ship.sail_date).format('DD MMMM YYYY') : ''}
            />
            <InfoColumn colSize="col-12 col-md-3" title={translations['ships-d-info-invoice']} info="" />
            <InfoColumn
              colSize="col-12 col-md-3"
              title={translations['ships-d-info-update']}
              info={
                <React.Fragment>
                  <span title="Updated">{moment(ship.updateAt).format('DD MMMM YYYY HH:mm')}</span>
                  <br />
                  <span title="Created" style={{ fontSize: '0.8em' }}>
                    {moment(ship.createdAt).format('DD MMMM YYYY HH:mm')}
                  </span>
                </React.Fragment>
              }
            />
          </div>
          <div className="card-subtitle">
            <FormattedMessage id="ships-d-subt-2" />
          </div>
          <div className="row">
            {ship.shipment_storages &&
              ship.shipment_storages.map((store, index) => {
                const limit = materialQuantity(store.material, store.limit_quantity);
                const stowage = materialQuantity(store.material, store.stowage_plan?.stowage_plan);
                const current = materialQuantity(store.material, store.charged_material);

                return (
                  <div key={store.id_ship_storage} className="col-12 col-md-2">
                    <ShipContainer total={limit} stowage={stowage} used={current} index={index+1} />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="divider" />
      <ShipChargesTable ship={ship} />
      {renderModal}
    </React.Fragment>
  );
};

export default ShipDetails;
