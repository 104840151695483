import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm } from '@wafo/forms';
import DatetimePicker from '#components/forms/customForms/datetimePicker/datetimePicker';
import useApi from '#hooks/api/useApi';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import styles from './trains.module.css';
import useAlerts from '#context/hooks/useAlerts';
import useLoading from '#context/hooks/useLoading';

const FormTimings = ({ translations, locale, locations, onSubmitSuccess }) => {
  const api = useApi();
  const alerts = useAlerts();
  const loading = useLoading();
  
  async function onFormSubmit(form, values) {
    if (form.valid) {
      try {
        loading.set();
        await Promise.all(
          locations.map(l =>
            api.put(`/equipments/locations/${l.id_el}`, {
              date_arrival: values[`date_arrival_${l.id_el}`],
              date_departed: values[`date_departed_${l.id_el}`],
            }),
          ),
        );

        alerts.success({
          title: translations['alerts-success-title'],
          message: translations['alerts-success-msg'],
          timer: 3000,
        });

        onSubmitSuccess();
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    }
  }

  const values = {};
  locations.forEach(l => {
    values[`date_arrival_${l.id_el}`] = l.date_arrival;
    values[`date_departed_${l.id_el}`] = l.date_departed;
  });

  return (
    <div id="component-form-timings" className="row">
      <div className="col-12">
        <WafoForm formId="form-timings" onSubmit={onFormSubmit} ignoreEmpty locale={locale} values={values}>
          {locations.map(l => {
            return [
              <div key={`title-${l.id_el}`} className="col-12">
                <div className={styles['timings-title']}>{l.location.name}</div>
              </div>,
              <DatetimePicker
                key={`${l.id_el}-1`}
                name={`date_arrival_${l.id_el}`}
                customClass="col-6"
                label={translations['equipments-m-details-f-label-arrival']}
                handleChange
              />,
              <DatetimePicker
                key={`${l.id_el}-2`}
                name={`date_departed_${l.id_el}`}
                customClass="col-6"
                label={translations['equipments-m-details-f-label-departure']}
                handleChange
              />,
            ];
          })}
        </WafoForm>
      </div>
      <div className="col-12 text-center">
        <button type="submit" form="form-timings" className="btn btn-s btn-submit">
          <FormattedMessage id="form-submit" />
        </button>
      </div>
    </div>
  );
};

FormTimings.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  onSubmitSuccess: PropTypes.func,
  locations: PropTypes.array,
};

export default withTranslation(FormTimings, 'equipments');
