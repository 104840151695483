import React from 'react';
import { useSegroveContext } from '#context/segrove-context';

const useSidebar = () => {
  const { state, dispatch } = useSegroveContext();

  const sidebar = React.useMemo(
    () => ({
      toggle: () => dispatch({ type: 'SIDEBAR_TOGGLE' }),
      state: state.sidebar,
    }),
    [dispatch, state],
  );

  return sidebar;
};

export default useSidebar;
