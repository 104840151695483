import React from 'react';
import PanelContainer from '../panelContainer';
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import TableNotifications from './tableNotifications';

const Notifications = () => {
  const match = useRouteMatch();

  return (
    <PanelContainer>
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/list`} />
        <Route exact path={`${match.url}/list`} component={TableNotifications} />
      </Switch>
    </PanelContainer>
  );
};

export default Notifications;
