import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStamp, faTrain, faShip, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import styles from './routeProgress.module.css';

const RouteProgress = ({ route, current, selected, onLocationClick }) => {
  const active = selected ? selected : current;
  return (
    <div className={styles['route-progress']}>
      {route.route_locations
        .sort((a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0))
        .map(l => {
          const isActive = active && active.id_location === l.id_location;
          return (
            <div key={l.id_rl} className={styles['wrapper']}>
              <div className={styles['line']} />
              <div className={`${styles['bola']} ${isActive ? styles['active'] : styles['inactive']}`} onClick={() => onLocationClick(l)}>
                {l.location.type === 1 && <FontAwesomeIcon icon={faTrain} />}
                {l.location.type === 2 && <FontAwesomeIcon icon={faStamp} />}
                {l.location.type === 3 && <FontAwesomeIcon icon={faShip} />}
                {l.location.type === 4 && <FontAwesomeIcon icon={faWarehouse} />}
              </div>
              <span className={styles['label']}>{l.location.name}</span>
            </div>
          );
        })}
    </div>
  );
};

RouteProgress.propTypes = {
  route: PropTypes.shape({
    route_locations: PropTypes.array,
  }),
  current: PropTypes.shape({
    id_location: PropTypes.string,
  }),
  selected: PropTypes.shape({
    id_location: PropTypes.string,
  }),
  onLocationClick: PropTypes.func,
};

RouteProgress.defaultProps = {};

export default RouteProgress;
