import React from 'react';
import PropTypes from 'prop-types';
import styles from './infoColumn.module.css';

const InfoColumn = ({ colSize, title, info, empty }) => {
  return (
    <div className={colSize}>
      <div className={styles['infocol']}>
        <span>{title}</span>
        <span>{info}</span>
        {!info && <span className={styles['empty']}>{empty}</span>}
      </div>
    </div>
  );
};

InfoColumn.propTypes = {
  colSize: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.number]),
  empty: PropTypes.string,
};

InfoColumn.defaultProps = {
  colSize: 'col-12 col-md-4',
  title: 'Title',
  info: 'Information',
  empty: 'Not available'
};

export default InfoColumn;
