import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@wafo/table';
import useApi from '#hooks/api/useApi';
import { useHistory, Link, useRouteMatch } from 'react-router-dom';
import withTranslation from '#components/HOCs/withTranslation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { makeModal } from '#components/modals/makeModal';
import ConfirmModal from '#components/modals/confirmModal/confirmModal';
import { FormattedMessage } from 'react-intl';
import useQuery from '#hooks/router/useQuery';
import useLoading from '#context/hooks/useLoading';
import { WafoFormSelect } from '@wafo/forms';

const TableVehicles = ({ translations, locale }) => {
  const api = useApi();
  const loading = useLoading();

  const query = useQuery();
  const history = useHistory();
  const match = useRouteMatch();

  /** ******************************************************* */
  /** Warehouses */
  /** ******************************************************* */

  const [warehouses, setWarehouses] = React.useState([]);
  const [filters, setFilters] = React.useState({
    id_location: '',
  });

  React.useEffect(() => {
    async function getWarehouses() {
      loading.set();
      try {
        const resp = await api.get('/locations', {
          params: {
            limit: 999,
            page: 1,
            type: '4,5',
          },
        });
        setWarehouses(resp.rows);
      } catch (error) {
        console.error(error);
      }
      loading.stop();
    }
    getWarehouses();
  }, [api, loading]);

  /** ******************************************************* */
  /** Table */
  /** ******************************************************* */

  const [rows, setRows] = React.useState([]);
  const [totalRows, setTotalRows] = React.useState(0);

  const tableRef = React.useRef();

  const getData = React.useCallback(
    async ({ size, page, search }) => {
      try {
        loading.set();
        const resp = await api.get('/transports/vehicles', {
          params: {
            limit: size,
            page,
            ...(search && { search }),
            ...(filters.id_location && { id_location: filters.id_location }),
          },
        });
        setTotalRows(resp.count);
        setRows(
          resp.rows.map(row => ({
            id: row.id_vehicle,
            status: row.disabled,
            vehicle: {
              brand: row.brand,
              model: row.model,
              year: row.year,
            },
            serial: row.serial_number,
            plates: row.plates,
            driver: row.id_driver,
            warehouse: row.id_location,
            options: { id: row.id_vehicle, disabled: row.disabled },
          })),
        );
        history.replace({
          pathname: match.url,
          search: `?page=${page}`,
        });
        loading.stop();
      } catch (error) {
        loading.stop();
      }
    },
    [api, loading, history, match.url, filters],
  );

  const toggleDisabled = React.useCallback(
    async (id, disabled) => {
      async function callDisable() {
        try {
          loading.set();
          await api.put(`/transports/vehicles/${id}`, { disabled: !disabled });
          loading.stop();
          tableRef.current.forceUpdate();
        } catch (error) {
          loading.stop();
        }
      }
      makeModal(ConfirmModal, {
        message: translations['vehicles-t-confirm-m'],
        translations,
        onAccept: () => {
          callDisable();
        },
      });
    },
    [api, loading, translations],
  );

  const columnsConfig = React.useMemo(
    () => ({
      id: function col(val) {
        return <span>#{val}</span>;
      },
      status: function col(val) {
        return (
          <span className={`badge table-badge ${!val ? 'badge-success' : 'badge-warning'}`}>
            <FormattedMessage id={!val ? 'vehicles-t-enabled' : 'vehicles-t-disabled'} />
          </span>
        );
      },
      vehicle: {
        render: function col(val) {
          return (
            <React.Fragment>
              <span>
                {val.model} {val.year}
              </span>
              <br />
              <span style={{ fontSize: '0.85em' }}>{val.brand}</span>
            </React.Fragment>
          );
        },
        style: { lineHeight: 1 },
      },
      driver: function col(val) {
        if (!val) {
          return null;
        }
        return <span>#{val}</span>;
      },
      warehouse: function col(val) {
        return <span>#{val}</span>;
      },
      options: function col(val) {
        return (
          <React.Fragment>
            <button
              type="button"
              className={`btn btn-table ${val.disabled ? 'btn-danger' : 'btn-success'}`}
              title={val.disabled ? 'Enable' : 'Disable'}
              onClick={() => {
                toggleDisabled(val.id, val.disabled);
              }}
            >
              <FontAwesomeIcon icon={val.disabled ? faToggleOff : faToggleOn} />
            </button>
            <Link to={`/panel/warehouses/vehicles/new/${val.id}`} className="btn btn-table btn-primary">
              <FontAwesomeIcon icon={faEdit} style={{ marginRight: '0.5rem' }} />
              {translations['vehicles-t-b-edit']}
            </Link>
          </React.Fragment>
        );
      },
    }),
    [toggleDisabled, translations],
  );

  return (
    <div>
      <DataTable
        ref={tableRef}
        locale={locale}
        columns={translations['vehicles-t-columns']}
        rows={rows}
        totalRows={totalRows}
        tableClass="table table-striped table-sm"
        onPagination={getData}
        noRowsMessage={translations['vehicles-t-norows']}
        initialPage={parseInt(query.get('page'), 10)}
        columnsConfig={columnsConfig}
        controls={
          <div className="row">
            <WafoFormSelect
              name="warehouse"
              defaultValue={translations['vehicles-t-filters-warehouse']}
              customClass="col-12 col-md-6"
              options={warehouses.map(w => ({
                value: w.id_location,
                display: `#${w.id_location} - ${w.name}`,
              }))}
              value={filters.id_location}
              onChangeCallback={({ target: { value } }) => setFilters(prev => ({ ...prev, id_location: value }))}
            />
          </div>
        }
      />
    </div>
  );
};

TableVehicles.propTypes = {
  translations: PropTypes.any,
  locale: PropTypes.string,
};

export default withTranslation(TableVehicles, 'vehicles');
