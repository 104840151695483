import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withTranslation from '#components/HOCs/withTranslation';
import { FormattedMessage } from 'react-intl';
import FormTimings from '#components/forms/trains/formTimings';
import styles from './equipmentTimeline.module.css';

function secondsToHms(d) {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 9 ? h : `0${h}`;
  const mDisplay = m > 9 ? m : `0${m}`;
  const sDisplay = s > 9 ? s : `0${s}`;

  return `${hDisplay}:${mDisplay}:${sDisplay}`;
}

const EquipmentTimeline = ({ translations, locations, updateEquipment }) => {
  const [editTimings, setEditTimings] = React.useState(false);
  const toggleEdit = () => setEditTimings(prevState => !prevState);

  const onAfterSubmit = () => {
    toggleEdit();
    updateEquipment();
  };

  if (locations.length > 1) {
    for (let i = 0; i < locations.length; i++) {
      if (i === 0) {
        continue;
      }
      const seconds = new Date(locations[i].date_arrival).getTime() - new Date(locations[i - 1].date_departed).getTime();
      const travel_time = secondsToHms(seconds / 1000);
      locations[i].travel_time = travel_time;
    }
  }

  return (
    <div id="equipment-timeline">
      <div className="row">
        <div className="col-12">
          <div className={styles['header']}>
            <h6 className={styles['title']}>
              <strong>
                <FormattedMessage id="equipments-m-details-timeline-subtitle" />
              </strong>
            </h6>
            <button type="button" className={`btn btn-sm btn-link ${styles['btn-editar']}`} onClick={toggleEdit}>
              <FormattedMessage id="equipments-m-details-f-b-edit" />
            </button>
          </div>
          {!editTimings && (
            <div className={styles['table-wrapper']}>
              <table className="table table-sm table-striped">
                <thead>
                  <tr>
                    {translations['equipments-m-details-timeline-columns'].map((column, index) => (
                      <th key={index}>{column}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {locations.map(l => (
                    <tr key={l.id_el}>
                      <td>{l.location.name}</td>
                      <td>{l.date_arrival ? moment(l.date_arrival).format('DD MMMM YYYY HH:mm') : 'NA'}</td>
                      <td>{l.date_departed ? moment(l.date_departed).format('DD MMMM YYYY HH:mm') : 'NA'}</td>
                      <td>{l.travel_time ? l.travel_time : 'NA'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {editTimings && <FormTimings locations={locations} onSubmitSuccess={onAfterSubmit} />}
        </div>
      </div>
    </div>
  );
};

EquipmentTimeline.propTypes = {
  locale: PropTypes.string,
  translations: PropTypes.any,
  updateEquipment: PropTypes.func,
  locations: PropTypes.array,
};

export default withTranslation(EquipmentTimeline, 'equipments');
