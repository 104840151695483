export function transformUnitsUP(unit) {
  switch (unit) {
    case 'lbs':
      return 'lb';
    default:
      return unit;
  }
}

export function materialQuantity(material, quantity) {
  return parseFloat(material.quantity) * parseFloat(quantity);
}
