import React from 'react';
import PropTypes from 'prop-types';
import { WafoForm, WafoFormInput } from '@wafo/forms';
import { useIntl } from 'react-intl';
import useApi from '#hooks/api/useApi';

const FormRecover = ({ onAfterSubmit }) => {
  const { locale, messages: translations } = useIntl();

  const api = useApi();

  const handleFormSubmit = async (form, values) => {
    if (!form.valid) {
      return;
    }
    try {
      await api.post('/auth/forgot', values);
      onAfterSubmit('/auth/awaitemail');
    } catch (error) {
      // handle
    }
  };

  return (
    <div>
      <WafoForm formId="recover-form" locale={locale} onSubmit={handleFormSubmit}>
        <WafoFormInput
          type="email"
          name="email"
          label={translations['auth-f-login-label-email']}
          placeholder={translations['auth-f-login-placeholder-email']}
          customClass="col-12"
          validations={{
            required: true,
            email: true,
          }}
        />
      </WafoForm>

      <button type="submit" form="recover-form" className="btn btn-s" style={{ width: '100%', marginBottom: '1rem', marginTop: '1rem' }}>
        {translations['auth-f-recover-button']}
      </button>
    </div>
  );
};

FormRecover.propTypes = {
  onAfterSubmit: PropTypes.func,
};

export default FormRecover;
