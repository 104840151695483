import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withTranslation from '#components/HOCs/withTranslation';
import styles from './equipmentIncidents.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faExclamationTriangle,
  faCheck,
  faEdit,
  faTrash,
  faFileDownload,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import IncidentType from '#components/UI/extra/incidentType';
import moment from 'moment';
import useApi from '#hooks/api/useApi';
import FormIncident from '#components/forms/trains/formIncident';
import useLoading from '#context/hooks/useLoading';

const EquipmentIncidents = ({ translations, equipmentId, locations, incidents, updateEquipment }) => {
  const [listexpanded, setListexpanded] = React.useState(null);
  const [edit, setEdit] = React.useState(null);

  const api = useApi();
  const loading = useLoading();

  const onListClick = location => {
    setListexpanded(prev => {
      if (prev === location) {
        return null;
      }
      return location;
    });
  };

  const releaseIncident = async id => {
    try {
      loading.set();
      await api.put(`/equipments/incidents/${id}`, {
        date_released: moment().toISOString(),
      });
      updateEquipment();
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  const deleteIncident = async id => {
    try {
      loading.set();
      await api.delete(`/equipments/incidents/${id}`);
      updateEquipment();
      loading.stop();
    } catch (error) {
      loading.stop();
    }
  };

  const cancelForm = () => {
    setEdit(null);
    updateEquipment();
  };

  const locationWithIncidents = React.useMemo(() => {
    return locations.map(l => {
      const locationIncidents = incidents.filter(x => x.id_el === l.id_el);
      return {
        ...l,
        incidents: locationIncidents,
      };
    });
  }, [locations, incidents]);

  if (edit) {
    const location = locations.find(x => x.id_el === edit.location);
    const incident = incidents.find(x => x.id_ei === edit.incident);

    return (
      <div className="equipment-incidents">
        <div className={styles['go-back']}>
          <button type="button" className={`btn btn-sm btn-link ${styles['btn-back']}`} onClick={cancelForm}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <h6 className={styles['h6-back']}>
            <strong>
              <FormattedMessage id={incident ? 'incidents-f-e-subtitle' : 'incidents-f-subtitle'} />:
            </strong>{' '}
            {location.location.name}
          </h6>
        </div>
        <FormIncident
          equipmentId={equipmentId}
          equipmentLocation={edit.location}
          incident={incident}
          onSubmitSuccess={cancelForm}
        />
      </div>
    );
  }

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <p>
            <FormattedMessage id="equipments-m-details-incidents-desc" />
          </p>
        </div>
        <div className="col-12">
          <ul className={styles['collapse-list']}>
            {locationWithIncidents.map(l => (
              <li key={l.id_location}>
                <div className={styles['listitem-header']} onClick={() => onListClick(l.id_location)}>
                  <h6>
                    {l.location.name} [{l.incidents.length}]
                  </h6>
                  <button type="button" className="btn btn-sm btn-link" onClick={() => setEdit({ location: l.id_el })}>
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
                <div className={`${styles['listitem-cards']} ${listexpanded !== l.id_location ? 'closed' : ''}`}>
                  {l.incidents.map(i => (
                    <div key={i.id_ei} className={`card ${i.date_released ? styles['released'] : styles['pending']}`}>
                      <div className="card-body">
                        <div className={styles['card-header']}>
                          <span>
                            <strong>
                              <IncidentType translations={translations} type={i.type} />
                            </strong>
                          </span>
                          <div>
                            <button
                              type="button"
                              className="btn btn-link"
                              title="Release"
                              onClick={() => releaseIncident(i.id_ei)}
                              disabled={i.date_released}
                            >
                              <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <button
                              type="button"
                              className="btn btn-link"
                              title="Edit"
                              onClick={() => setEdit({ location: l.id_el, incident: i.id_ei })}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button
                              type="button"
                              className="btn btn-link"
                              title="Delete"
                              onClick={() => deleteIncident(i.id_ei)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </div>
                        </div>
                        <p>
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            style={{ marginRight: '.25rem', color: '#dc3545' }}
                          />
                          <FormattedMessage id="incidents-c-date" />
                          <span style={{ marginLeft: '.25rem' }}>
                            {i.date_incident ? (
                              moment(i.date_incident).format('DD/MM/YYYY HH:mm')
                            ) : (
                              <FormattedMessage id="incidents-nodate" />
                            )}
                          </span>
                        </p>
                        <p>
                          <FontAwesomeIcon icon={faCheck} style={{ marginRight: '.25rem', color: '#28a745' }} />
                          <FormattedMessage id="incidents-c-release" />
                          <span style={{ marginLeft: '.25rem' }}>
                            {i.date_released ? (
                              moment(i.date_released).format('DD/MM/YYYY HH:mm')
                            ) : (
                              <FormattedMessage id="incidents-nodate" />
                            )}
                          </span>
                        </p>
                        <p className={styles['card-comment']}>{i.comment}</p>
                        {i.eincident_images.length > 0 && (
                          <a
                            className={`btn btn-link ${styles['file']}`}
                            href={`${process.env.REACT_APP_FILES_EQUIPMENTS_URL}/${equipmentId}/${i.eincident_images[0].filename}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon={faFileDownload} style={{ marginRight: '.25rem' }} />
                            {i.eincident_images[0].filename}
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                  {l.incidents.length === 0 && (
                    <p style={{ marginBottom: '0' }}>
                      <FormattedMessage id="incidents-c-noincidents" />
                    </p>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

EquipmentIncidents.propTypes = {
  translations: PropTypes.any,
  equipmentId: PropTypes.string,
  locations: PropTypes.array,
  incidents: PropTypes.array,
  updateEquipment: PropTypes.func,
};

export default withTranslation(EquipmentIncidents, ['equipments', 'incidents']);
